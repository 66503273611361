import React, { ReactNode } from 'react';
import { routes } from '../../../config/Router/routes';
import PublicNavigation from '../../PublicNavigation/PublicNavigation';
import styles from './PublicLayout.module.scss';
import AuthNavigation from 'src/component/Public/Auth/AuthNavigation/AuthNavigation';

type Props = {
  children: ReactNode;
};

const IGNORED_ROUTES = [
  routes.comingSoon,
  routes.resetPassword.replace('/:code', ''),
  routes.login,
  routes.register,
  routes.certificate.form.replace('/:id?', ''),
  routes.templates.form.replace('/:id?', ''),
];

const SECONDARY_NAVIGATION_ROUTES = [
  routes.certificate.shared.replace('/:id', ''),
  routes.section.shared.replace('/:id', ''),
];

const PublicLayout = ({ children }: Props) => {
  const isRouteIgnored = IGNORED_ROUTES.find((ignoredRoute) =>
    location.pathname.includes(ignoredRoute),
  );

  if (isRouteIgnored) {
    return <>{children}</>;
  }

  const isSecondaryNavigationRoute = SECONDARY_NAVIGATION_ROUTES.find(
    (ignoredRoute) => location.pathname.includes(ignoredRoute),
  );

  if (isSecondaryNavigationRoute) {
    return (
      <>
        <AuthNavigation />
        {children}
      </>
    );
  }

  return (
    <div className={styles.publicLayoutContainer}>
      <PublicNavigation />
      <div className={styles.rightSection}>{children}</div>
    </div>
  );
};

export default PublicLayout;
