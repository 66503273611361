import React, { useEffect } from 'react';
import styles from './SignaturePurchaseSecondStep.module.scss';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../../../../utility/messageTranslator/translate';
import PlanCard from '../../PlansSection/PlanCard/PlanCard';
import arrowLeftIcon from '../../../../../assets/icons/arrow_left.svg';
import chevronRightIcon from '../../../../../assets/icons/chevron_right.svg';
import stripeLogo from '../../../../../assets//stripe.svg';
import * as paymentService from '../../../../../store/payment/service';
import neopayLogo from '../../../../../assets/neopay.svg';
import { ReactSVG } from 'react-svg';
import Button from '../../../../../common/Button/Button';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../../../../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { PaymentCreateRequest } from '../../../../../store/payment/service';
import { useLocation } from 'react-router-dom';
import { getIcon } from '../../../../../utility/creditPackageHelper/creditPackageHelper';
import { CreditPackage } from '../../../../../domain/CreditPackage';
import { PaymentMethod } from '../../../../../domain/Payment';

type Props = {
  onBackButtonClick: () => void;
  selectedPlan: CreditPackage;
  onCreatePayment: (inputs: PaymentCreateRequest, intl: IntlShape) => void;
  redirectUrl: string | null;
  paymentCreateLoading: boolean;
  isBackButtonHidden?: boolean;
};

const SignaturePurchaseSecondStep = ({
  onBackButtonClick,
  selectedPlan,
  onCreatePayment,
  redirectUrl,
  paymentCreateLoading,
  isBackButtonHidden,
}: Props) => {
  const intl = useIntl();
  const location = useLocation();

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const handlePurchaseClick = (
    packageId: number,
    paymentMethod: PaymentMethod,
  ) => {
    onCreatePayment(
      {
        packageId: packageId,
        locationUrl: location.pathname,
        paymentMethod: paymentMethod,
      },
      intl,
    );
  };

  const renderPaymentButtons = () => {
    const PAYMENT_BUTTONS = [
      {
        imgSrc: stripeLogo,
        label: translate(
          intl,
          'SIGNATURE_PURCHASE_SECOND_STEP.PAYMENT_BUTTON_STRIPE',
        ),
        onClick: () =>
          handlePurchaseClick(selectedPlan.id, PaymentMethod.STRIPE),
      },
      {
        imgSrc: neopayLogo,
        label: translate(
          intl,
          'SIGNATURE_PURCHASE_SECOND_STEP.PAYMENT_BUTTON_NEOPAY',
        ),
        onClick: () =>
          handlePurchaseClick(selectedPlan.id, PaymentMethod.NEOPAY),
      },
    ];

    return PAYMENT_BUTTONS.map((button, index) => (
      <Button
        key={`payment-button-${index}`}
        type="button"
        onClick={button.onClick}
        isPaymentButton
        isDisabled={paymentCreateLoading}
      >
        <div>
          <ReactSVG src={button.imgSrc} className={styles.paymentMethodIcon} />
          {button.label}
        </div>
        <ReactSVG src={chevronRightIcon} className={styles.chevronIcon} />
      </Button>
    ));
  };

  return (
    <section className={styles.signaturePurchaseSecondStepContainer}>
      <div
        className={styles.signaturePurchaseSecondStepTitle}
        dangerouslySetInnerHTML={{
          __html: translate(intl, 'SIGNATURE_PURCHASE_SECOND_STEP.TITLE'),
        }}
      />
      {!isBackButtonHidden && (
        <div className={styles.backButtonContainer} onClick={onBackButtonClick}>
          <ReactSVG src={arrowLeftIcon} />
          {translate(intl, 'SIGNATURE_PURCHASE_SECOND_STEP.BACK_BUTTON')}
        </div>
      )}

      <div className={styles.cardsContainer}>
        <PlanCard
          icon={getIcon(selectedPlan.subscriptionType)}
          name={selectedPlan.name}
          description={selectedPlan.description}
          price={selectedPlan.price}
          isTheMostPopular={selectedPlan?.isPopular}
          isPurchaseButtonHidden
          credits={selectedPlan.credits}
          additionalCredits={selectedPlan.additionalCredits}
        />
        <div className={styles.paymentChooseContainer}>
          <div className={styles.title}>
            {translate(
              intl,
              'SIGNATURE_PURCHASE_SECOND_STEP.PAYMENT_CHOOSE_TITLE',
            )}
          </div>
          <div className={styles.description}>
            {translate(
              intl,
              'SIGNATURE_PURCHASE_SECOND_STEP.PAYMENT_CHOOSE_DESCRIPTION',
            )}
          </div>
          <div className={styles.buttonsContainer}>
            {renderPaymentButtons()}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: StoreState) => ({
  redirectUrl: state.payment.redirectUrl,
  paymentCreateLoading: state.payment.paymentCreateLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCreatePayment: (inputs: PaymentCreateRequest, intl: IntlShape) =>
    dispatch(paymentService.createPayment(inputs, intl)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignaturePurchaseSecondStep);
