import React, { useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { IntlShape, useIntl } from 'react-intl';
import styles from './PublicMobileNavigation.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { User } from '../../../domain/User';
import { NavLink, useNavigate } from 'react-router-dom';
import rightArrow from '../../../assets/icons/chevron_right_2.svg';
import cx from 'classnames';
import { PublicNavItem, PublicNavSubItem } from '../PublicNavigation';
import arrowLeftIcon from '../../../assets/icons/arrow_left.svg';
import ababaTechLogo from '../../../assets/ababa-tech-logo.svg';
// eslint-disable-next-line max-len
import PublicSidebarCompanyAndSignatures from '../PublicSidebar/PublicSidebarCompanyAndSignatures/PublicSidebarCompanyAndSignatures';
import { useForm } from 'src/hooks/useForm/useForm';
import { Roles } from 'src/domain/Role';
import { StoreState } from 'src/config/StoreProvider/StoreProvider';
import { SetSelectedRoleRequest } from 'src/store/profile/service';
import * as profileService from 'src/store/profile/service';
import TextField from '../../../common/TextField/TextField';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import userIcon from '../../../assets/icons/user.svg';
import { routes } from '../../../config/Router/routes';

type Props = {
  navigationItems: PublicNavItem[];
  onAddCreditsModalOpen: () => void;
  currentUser: User | null;
  onSetSelectedRole: (inputs: SetSelectedRoleRequest, intl: IntlShape) => void;
  isLoading: boolean;
};

type FormInputs = {
  selectedRole: Roles;
};

const PublicMobileNavigation = ({
  navigationItems,
  currentUser,
  onAddCreditsModalOpen,
  onSetSelectedRole,
  isLoading,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedItemWithSubMenu, setSelectedItemWithSubMenu] = useState<
    PublicNavItem | undefined
  >(undefined);

  const INPUTS = [
    {
      name: 'selectedRole',
      type: 'autocomplete',
      options: currentUser?.roles.map((role) => {
        return {
          label: translate(intl, `ROLE_SELECTION.ROLE_${role.role}`),
          value: role.role,
        };
      }),
      value: currentUser?.profile?.selectedRole,
    },
  ];

  const handleFilterChange = ({ selectedRole }: FormInputs) => {
    onSetSelectedRole(
      {
        selectedRole,
      },
      intl,
    );
  };

  const { inputs, onSelectChange } = useForm<FormInputs>(
    INPUTS,
    handleFilterChange,
    {
      submitOnChange: true,
    },
  );

  const getSubMenuItems = (
    publicNavSubItems: PublicNavSubItem[] | undefined,
  ) => {
    if (!publicNavSubItems?.length) {
      return [];
    }

    return publicNavSubItems.map((item: PublicNavSubItem, index: number) => (
      <div
        className={cx(styles.link, {
          [styles.isLast]: index === publicNavSubItems.length - 1,
        })}
        key={`subMenu-item-${item.label}-${index}`}
        onClick={() => navigate(item.to)}
      >
        <div className={styles.labelWithIconContainer}>{item.label}</div>
        <ReactSVG src={rightArrow} className={styles.arrowIcon} />
      </div>
    ));
  };

  const getSubmenu = (selectedItemWithSubMenu: PublicNavItem) => {
    if (!selectedItemWithSubMenu) {
      return <></>;
    }

    const getBackButton = () => (
      <div
        className={styles.backButtonContainer}
        onClick={() => setSelectedItemWithSubMenu(undefined)}
      >
        <ReactSVG src={arrowLeftIcon} />
        {translate(intl, 'PUBLIC_MOBILE_NAVIGATION.BACK_BUTTON')}
      </div>
    );

    return (
      <div className={styles.subMenuContainer}>
        {getBackButton()}

        <div className={styles.selectedItem}>
          <ReactSVG
            src={selectedItemWithSubMenu.icon}
            className={styles.icon}
          />
          {selectedItemWithSubMenu.label}
        </div>
        {getSubMenuItems(selectedItemWithSubMenu?.subItems)}
      </div>
    );
  };

  const handleMainNavigationItemClick = (publicNavItem: PublicNavItem) => {
    if (publicNavItem?.subItems?.length) {
      return setSelectedItemWithSubMenu(publicNavItem);
    }

    if (publicNavItem?.onClick) {
      return publicNavItem.onClick();
    }

    return null;
  };

  const isOrganiser = useMemo(() => {
    return !!currentUser?.roles.some((role) => role.role === Roles.OWNER);
  }, [currentUser]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <PublicSidebarCompanyAndSignatures
          currentUser={currentUser}
          onAddCreditsModalOpen={onAddCreditsModalOpen}
          companyContainerClass={styles.companyContainer}
        />
        <div className={styles.mobileMenuLinks}>
          {!selectedItemWithSubMenu &&
            navigationItems.map((item, index) =>
              item.to ? (
                <NavLink
                  key={`public-mobile-navigation-${item.label}-${index}`}
                  to={item.to}
                  className={cx(styles.link, {
                    [styles.isLast]: index === navigationItems.length - 1,
                  })}
                  target={item.targetBlank ? '_blank' : undefined}
                >
                  <div className={styles.labelWithIconContainer}>
                    <ReactSVG src={item.icon} className={styles.labelIcon} />
                    {item.label}
                  </div>

                  <ReactSVG src={rightArrow} className={styles.arrowIcon} />
                </NavLink>
              ) : (
                <div
                  key={`public-mobile-navigation-${item.label}-${index}`}
                  className={cx(styles.link, {
                    [styles.isLast]: index === navigationItems.length - 1,
                  })}
                  onClick={() => handleMainNavigationItemClick(item)}
                >
                  <div className={styles.labelWithIconContainer}>
                    <ReactSVG src={item.icon} className={styles.labelIcon} />
                    {item.label}
                  </div>

                  <ReactSVG src={rightArrow} className={styles.arrowIcon} />
                </div>
              ),
            )}
          {!isOrganiser && (
            <div
              className={styles.becomeOrganiser}
              onClick={() => navigate(routes.becomeOrganiser)}
            >
              <div className={styles.becomeOrganiserItem}>
                <ReactSVG src={userIcon} />
                {translate(
                  intl,
                  'PUBLIC_NAVIGATION.NAVIGATION_ITEM_BECOME_ORGANISER',
                )}
              </div>
              <ReactSVG src={rightArrow} className={styles.arrowIcon} />
            </div>
          )}
          <div className={styles.roleSelectionContainer}>
            {inputs.map((input) => (
              <TextField
                key={input.name}
                value={input.value?.toString() ?? ''}
                name={input.name}
                type={input.type}
                options={input.options}
                className={styles.textField}
                onSelectChange={onSelectChange}
                isLoading={isLoading}
                endContainer={<ReactSVG src={rightArrow} />}
                disableSelectTyping
                popperClassName={styles.popper}
              />
            ))}
          </div>
          {selectedItemWithSubMenu && getSubmenu(selectedItemWithSubMenu)}
        </div>

        <a
          href="https://ababa.tech/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.ababaLogo}
        >
          <img src={ababaTechLogo} alt="ababa.tech logo" />
        </a>
        <div className={styles.version}>{process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.profile.setSelectedRoleLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetSelectedRole: (inputs: SetSelectedRoleRequest, intl: IntlShape) =>
    dispatch(profileService.setSelectedRole(inputs, intl)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicMobileNavigation);
