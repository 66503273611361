import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { routes } from '../../..//config/Router/routes';
import { StoreState } from '../../..//config/StoreProvider/StoreProvider';
import { getLocaleUrl } from '../../../utility/url/urlHelper';
import { Locale } from '../../../domain/Translation';
import styles from './LogoLink.module.scss';
import cx from 'classnames';

type Props = {
  logoSrc: string;
  selectedLocale: Locale;
  logoClassName?: string;
  isNotSvg?: boolean;
};

const LogoLink = ({
  logoSrc,
  selectedLocale,
  isNotSvg,
  logoClassName,
}: Props) => {
  if (isNotSvg) {
    return <img src={logoSrc} className={logoClassName} />;
  }

  return (
    <Link
      className={cx(styles.logoLink, logoClassName)}
      to={getLocaleUrl(routes.dashboard, selectedLocale)}
    >
      <ReactSVG src={logoSrc} />
    </Link>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(LogoLink);
