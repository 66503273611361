import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchCreditPackageFail,
  fetchCreditPackagesFail,
  fetchCreditPackagesStart,
  fetchCreditPackagesSuccess,
  fetchCreditPackageStart,
  fetchCreditPackageSuccess,
  fetchPublicCreditPackagesFail,
  fetchPublicCreditPackagesStart,
  fetchPublicCreditPackagesSuccess,
  updateCreditPackageFail,
  updateCreditPackageStart,
  updateCreditPackageSuccess,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/credit-packages';

export type CreditPackageCreateRequest = {
  name: string;
  price: number;
  credits: number;
  additionalCredits: number;
  description: string;
  isPopular: boolean;
};

export type CreditPackageUpdateRequest = {
  id: number;
  name: string;
  price: number;
  credits: number;
  additionalCredits: number;
  description: string;
  isPopular: boolean;
};

export const fetchCreditPackages =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchCreditPackagesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchCreditPackagesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCreditPackagesFail(err?.response?.data?.message));
        showToast(err?.response?.data?.message, 'error');
      });
  };

export const publicCreditPackagesFetch = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicCreditPackagesStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchPublicCreditPackagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicCreditPackagesFail(err?.response?.data?.message));
    });
};

export const fetchCreditPackage = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchCreditPackageStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchCreditPackageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCreditPackageFail(err?.response?.data?.message));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const updateCreditPackage =
  (inputs: CreditPackageUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateCreditPackageStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, { ...inputs })
      .then(() => {
        dispatch(updateCreditPackageSuccess());
        showToast(translate(intl, 'SUCCESS.CREDIT_PACKAGE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCreditPackageFail(err?.response?.data?.message));
      });
  };
