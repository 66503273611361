export const CREATE_PARTICIPANT_CERTIFICATE_SECTION_START =
  'CREATE_PARTICIPANT_CERTIFICATE_SECTION_START';
export const CREATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS =
  'CREATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS';
export const CREATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL =
  'CREATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL';

export const FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_START =
  'FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_START';
export const FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_SUCCESS =
  'FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_SUCCESS';
export const FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_FAIL =
  'FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_FAIL';

export const FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_START =
  'FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_START';
export const FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS =
  'FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS';
export const FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_FAIL =
  'FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_FAIL';

export const UPDATE_PARTICIPANT_CERTIFICATE_SECTION_START =
  'UPDATE_PARTICIPANT_CERTIFICATE_SECTION_START';
export const UPDATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS =
  'UPDATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS';
export const UPDATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL =
  'UPDATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL';

export const DELETE_PARTICIPANT_CERTIFICATE_SECTION_START =
  'DELETE_PARTICIPANT_CERTIFICATE_SECTION_START';
export const DELETE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS =
  'DELETE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS';
export const DELETE_PARTICIPANT_CERTIFICATE_SECTION_FAIL =
  'DELETE_PARTICIPANT_CERTIFICATE_SECTION_FAIL';

export const ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_START =
  'ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_START';
export const ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_SUCCESS =
  'ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_SUCCESS';
export const ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_FAIL =
  'ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_FAIL';

export const TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_START =
  'TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_START';
export const TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_SUCCESS =
  'TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_SUCCESS';
export const TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_FAIL =
  'TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_FAIL';

export const RESET_ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION =
  'RESET_ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION';

export const RESET_CREATE_PARTICIPANT_CERTIFICATE_SECTION =
  'RESET_CREATE_PARTICIPANT_CERTIFICATE_SECTION';

export const RESET_PARTICIPANT_CERTIFICATE_SECTION_STORE =
  'RESET_PARTICIPANT_CERTIFICATE_SECTION_STORE';

export type ParticipantCertificateSectionActionTypes =
  | typeof CREATE_PARTICIPANT_CERTIFICATE_SECTION_START
  | typeof CREATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS
  | typeof CREATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL
  | typeof FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_START
  | typeof FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_SUCCESS
  | typeof FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_FAIL
  | typeof FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_START
  | typeof FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS
  | typeof FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_FAIL
  | typeof UPDATE_PARTICIPANT_CERTIFICATE_SECTION_START
  | typeof UPDATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS
  | typeof UPDATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL
  | typeof DELETE_PARTICIPANT_CERTIFICATE_SECTION_START
  | typeof DELETE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS
  | typeof DELETE_PARTICIPANT_CERTIFICATE_SECTION_FAIL
  | typeof ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_START
  | typeof ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_SUCCESS
  | typeof ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_FAIL
  | typeof TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_START
  | typeof TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_SUCCESS
  | typeof TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_FAIL
  | typeof RESET_ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION
  | typeof RESET_CREATE_PARTICIPANT_CERTIFICATE_SECTION
  | typeof RESET_PARTICIPANT_CERTIFICATE_SECTION_STORE;
