import React, { ReactNode, useEffect } from 'react';
import styles from './AuthContainer.module.scss';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  title: string;
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
  leftSection?: ReactNode;
};

export const AuthContainer = ({
  children,
  isAuthenticated,
  title,
  onChangeSection,
  section,
  leftSection,
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.dashboard);
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.authContainer}>
      <div className={styles.leftContainer}>{leftSection}</div>
      <div className={styles.rightContainer}>
        <div className={styles.rightContainerInner}>
          <div className={styles.title}>{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(AuthContainer);
