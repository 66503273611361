import React, { ReactElement, ReactNode } from 'react';
import styles from './Popover.module.scss';
import { Tooltip } from '@mui/material';

export type Props = {
  label: string;
  icon?: ReactElement;
  children?: ReactNode;
  isCompleted?: boolean;
  secondaryLabel?: string;
  isInProgress?: boolean;
  isOpen?: boolean;
};

export const Popover = ({
  label,
  icon,
  isCompleted,
  children,
  secondaryLabel,
  isInProgress,
  isOpen,
}: Props) => {
  const getLabelWithIcon = () => {
    if (secondaryLabel && isCompleted === false && !isInProgress) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.36rem',
            alignItems: 'center',
          }}
        >
          <div className={styles.iconContainer}>{icon}</div> {secondaryLabel}
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.36rem',
          alignItems: 'center',
        }}
      >
        <div className={styles.iconContainer}>{icon}</div> {label}
      </div>
    );
  };

  const getLabelColor = () => {
    if (isInProgress) {
      return '#e2a226';
    }

    if (isCompleted === false) {
      return '#B95E5E';
    }

    return '#4F868E';
  };

  return (
    <>
      <Tooltip
        title={icon ? getLabelWithIcon() : label}
        open={isOpen}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'white',
              color: getLabelColor(),
              padding: '0.62rem',
              fontSize: '0.9375rem',
              border: '1px solid #DADADA',
              borderRadius: '0.625rem',
            },
          },
        }}
      >
        <div>{children}</div>
      </Tooltip>
    </>
  );
};
