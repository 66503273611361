import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';
import Button from '../../common/Button/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import arrowLeftIcon from '../../assets/icons/arrow_left.svg';
import { ReactSVG } from 'react-svg';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Popover } from '../Popover/Popover';
import infoSvg from '../../assets/icons/info-circle.svg';

type Props = {
  onConfirm?: () => void;
  onClose: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  explanationText?: string | undefined;
  title?: string;
  children?: ReactNode;
  className?: string;
  isSmall?: boolean;
  isFooterHidden?: boolean;
  isFullScreen?: boolean;
  isFullWidth?: boolean;
  isBackButtonVisible?: boolean;
  isCloseButtonHidden?: boolean;
  isTitleCentered?: boolean;
  isTitleLeftAligned?: boolean;
  isTransparent?: boolean;
  isCloseButtonInContent?: boolean;
  customConfirmationButtonText?: string;
  withZoom?: boolean;
};

const Modal = ({
  isOpen,
  onConfirm,
  onClose,
  explanationText,
  title,
  isLoading,
  children,
  className,
  isSmall,
  isFooterHidden,
  isFullScreen,
  isFullWidth,
  isBackButtonVisible,
  isCloseButtonHidden,
  isTitleCentered,
  isTitleLeftAligned,
  isTransparent,
  isCloseButtonInContent,
  customConfirmationButtonText,
  withZoom,
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isFullScreen}
      className={cx(
        styles.modalContainer,
        {
          [styles.isSmall]: !!onConfirm || isSmall,
        },
        {
          [styles.isFullWidth]: isFullWidth,
        },
        {
          [styles.isTransparent]: isTransparent,
        },
        className,
      )}
    >
      {isBackButtonVisible && (
        <div className={styles.backButtonContainer} onClick={onClose}>
          <ReactSVG src={arrowLeftIcon} />
          {translate(intl, 'MODAL.BACK_BUTTON')}
        </div>
      )}

      <DialogTitle id="alert-dialog-title" className={styles.modalTitle}>
        <div
          className={cx({
            [styles.isTitleCentered]: isTitleCentered,
            [styles.isTitleLeftAligned]: isTitleLeftAligned,
          })}
        >
          {title}
        </div>
        {!isBackButtonVisible &&
          !isCloseButtonHidden &&
          !isCloseButtonInContent && (
            <IconButton onClick={onClose} disableFocusRipple disableRipple>
              <CloseIcon className={styles.closeIcon} />
            </IconButton>
          )}
      </DialogTitle>
      <DialogContent>
        {explanationText && (
          <DialogContentText
            id="alert-dialog-description"
            className={styles.explanationText}
          >
            {explanationText}
          </DialogContentText>
        )}
        <div
          className={cx({
            [styles.isCloseButtonInContent]: isCloseButtonInContent,
          })}
        >
          {withZoom ? (
            <TransformWrapper
              wheel={{
                activationKeys: ['Control', 'Meta'],
                step: 0.1,
              }}
            >
              <TransformComponent>{children}</TransformComponent>
            </TransformWrapper>
          ) : (
            children
          )}
          <div className={styles.actionsContainer}>
            {isCloseButtonInContent && (
              <div onClick={onClose}>
                <IconButton
                  onClick={onClose}
                  className={styles.closeButton}
                  disableFocusRipple
                  disableRipple
                >
                  <CloseIcon className={styles.closeIcon} />
                </IconButton>
              </div>
            )}
            {withZoom && (
              <div className={styles.tooltipContainer}>
                <Popover label={translate(intl, 'CERTIFICATE_ZOOM.TOOLTIP')}>
                  <ReactSVG src={infoSvg} className={styles.zoomInfoIcon} />
                </Popover>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      {!isFooterHidden && onConfirm && (
        <DialogActions>
          <Button isConfirmationButton onClick={onClose} isLoading={isLoading}>
            {translate(intl, 'MODAL.CANCEL')}
          </Button>
          <Button
            isConfirmationButton
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {customConfirmationButtonText ?? translate(intl, 'MODAL.CONFIRM')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
