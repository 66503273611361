import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  setSelectedRoleSuccess,
  setSelectedRoleStart,
  setSelectedRoleFail,
  sendPhoneVerificationCodeFail,
  sendPhoneVerificationCodeStart,
  sendPhoneVerificationCodeSuccess,
  confirmPhoneNumberFail,
  confirmPhoneNumberStart,
  confirmPhoneNumberSuccess,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { Roles } from '../../domain/Role';

const API_URL = '/profile';

export type SetSelectedRoleRequest = {
  selectedRole: Roles;
};

export type confirmPhoneNumberRequest = {
  code: string;
};

export const setSelectedRole =
  (inputs: SetSelectedRoleRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(setSelectedRoleStart());
    return axios
      .patch(`${API_URL}/select-role`, { ...inputs })
      .then(() => {
        dispatch(setSelectedRoleSuccess());
      })
      .catch((err) => {
        dispatch(setSelectedRoleFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const sendPhoneVerificationCode =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(sendPhoneVerificationCodeStart());
    return axios
      .post(`${API_URL}/phone-verification-code`)
      .then(() => {
        dispatch(sendPhoneVerificationCodeSuccess());
      })
      .catch((err) => {
        dispatch(sendPhoneVerificationCodeFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const confirmPhoneNumber =
  (inputs: confirmPhoneNumberRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(confirmPhoneNumberStart());
    return axios
      .post(`${API_URL}/phone-confirm`, { ...inputs })
      .then(() => {
        dispatch(confirmPhoneNumberSuccess());
      })
      .catch((err) => {
        dispatch(confirmPhoneNumberFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
