import * as actionTypes from './actionTypes';
import { CertificateSectionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CertificateSection } from '../../domain/CertificateSection';
import { ListResults } from '../../common/List/List';

export type CertificateSectionStateType = {
  createCertificateSectionLoading: boolean;
  createdCertificateSection: CertificateSection | null;
  createCertificateSectionError: HttpError;
  certificateSections: ListResults<CertificateSection> | null;
  certificateSectionsLoading: boolean;
  certificateSectionsError: HttpError;
  certificateSectionUpdateLoading: boolean;
  certificateSectionUpdateSuccess: boolean;
  certificateSectionUpdateError: HttpError;
  certificateSectionDeleteLoading: boolean;
  certificateSectionDeleteError: HttpError;
  certificateSectionDeleteSuccess: boolean;
  certificateSectionListUpdateNeeded: boolean;
  assignCertificateTemplateToSectionLoading: boolean;
  assignCertificateTemplateToSectionSuccess: boolean;
  assignCertificateTemplateToSectionError: HttpError;
};

export type CertificateSectionActionType = CertificateSectionStateType & {
  type: CertificateSectionActionTypes;
  certificateId: number;
};

export const initialState: CertificateSectionStateType = {
  createCertificateSectionLoading: false,
  createdCertificateSection: null,
  createCertificateSectionError: null,
  certificateSections: null,
  certificateSectionsLoading: false,
  certificateSectionsError: null,
  certificateSectionUpdateLoading: false,
  certificateSectionUpdateSuccess: false,
  certificateSectionUpdateError: null,
  certificateSectionDeleteLoading: false,
  certificateSectionDeleteError: null,
  certificateSectionDeleteSuccess: false,
  certificateSectionListUpdateNeeded: false,
  assignCertificateTemplateToSectionLoading: false,
  assignCertificateTemplateToSectionSuccess: false,
  assignCertificateTemplateToSectionError: null,
};

const createCertificateSectionStart = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  createCertificateSectionLoading: true,
});

const createCertificateSectionSuccess = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  createCertificateSectionLoading: false,
  createCertificateSectionError: null,
  createdCertificateSection: action.createdCertificateSection,
  certificateSectionListUpdateNeeded: true,
});

const createCertificateSectionFail = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  createCertificateSectionLoading: false,
  createCertificateSectionError: action.createCertificateSectionError,
});

const fetchCertificateSectionsStart = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionsLoading: true,
});

const fetchCertificateSectionsSuccess = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  certificateSections: action.certificateSections,
  certificateSectionsLoading: false,
  certificateSectionsError: null,
  certificateSectionListUpdateNeeded: false,
  certificateSectionDeleteSuccess: false,
});

const fetchCertificateSectionsFail = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionsLoading: false,
  certificateSectionsError: action.certificateSectionsError,
});

const updateCertificateSectionStart = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionUpdateLoading: true,
});

const updateCertificateSectionSuccess = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionUpdateLoading: false,
  certificateSectionUpdateError: null,
  certificateSectionUpdateSuccess: true,
  certificateSectionListUpdateNeeded: true,
});

const updateCertificateSectionFail = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionUpdateLoading: false,
  certificateSectionUpdateError: action.certificateSectionUpdateError,
});

const deleteCertificateSectionStart = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionDeleteLoading: true,
});

const deleteCertificateSectionSuccess = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionDeleteLoading: false,
  certificateSectionDeleteError: null,
  certificateSectionDeleteSuccess: true,
  certificateSectionListUpdateNeeded: true,
});

const deleteCertificateSectionFail = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  certificateSectionDeleteLoading: false,
  certificateSectionDeleteError: action.certificateSectionDeleteError,
});

const assignCertificateTemplateToSectionStart = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  assignCertificateTemplateToSectionLoading: true,
});

const assignCertificateTemplateToSectionSuccess = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  assignCertificateTemplateToSectionLoading: false,
  assignCertificateTemplateToSectionError: null,
  assignCertificateTemplateToSectionSuccess: true,
  certificateSectionListUpdateNeeded: true,
});

const assignCertificateTemplateToSectionFail = (
  state: CertificateSectionStateType,
  action: CertificateSectionActionType,
): CertificateSectionStateType => ({
  ...state,
  assignCertificateTemplateToSectionLoading: false,
  assignCertificateTemplateToSectionError:
    action.assignCertificateTemplateToSectionError,
});

const resetAssignCertificateTemplateToSection = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  assignCertificateTemplateToSectionLoading: false,
  assignCertificateTemplateToSectionError: null,
  assignCertificateTemplateToSectionSuccess: false,
});

const resetCreateCertificateSection = (
  state: CertificateSectionStateType,
): CertificateSectionStateType => ({
  ...state,
  createCertificateSectionLoading: false,
  createCertificateSectionError: null,
  createdCertificateSection: null,
});

const resetStore = (): CertificateSectionStateType => ({
  ...initialState,
});

const reducer = (
  state = initialState,
  action: CertificateSectionActionType,
) => {
  switch (action.type) {
    case actionTypes.CREATE_CERTIFICATE_SECTION_START:
      return createCertificateSectionStart(state);
    case actionTypes.CREATE_CERTIFICATE_SECTION_SUCCESS:
      return createCertificateSectionSuccess(state, action);
    case actionTypes.CREATE_CERTIFICATE_SECTION_FAIL:
      return createCertificateSectionFail(state, action);
    case actionTypes.FETCH_CERTIFICATE_SECTIONS_START:
      return fetchCertificateSectionsStart(state);
    case actionTypes.FETCH_CERTIFICATE_SECTIONS_SUCCESS:
      return fetchCertificateSectionsSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_SECTIONS_FAIL:
      return fetchCertificateSectionsFail(state, action);
    case actionTypes.UPDATE_CERTIFICATE_SECTION_START:
      return updateCertificateSectionStart(state);
    case actionTypes.UPDATE_CERTIFICATE_SECTION_SUCCESS:
      return updateCertificateSectionSuccess(state, action);
    case actionTypes.UPDATE_CERTIFICATE_SECTION_FAIL:
      return updateCertificateSectionFail(state, action);
    case actionTypes.DELETE_CERTIFICATE_SECTION_START:
      return deleteCertificateSectionStart(state);
    case actionTypes.DELETE_CERTIFICATE_SECTION_SUCCESS:
      return deleteCertificateSectionSuccess(state, action);
    case actionTypes.DELETE_CERTIFICATE_SECTION_FAIL:
      return deleteCertificateSectionFail(state, action);
    case actionTypes.ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_START:
      return assignCertificateTemplateToSectionStart(state);
    case actionTypes.ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_SUCCESS:
      return assignCertificateTemplateToSectionSuccess(state, action);
    case actionTypes.ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_FAIL:
      return assignCertificateTemplateToSectionFail(state, action);
    case actionTypes.RESET_ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION:
      return resetAssignCertificateTemplateToSection(state);
    case actionTypes.RESET_CREATE_CERTIFICATE_SECTION:
      return resetCreateCertificateSection(state);
    case actionTypes.RESET_CERTIFICATE_SECTION_STORE:
      return resetStore();
    default:
      return state;
  }
};

export default reducer;
