import React from 'react';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import styles from './HamburgerButton.module.scss';

type Props = {
  isMobileMenuOpen: boolean;
  clickHandler: () => void;
  iconColor?: string;
};

const HamburgerButton = ({
  isMobileMenuOpen,
  clickHandler,
  iconColor,
}: Props) => {
  return (
    <div className={styles.icon}>
      <IconButton onClick={clickHandler}>
        <FontAwesomeIcon
          icon={isMobileMenuOpen ? faTimes : faBars}
          fixedWidth
          size="sm"
          color={iconColor ? iconColor : '#4F868E'}
        />
      </IconButton>
    </div>
  );
};

export default HamburgerButton;
