export const FETCH_TEXT_TEMPLATES_START = 'FETCH_TEXT_TEMPLATES_START';
export const FETCH_TEXT_TEMPLATES_SUCCESS = 'FETCH_TEXT_TEMPLATES_SUCCESS';
export const FETCH_TEXT_TEMPLATES_FAIL = 'FETCH_TEXT_TEMPLATES_FAIL';

export const CREATE_OR_UPDATE_TEXT_TEMPLATES_START =
  'CREATE_OR_UPDATE_TEXT_TEMPLATES_START';
export const CREATE_OR_UPDATE_TEXT_TEMPLATES_SUCCESS =
  'CREATE_OR_UPDATE_TEXT_TEMPLATES_SUCCESS';
export const CREATE_OR_UPDATE_TEXT_TEMPLATES_FAIL =
  'CREATE_OR_UPDATE_TEXT_TEMPLATES_FAIL';

export const RESET_TEXT_TEMPLATE_STORE = 'RESET_TEXT_TEMPLATE_STORE';
export const RESET_TEXT_TEMPLATE_CREATE_UPDATE_STORE =
  'RESET_TEXT_TEMPLATE_CREATE_UPDATE_STORE';

export const LOGOUT = 'LOGOUT';

export type TextTemplateActionTypes =
  | typeof FETCH_TEXT_TEMPLATES_START
  | typeof FETCH_TEXT_TEMPLATES_SUCCESS
  | typeof FETCH_TEXT_TEMPLATES_FAIL
  | typeof CREATE_OR_UPDATE_TEXT_TEMPLATES_START
  | typeof CREATE_OR_UPDATE_TEXT_TEMPLATES_SUCCESS
  | typeof CREATE_OR_UPDATE_TEXT_TEMPLATES_FAIL
  | typeof RESET_TEXT_TEMPLATE_STORE
  | typeof RESET_TEXT_TEMPLATE_CREATE_UPDATE_STORE
  | typeof LOGOUT;
