export const FETCH_BALANCE_HISTORY_START = 'FETCH_BALANCE_HISTORY_START';
export const FETCH_BALANCE_HISTORY_SUCCESS = 'FETCH_BALANCE_HISTORY_SUCCESS';
export const FETCH_BALANCE_HISTORY_FAIL = 'FETCH_BALANCE_HISTORY_FAIL';

export const RESET_BALANCE_HISTORY_STORE = 'RESET_BALANCE_HISTORY_STORE';

export const LOGOUT = 'LOGOUT';

export type BalanceHistoryActionTypes =
  | typeof FETCH_BALANCE_HISTORY_START
  | typeof FETCH_BALANCE_HISTORY_SUCCESS
  | typeof FETCH_BALANCE_HISTORY_FAIL
  | typeof RESET_BALANCE_HISTORY_STORE
  | typeof LOGOUT;
