import React, { useState } from 'react';
import AuthContainer from '../../../component/Public/Auth/AuthContainer/AuthContainer';
import SignUpSection from '../../../component/Public/Auth/SignUpSection/SignUpSection';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import AuthAboutSection from '../../../component/Public/Auth/AuthAboutSection/AuthAboutSection';

export enum SignUpSectionType {
  // eslint-disable-next-line no-unused-vars
  LOGIN = 'LOGIN',
  // eslint-disable-next-line no-unused-vars
  REGISTER = 'REGISTER',
}

export type AboutProcerty = {
  title: string;
  label1: string;
  label2: string;
  label3: string;
};

type Props = {
  sectionType?: SignUpSectionType;
};

const SignUpPage = ({ sectionType }: Props) => {
  const intl = useIntl();
  const [section, setSection] = useState(
    sectionType ? sectionType : SignUpSectionType.LOGIN,
  );

  const getTitle = () => {
    if (section === SignUpSectionType.REGISTER) {
      return translate(intl, 'SIGN_UP_PAGE.REGISTRATION_TITLE_MAIN');
    }

    return translate(intl, 'SIGN_UP_PAGE.TITLE_MAIN');
  };

  const registrationTranslations: AboutProcerty = {
    title: translate(intl, 'LEFT_SECTION_REGISTRATION.TITLE'),
    label1: translate(intl, 'LEFT_SECTION_REGISTRATION.LABEL_1'),
    label2: translate(intl, 'LEFT_SECTION_REGISTRATION.LABEL_2'),
    label3: translate(intl, 'LEFT_SECTION_REGISTRATION.LABEL_3'),
  };

  const loginTranslations: AboutProcerty = {
    title: translate(intl, 'LEFT_SECTION_LOGIN.TITLE'),
    label1: translate(intl, 'LEFT_SECTION_LOGIN.LABEL_1'),
    label2: translate(intl, 'LEFT_SECTION_LOGIN.LABEL_2'),
    label3: translate(intl, 'LEFT_SECTION_LOGIN.LABEL_3'),
  };

  const getSection = () => {
    if (section === SignUpSectionType.REGISTER) {
      return <AuthAboutSection translations={registrationTranslations} />;
    }

    return <AuthAboutSection translations={loginTranslations} />;
  };

  return (
    <AuthContainer
      title={getTitle()}
      onChangeSection={setSection}
      section={section}
      leftSection={getSection()}
    >
      <SignUpSection section={section} onChangeSection={setSection} />
    </AuthContainer>
  );
};

export default SignUpPage;
