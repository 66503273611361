import React from 'react';
import styles from './SignUpSection.module.scss';
import SocialLogin from '../SocialLogin/SocialLogin';
import LoginForm from '../LoginForm/LoginForm';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import RegisterForm from '../RegisterForm/RegisterForm';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';

type Props = {
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
};

export const SignUpSection = ({ section, onChangeSection }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const getSection = () => {
    if (section === SignUpSectionType.REGISTER) {
      return <RegisterForm />;
    }

    return <LoginForm onChangeSection={onChangeSection} />;
  };

  const getBottomMessage = () => {
    if (section === SignUpSectionType.LOGIN) {
      return (
        <>
          {translate(intl, 'SIGN_UP_SECTION.DO_NOT_HAVE_ACCOUNT_PART_1')}
          <span
            onClick={() => {
              onChangeSection(SignUpSectionType.REGISTER);
              navigate(routes.register);
            }}
          >
            {translate(intl, 'SIGN_UP_SECTION.DO_NOT_HAVE_ACCOUNT_PART_2')}
          </span>
        </>
      );
    }

    if (section === SignUpSectionType.REGISTER) {
      return (
        <>
          {translate(intl, 'SIGN_UP_SECTION.ALREADY_HAVE_ACCOUNT_PART_1')}
          <span
            onClick={() => {
              onChangeSection(SignUpSectionType.LOGIN);
              navigate(routes.login);
            }}
          >
            {translate(intl, 'SIGN_UP_SECTION.ALREADY_HAVE_ACCOUNT_PART_2')}
          </span>
        </>
      );
    }

    return <></>;
  };

  return (
    <div className={styles.loginSectionContainer}>
      {getSection()}
      <section className={styles.separatorWrapper}>
        <div className={styles.separator}>
          <hr className={styles.line} />
          <span className={styles.word}>
            {translate(intl, 'SIGN_UP_SECTION.SEPARATOR')}
          </span>
        </div>
      </section>
      <div className={styles.socialButtonsContainer}>
        <SocialLogin isRegistration={section === SignUpSectionType.REGISTER} />
      </div>

      <p className={styles.bottomMessage}>{getBottomMessage()}</p>
    </div>
  );
};

export default SignUpSection;
