import * as actionTypes from './actionTypes';
import { CreditPackageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  CreditPackage,
  CreditPackageListType,
} from '../../domain/CreditPackage';

export type CreditPackageStateType = {
  creditPackagesList: CreditPackageListType | null;
  creditPackagesLoading: boolean;
  creditPackagesError: HttpError;
  creditPackagesListUpdateNeeded: boolean;
  createdCreditPackage: CreditPackage | null;
  creditPackageUpdateLoading: boolean;
  creditPackageUpdateError: HttpError;
  creditPackageUpdateSuccess: boolean;
  creditPackage: CreditPackage | null;
  creditPackageLoading: boolean;
  creditPackageError: HttpError;
  publicCreditPackagesLoading: boolean;
  publicCreditPackages: CreditPackage[] | null;
  publicCreditPackagesError: HttpError;
};

export type CreditPackageActionType = CreditPackageStateType & {
  type: CreditPackageActionTypes;
};

export const initialState: CreditPackageStateType = {
  creditPackagesList: null,
  creditPackagesLoading: true,
  creditPackagesError: null,
  creditPackagesListUpdateNeeded: false,
  createdCreditPackage: null,
  creditPackageUpdateLoading: false,
  creditPackageUpdateError: null,
  creditPackageUpdateSuccess: false,
  creditPackage: null,
  creditPackageLoading: false,
  creditPackageError: null,
  publicCreditPackagesLoading: false,
  publicCreditPackages: null,
  publicCreditPackagesError: null,
};

const fetchCreditPackagesStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackagesLoading: true,
});

const fetchCreditPackagesSuccess = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackagesList: action.creditPackagesList,
  creditPackagesLoading: false,
  creditPackagesError: null,
  creditPackagesListUpdateNeeded: false,
});

const fetchCreditPackagesFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackagesError: action.creditPackagesError,
  creditPackagesLoading: false,
});

const fetchPublicCreditPackagesStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  publicCreditPackagesLoading: true,
});

const fetchPublicCreditPackagesSuccess = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  publicCreditPackages: action.publicCreditPackages,
  creditPackagesLoading: false,
  publicCreditPackagesError: null,
});

const fetchPublicCreditPackagesFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  publicCreditPackagesError: action.publicCreditPackagesError,
  publicCreditPackagesLoading: false,
});

const fetchCreditPackageStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageLoading: true,
});

const fetchCreditPackageSuccess = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackage: action.creditPackage,
  creditPackageLoading: false,
  creditPackageError: null,
});

const fetchCreditPackageFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageError: action.creditPackageError,
  creditPackageLoading: false,
});

const updateCreditPackageStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateLoading: true,
  creditPackageUpdateSuccess: false,
});

const updateCreditPackageSuccess = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateLoading: false,
  creditPackageUpdateError: null,
  creditPackageUpdateSuccess: true,
  creditPackagesListUpdateNeeded: true,
});

const updateCreditPackageFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateLoading: false,
  creditPackageUpdateError: action.creditPackageUpdateError,
});

const resetUpdatedCreditPackage = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateSuccess: false,
});

const resetCreditPackagesStore = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
});

const completelyResetCreditPackageStore = (): CreditPackageStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CreditPackageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_PACKAGES_START:
      return fetchCreditPackagesStart(state);
    case actionTypes.FETCH_CREDIT_PACKAGES_SUCCESS:
      return fetchCreditPackagesSuccess(state, action);
    case actionTypes.FETCH_CREDIT_PACKAGES_FAIL:
      return fetchPublicCreditPackagesFail(state, action);
    case actionTypes.FETCH_PUBLIC_CREDIT_PACKAGES_START:
      return fetchPublicCreditPackagesStart(state);
    case actionTypes.FETCH_PUBLIC_CREDIT_PACKAGES_SUCCESS:
      return fetchPublicCreditPackagesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_CREDIT_PACKAGES_FAIL:
      return fetchCreditPackagesFail(state, action);
    case actionTypes.FETCH_CREDIT_PACKAGE_START:
      return fetchCreditPackageStart(state);
    case actionTypes.FETCH_CREDIT_PACKAGE_SUCCESS:
      return fetchCreditPackageSuccess(state, action);
    case actionTypes.FETCH_CREDIT_PACKAGE_FAIL:
      return fetchCreditPackageFail(state, action);
    case actionTypes.UPDATE_CREDIT_PACKAGE_START:
      return updateCreditPackageStart(state);
    case actionTypes.UPDATE_CREDIT_PACKAGE_SUCCESS:
      return updateCreditPackageSuccess(state);
    case actionTypes.UPDATE_CREDIT_PACKAGE_FAIL:
      return updateCreditPackageFail(state, action);
    case actionTypes.RESET_EDITED_CREDIT_PACKAGE:
      return resetUpdatedCreditPackage(state);
    case actionTypes.RESET_CREDIT_PACKAGES_STORE:
      return resetCreditPackagesStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCreditPackageStore();
    default:
      return state;
  }
};

export default reducer;
