import React, { useEffect, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import logo from '../../assets/procertyLogo_beta.png';
import LogoLink from './LogoLink/LogoLink';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import HamburgerButton from './HamburgerButton/HamburgerButton';
import { useLocation } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import PublicSidebar from './PublicSidebar/PublicSidebar';
import pieChartIcon from '../../assets/icons/pie_chart.svg';
import certificateIcon from '../../assets/icons/certificate.svg';
import userInCardIcon from '../../assets/icons/user_in_card.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import questionMarkIcon from '../../assets/icons/question_mark.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import { routes } from '../../config/Router/routes';
import * as authService from '../../store/auth/service';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { User } from '../../domain/User';
import SignaturePurchaseModal from '../../component/Public/Payment/SignaturePurchaseModal/SignaturePurchaseModal';
import { Roles } from '../../domain/Role';
import PublicMobileNavigation from './PublicMobileNavigation/PublicMobileNavigation';
import Modal from '../Modal/Modal';

export const MOBILE_BREAK_POINT = 900;
export const MOBILE_SMALLER_BREAK_POINT = 600;

export type PublicNavSubItem = {
  label: string;
  to: string;
  roles?: Roles[];
};

export type PublicNavItem = {
  label: string;
  icon: string;
  to?: string;
  targetBlank?: boolean;
  onClick?: () => void;
  roles?: Roles[];
  subItems?: PublicNavSubItem[];
};

type Props = {
  onLogout: () => void;
  isAuthenticated: boolean;
  currentUser: User | null;
};

const PublicNavigation = ({
  onLogout,
  isAuthenticated,
  currentUser,
}: Props) => {
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();
  const [isSignaturePurchaseModalOpen, setIsSignaturePurchaseModalOpen] =
    useState(false);
  const [isLogoutConfirmationModalOpen, setIsLogoutConfirmationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (width && width > MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  const GENERAL_ITEMS: PublicNavItem[] = [
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.PARTICIPANT_DASHBOARD'),
      icon: certificateIcon,
      to: routes.participantDashboard,
      roles: [Roles.USER],
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.NAVIGATION_ITEM_OVERVIEW'),
      icon: pieChartIcon,
      to: routes.dashboard,
      roles: [Roles.ADMIN, Roles.OWNER],
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.NAVIGATION_ITEM_CERTIFICATES'),
      icon: certificateIcon,
      to: routes.certificate.list,
      roles: [Roles.ADMIN, Roles.OWNER],
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.NAVIGATION_ITEM_PARTICIPANTS'),
      icon: userInCardIcon,
      to: routes.participant.list,
      roles: [Roles.ADMIN, Roles.OWNER],
    },
    {
      label: translate(
        intl,
        'PUBLIC_NAVIGATION.NAVIGATION_ITEM_PARTICIPANT_SETTINGS',
      ),
      icon: settingsIcon,
      to: routes.participantProfile,
      roles: [Roles.USER],
      subItems: [
        {
          label: translate(intl, 'PARTICIPANT_PROFILE.TAB_USER_INFO'),
          to: `${routes.participantProfile}?tabId=0`,
          roles: [Roles.USER],
        },
        {
          label: translate(intl, 'PARTICIPANT_PROFILE.TAB_CHANGE_PASSWORD'),
          to: `${routes.participantProfile}?tabId=1`,
          roles: [Roles.USER],
        },
        {
          label: translate(intl, 'PARTICIPANT_PROFILE.TAB_CONNECT_EMAIL'),
          to: `${routes.participantProfile}?tabId=2`,
          roles: [Roles.USER],
        },
      ],
    },
  ];

  const PROFILE_SETTINGS_ITEMS: PublicNavItem[] = [
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.NAVIGATION_ITEM_SETTINGS'),
      icon: settingsIcon,
      to: routes.profile,
      roles: [Roles.ADMIN, Roles.OWNER],
      subItems: [
        {
          label: translate(intl, 'PROFILE.TAB_USER_INFO'),
          to: `${routes.profile}?tabId=0`,
          roles: [Roles.ADMIN, Roles.OWNER],
        },
        {
          label: translate(intl, 'PROFILE.TAB_CHANGE_PASSWORD'),
          to: `${routes.profile}?tabId=1`,
          roles: [Roles.ADMIN, Roles.OWNER],
        },
        {
          label: translate(intl, 'PROFILE.TAB_BALANCE_HISTORY'),
          to: `${routes.profile}?tabId=2`,
          roles: [Roles.ADMIN, Roles.OWNER],
        },
      ],
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.NAVIGATION_ITEM_HELP'),
      icon: questionMarkIcon,
      to: routes.help,
      targetBlank: true,
      roles: [Roles.ADMIN, Roles.OWNER, Roles.USER],
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.NAVIGATION_ITEM_LOGOUT'),
      icon: logoutIcon,
      onClick: () => setIsLogoutConfirmationModalOpen(true),
      roles: [Roles.ADMIN, Roles.OWNER, Roles.USER],
    },
  ];

  const getFilteredItems = (items: PublicNavItem[]) => {
    if (!items) {
      return [];
    }

    return items.filter(
      (item) =>
        !!currentUser?.profile?.selectedRole &&
        item?.roles?.includes(currentUser?.profile?.selectedRole),
    );
  };

  const getMobileNavigationItems = () => {
    return [
      ...getFilteredItems(GENERAL_ITEMS),
      ...getFilteredItems(PROFILE_SETTINGS_ITEMS),
    ];
  };

  return (
    <header className={styles.navigationContainer}>
      {isAuthenticated && width && width >= MOBILE_BREAK_POINT && (
        <PublicSidebar
          currentUser={currentUser}
          generalItems={getFilteredItems(GENERAL_ITEMS)}
          profileSettingsItems={getFilteredItems(PROFILE_SETTINGS_ITEMS)}
          onAddCreditsModalOpen={() => setIsSignaturePurchaseModalOpen(true)}
        />
      )}
      {width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileNavigation}>
          <LogoLink logoSrc={logo} isNotSvg logoClassName={styles.logo} />
          <HamburgerButton
            isMobileMenuOpen={isMobileMenuOpen}
            clickHandler={() => setIsMobileMenuOpen((prevState) => !prevState)}
          />
        </div>
      )}

      {width && width < MOBILE_BREAK_POINT && isMobileMenuOpen && (
        <PublicMobileNavigation
          currentUser={currentUser}
          navigationItems={getMobileNavigationItems()}
          onAddCreditsModalOpen={() => setIsSignaturePurchaseModalOpen(true)}
        />
      )}

      {isSignaturePurchaseModalOpen && (
        <SignaturePurchaseModal
          isOpen={isSignaturePurchaseModalOpen}
          onClose={() => setIsSignaturePurchaseModalOpen(false)}
          isFullScreen={!!width && width <= MOBILE_SMALLER_BREAK_POINT}
          isBackButtonVisible={!!width && width <= MOBILE_SMALLER_BREAK_POINT}
        />
      )}

      <Modal
        onConfirm={() => onLogout()}
        onClose={() => setIsLogoutConfirmationModalOpen(false)}
        isLoading={false}
        isOpen={isLogoutConfirmationModalOpen}
        explanationText={translate(
          intl,
          'PUBLIC_NAVIGATION_LOGOUT_CONFIRMATION.EXPLANATION_TEXT',
        )}
        title={translate(intl, 'PUBLIC_NAVIGATION_LOGOUT_CONFIRMATION.TITLE')}
        isTitleLeftAligned
      />
    </header>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
