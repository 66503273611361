import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { ParticipantCertificateSection } from '../../domain/ParticipantCertificateSection';
import { ParticipantCertificateSectionActionTypes } from './actionTypes';

export type ParticipantCertificateSectionStateType = {
  createParticipantCertificateSectionLoading: boolean;
  createdParticipantCertificateSection: ParticipantCertificateSection | null;
  createParticipantCertificateSectionError: HttpError;
  participantCertificateSections: ListResults<ParticipantCertificateSection> | null;
  participantCertificateSectionsLoading: boolean;
  participantCertificateSectionsError: HttpError;
  publicParticipantCertificateSection: ParticipantCertificateSection | null;
  publicParticipantCertificateSectionLoading: boolean;
  publicParticipantCertificateSectionSuccess: boolean;
  publicParticipantCertificateSectionError: HttpError;
  participantCertificateSectionUpdateLoading: boolean;
  participantCertificateSectionUpdateSuccess: boolean;
  participantCertificateSectionUpdateError: HttpError;
  participantCertificateSectionDeleteLoading: boolean;
  participantCertificateSectionDeleteError: HttpError;
  participantCertificateSectionDeleteSuccess: boolean;
  participantCertificateSectionListUpdateNeeded: boolean;
  assignParticipantCertificateToSectionLoading: boolean;
  assignParticipantCertificateToSectionSuccess: boolean;
  assignParticipantCertificateToSectionError: HttpError;
  toggleParticipantCertificateSectionPublicityLoading: boolean;
  toggleParticipantCertificateSectionPublicitySuccess: boolean;
  toggleParticipantCertificateSectionPublicityError: HttpError;
};

export type ParticipantCertificateSectionActionType =
  ParticipantCertificateSectionStateType & {
    type: ParticipantCertificateSectionActionTypes;
    participantCertificateId: number;
  };

export const initialState: ParticipantCertificateSectionStateType = {
  createParticipantCertificateSectionLoading: false,
  createdParticipantCertificateSection: null,
  createParticipantCertificateSectionError: null,
  participantCertificateSections: null,
  participantCertificateSectionsLoading: false,
  participantCertificateSectionsError: null,
  publicParticipantCertificateSection: null,
  publicParticipantCertificateSectionLoading: false,
  publicParticipantCertificateSectionSuccess: false,
  publicParticipantCertificateSectionError: null,
  participantCertificateSectionUpdateLoading: false,
  participantCertificateSectionUpdateSuccess: false,
  participantCertificateSectionUpdateError: null,
  participantCertificateSectionDeleteLoading: false,
  participantCertificateSectionDeleteError: null,
  participantCertificateSectionDeleteSuccess: false,
  participantCertificateSectionListUpdateNeeded: false,
  assignParticipantCertificateToSectionLoading: false,
  assignParticipantCertificateToSectionSuccess: false,
  assignParticipantCertificateToSectionError: null,
  toggleParticipantCertificateSectionPublicityLoading: false,
  toggleParticipantCertificateSectionPublicitySuccess: false,
  toggleParticipantCertificateSectionPublicityError: null,
};

const createParticipantCertificateSectionStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  createParticipantCertificateSectionLoading: true,
});

const createParticipantCertificateSectionSuccess = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  createParticipantCertificateSectionLoading: false,
  createParticipantCertificateSectionError: null,
  createdParticipantCertificateSection:
    action.createdParticipantCertificateSection,
  participantCertificateSectionListUpdateNeeded: true,
});

const createParticipantCertificateSectionFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  createParticipantCertificateSectionLoading: false,
  createParticipantCertificateSectionError:
    action.createParticipantCertificateSectionError,
});

const fetchParticipantCertificateSectionsStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionsLoading: true,
});

const fetchParticipantCertificateSectionsSuccess = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSections: action.participantCertificateSections,
  participantCertificateSectionsLoading: false,
  participantCertificateSectionsError: null,
  participantCertificateSectionListUpdateNeeded: false,
  participantCertificateSectionDeleteSuccess: false,
});

const fetchParticipantCertificateSectionsFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionsLoading: false,
  participantCertificateSectionsError:
    action.participantCertificateSectionsError,
});

const fetchPublicParticipantCertificateSectionStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  publicParticipantCertificateSectionLoading: true,
});

const fetchPublicParticipantCertificateSectionSuccess = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  publicParticipantCertificateSection:
    action.publicParticipantCertificateSection,
  publicParticipantCertificateSectionLoading: false,
  publicParticipantCertificateSectionError: null,
});

const fetchPublicParticipantCertificateSectionFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  publicParticipantCertificateSectionLoading: false,
  publicParticipantCertificateSectionError:
    action.publicParticipantCertificateSectionError,
});

const updateParticipantCertificateSectionStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionUpdateLoading: true,
});

const updateParticipantCertificateSectionSuccess = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionUpdateLoading: false,
  participantCertificateSectionUpdateError: null,
  participantCertificateSectionUpdateSuccess: true,
  participantCertificateSectionListUpdateNeeded: true,
});

const updateParticipantCertificateSectionFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionUpdateLoading: false,
  participantCertificateSectionUpdateError:
    action.participantCertificateSectionUpdateError,
});

const assignParticipantCertificateToSectionStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  assignParticipantCertificateToSectionLoading: true,
});

const assignParticipantCertificateToSectionSuccess = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  assignParticipantCertificateToSectionLoading: false,
  assignParticipantCertificateToSectionError: null,
  assignParticipantCertificateToSectionSuccess: true,
  participantCertificateSectionListUpdateNeeded: true,
});

const assignParticipantCertificateToSectionFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  assignParticipantCertificateToSectionLoading: false,
  assignParticipantCertificateToSectionError:
    action.assignParticipantCertificateToSectionError,
});

const toggleParticipantCertificateSectionPublicityStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  toggleParticipantCertificateSectionPublicityLoading: true,
  participantCertificateSectionListUpdateNeeded: false,
});

const toggleParticipantCertificateSectionPublicitySuccess = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  toggleParticipantCertificateSectionPublicityLoading: false,
  toggleParticipantCertificateSectionPublicityError: null,
  toggleParticipantCertificateSectionPublicitySuccess: true,
  participantCertificateSectionListUpdateNeeded: true,
});

const toggleParticipantCertificateSectionPublicityFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  toggleParticipantCertificateSectionPublicityLoading: false,
  toggleParticipantCertificateSectionPublicityError:
    action.toggleParticipantCertificateSectionPublicityError,
});

const deleteParticipantCertificateSectionStart = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionDeleteLoading: true,
});

const deleteParticipantCertificateSectionSuccess = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionDeleteLoading: false,
  participantCertificateSectionDeleteError: null,
  participantCertificateSectionDeleteSuccess: true,
  participantCertificateSectionListUpdateNeeded: true,
});

const deleteParticipantCertificateSectionFail = (
  state: ParticipantCertificateSectionStateType,
  action: ParticipantCertificateSectionActionType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  participantCertificateSectionDeleteLoading: false,
  participantCertificateSectionDeleteError:
    action.participantCertificateSectionDeleteError,
});

const resetCreateParticipantCertificateSection = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  createParticipantCertificateSectionLoading: false,
  createParticipantCertificateSectionError: null,
  createdParticipantCertificateSection: null,
});

const resetAssignParticipantCertificateToSection = (
  state: ParticipantCertificateSectionStateType,
): ParticipantCertificateSectionStateType => ({
  ...state,
  assignParticipantCertificateToSectionLoading: false,
  assignParticipantCertificateToSectionError: null,
  assignParticipantCertificateToSectionSuccess: false,
});

const resetStore = (): ParticipantCertificateSectionStateType => ({
  ...initialState,
});

const reducer = (
  state = initialState,
  action: ParticipantCertificateSectionActionType,
) => {
  switch (action.type) {
    case actionTypes.CREATE_PARTICIPANT_CERTIFICATE_SECTION_START:
      return createParticipantCertificateSectionStart(state);
    case actionTypes.CREATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS:
      return createParticipantCertificateSectionSuccess(state, action);
    case actionTypes.CREATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL:
      return createParticipantCertificateSectionFail(state, action);
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_START:
      return fetchParticipantCertificateSectionsStart(state);
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_SUCCESS:
      return fetchParticipantCertificateSectionsSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_FAIL:
      return fetchParticipantCertificateSectionsFail(state, action);
    case actionTypes.FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_START:
      return fetchPublicParticipantCertificateSectionStart(state);
    case actionTypes.FETCH_PUBLIC_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS:
      return fetchPublicParticipantCertificateSectionSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_SECTIONS_FAIL:
      return fetchPublicParticipantCertificateSectionFail(state, action);
    case actionTypes.UPDATE_PARTICIPANT_CERTIFICATE_SECTION_START:
      return updateParticipantCertificateSectionStart(state);
    case actionTypes.UPDATE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS:
      return updateParticipantCertificateSectionSuccess(state);
    case actionTypes.UPDATE_PARTICIPANT_CERTIFICATE_SECTION_FAIL:
      return updateParticipantCertificateSectionFail(state, action);
    case actionTypes.ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_START:
      return assignParticipantCertificateToSectionStart(state);
    case actionTypes.ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_SUCCESS:
      return assignParticipantCertificateToSectionSuccess(state, action);
    case actionTypes.ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION_FAIL:
      return assignParticipantCertificateToSectionFail(state, action);
    case actionTypes.TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_START:
      return toggleParticipantCertificateSectionPublicityStart(state);
    case actionTypes.TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_SUCCESS:
      return toggleParticipantCertificateSectionPublicitySuccess(state);
    case actionTypes.TOGGLE_PARTICIPANT_CERTIFICATE_SECTION_PUBLICITY_FAIL:
      return toggleParticipantCertificateSectionPublicityFail(state, action);
    case actionTypes.DELETE_PARTICIPANT_CERTIFICATE_SECTION_START:
      return deleteParticipantCertificateSectionStart(state);
    case actionTypes.DELETE_PARTICIPANT_CERTIFICATE_SECTION_SUCCESS:
      return deleteParticipantCertificateSectionSuccess(state, action);
    case actionTypes.DELETE_PARTICIPANT_CERTIFICATE_SECTION_FAIL:
      return deleteParticipantCertificateSectionFail(state, action);
    case actionTypes.RESET_CREATE_PARTICIPANT_CERTIFICATE_SECTION:
      return resetCreateParticipantCertificateSection(state);
    case actionTypes.RESET_ASSIGN_PARTICIPANT_CERTIFICATE_TO_SECTION:
      return resetAssignParticipantCertificateToSection(state);
    case actionTypes.RESET_PARTICIPANT_CERTIFICATE_SECTION_STORE:
      return resetStore();
    default:
      return state;
  }
};

export default reducer;
