import * as actionTypes from './actionTypes';
import { CertificateActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  Certificate,
  MappedParticipantSectionCertificates,
  SignStatus,
} from '../../domain/Certificate';
import { ListResults } from '../../common/List/List';
import _ from 'lodash';

export type CertificateStateType = {
  participantCertificateListError: HttpError;
  participantCertificateListSuccess: boolean;
  participantCertificateList: ListResults<Certificate> | null;
  participantCertificateListLoading: boolean;
  participantCertificateListUpdateNeeded: boolean;
  prepareToSignLoading: boolean;
  prepareToSignError: HttpError;
  prepareToSignSuccess: boolean;
  prepareToSignBatchId: string | null;
  signUrlLoading: boolean;
  signUrlError: HttpError;
  signUrl: string | null;
  signUrlBatchId: string | null;
  startSignProcessLoading: boolean;
  startSignProcessSuccess: boolean;
  startSignProcessError: HttpError;
  startSignProcessBatchId: string | null;
  sendCertificateLoading: boolean;
  sendCertificateError: HttpError;
  sendCertificateSuccess: boolean;
  isUpdateNeeded: boolean;
  signStatusLoading: boolean;
  signStatusError: HttpError;
  signStatus: SignStatus | null;
  createExternalCertificateLoading: boolean;
  createExternalCertificateError: HttpError;
  deleteExternalCeritficateLoading: boolean;
  deleteExternalCeritficateSuccess: boolean;
  deleteExternalCeritficateError: HttpError;
  updateExternalCertificateLoading: boolean;
  updateExternalCertificateSuccess: boolean;
  updateExternalCertificateError: HttpError;
  unsectionedCertificatesLoading: boolean;
  unsectionedCertificatesError: HttpError;
  unsectionedCertificates: Certificate[];
  toggleParticipantCertificateLoading: boolean;
  toggleParticipantCertificateSuccess: boolean;
  toggleParticipantCertificateError: HttpError;
  publicCertificateError: HttpError;
  publicCertificateSuccess: boolean;
  publicCertificateLoading: boolean;
  publicCertificate: Certificate | null;
  toggleCertificateArchiveStatusLoading: boolean;
  toggleCertificateArchiveStatusSuccess: boolean;
  toggleCertificateArchiveStatusError: HttpError;
  participantSectionCertificates: ListResults<Certificate> | null;
  participantSectionCertificatesError: HttpError;
  participantSectionCertificatesLoading: boolean;
  mappedParticipantSectionCertificates: MappedParticipantSectionCertificates[];
  signOnBehalfLoading: boolean;
  signOnBehalfSuccess: boolean;
  signOnBehalfError: HttpError;
};

export type CertificateActionType = CertificateStateType & {
  type: CertificateActionTypes;
  certificateId: number;
};

export const initialState: CertificateStateType = {
  participantCertificateListError: null,
  participantCertificateListSuccess: false,
  participantCertificateList: null,
  participantCertificateListLoading: true,
  participantCertificateListUpdateNeeded: false,
  prepareToSignError: null,
  prepareToSignLoading: false,
  prepareToSignSuccess: false,
  prepareToSignBatchId: null,
  signUrlError: null,
  signUrlLoading: false,
  signUrl: null,
  signUrlBatchId: null,
  startSignProcessBatchId: null,
  startSignProcessError: null,
  startSignProcessLoading: false,
  startSignProcessSuccess: false,
  sendCertificateLoading: false,
  sendCertificateError: null,
  sendCertificateSuccess: false,
  isUpdateNeeded: false,
  signStatus: null,
  signStatusError: null,
  signStatusLoading: false,
  createExternalCertificateError: null,
  createExternalCertificateLoading: false,
  deleteExternalCeritficateLoading: false,
  deleteExternalCeritficateSuccess: false,
  deleteExternalCeritficateError: null,
  updateExternalCertificateLoading: false,
  updateExternalCertificateSuccess: false,
  updateExternalCertificateError: null,
  unsectionedCertificatesLoading: false,
  unsectionedCertificatesError: null,
  unsectionedCertificates: [],
  toggleParticipantCertificateLoading: false,
  toggleParticipantCertificateSuccess: false,
  toggleParticipantCertificateError: null,
  publicCertificateError: null,
  publicCertificateSuccess: false,
  publicCertificateLoading: false,
  publicCertificate: null,
  toggleCertificateArchiveStatusLoading: false,
  toggleCertificateArchiveStatusSuccess: false,
  toggleCertificateArchiveStatusError: null,
  participantSectionCertificates: null,
  participantSectionCertificatesError: null,
  participantSectionCertificatesLoading: false,
  mappedParticipantSectionCertificates: [],
  signOnBehalfLoading: false,
  signOnBehalfSuccess: false,
  signOnBehalfError: null,
};

const fetchParticipantCertificateListStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  participantCertificateListLoading: true,
  participantCertificateListSuccess: false,
  participantCertificateListUpdateNeeded: false,
});

const fetchParticipantCertificateListSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  participantCertificateList: action.participantCertificateList,
  participantCertificateListLoading: false,
  participantCertificateListError: null,
  participantCertificateListSuccess: true,
});

const fetchParticipantCertificateListFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  participantCertificateListError: action.participantCertificateListError,
  participantCertificateListLoading: false,
});

const fetchPublicCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  publicCertificateLoading: true,
  publicCertificateSuccess: false,
});

const fetchPublicCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  publicCertificate: action.publicCertificate,
  publicCertificateLoading: false,
  publicCertificateError: null,
  publicCertificateSuccess: true,
});

const fetchPublicCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  publicCertificateError: action.publicCertificateError,
  publicCertificateLoading: false,
});

const createExternalCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateLoading: true,
});

const createExternalCertificateSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateLoading: false,
  createExternalCertificateError: null,
  participantCertificateListUpdateNeeded: true,
});

const createExternalCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateError: action.createExternalCertificateError,
  createExternalCertificateLoading: false,
});

const updateExternalCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  updateExternalCertificateLoading: true,
});

const updateExternalCertificateSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  updateExternalCertificateLoading: false,
  updateExternalCertificateSuccess: true,
  updateExternalCertificateError: null,
  participantCertificateListUpdateNeeded: true,
});

const updateExternalCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  updateExternalCertificateError: action.createExternalCertificateError,
  updateExternalCertificateLoading: false,
  updateExternalCertificateSuccess: false,
});

const toggleParticipantCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  toggleParticipantCertificateLoading: true,
});

const toggleParticipantCertificateSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  toggleParticipantCertificateLoading: false,
  toggleParticipantCertificateSuccess: true,
  toggleParticipantCertificateError: null,
  participantCertificateListUpdateNeeded: true,
});

const toggleParticipantCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  toggleParticipantCertificateError: action.toggleParticipantCertificateError,
  toggleParticipantCertificateLoading: false,
  toggleParticipantCertificateSuccess: false,
});

const prepareToSignCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  prepareToSignLoading: true,
  prepareToSignSuccess: false,
  prepareToSignBatchId: null,
});

const prepareToSignCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  prepareToSignLoading: false,
  prepareToSignError: null,
  prepareToSignSuccess: true,
  prepareToSignBatchId: action.prepareToSignBatchId,
});

const prepareToSignCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  prepareToSignError: action.prepareToSignError,
  prepareToSignLoading: false,
  prepareToSignSuccess: false,
});

const prepareToSignCertificateReset = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signUrl: null,
  signUrlBatchId: null,
  prepareToSignSuccess: false,
  prepareToSignError: null,
  prepareToSignBatchId: null,
  startSignProcessSuccess: false,
  startSignProcessBatchId: null,
  startSignProcessError: null,
  signStatus: null,
});

const startSignProcessStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  startSignProcessLoading: true,
  startSignProcessError: null,
  startSignProcessBatchId: null,
  startSignProcessSuccess: false,
});

const startSignProcessSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  startSignProcessLoading: false,
  startSignProcessError: null,
  startSignProcessSuccess: true,
  startSignProcessBatchId: action.startSignProcessBatchId,
});

const startSignProcessFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  startSignProcessError: action.startSignProcessError,
  startSignProcessLoading: false,
  startSignProcessSuccess: false,
});

const getSignUrlStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signUrlLoading: true,
});

const getSignUrlSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signUrlLoading: false,
  signUrlError: null,
  signUrl: action.signUrl,
  signUrlBatchId: action.signUrlBatchId,
});

const getSignUrlFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signUrlError: action.signUrlError,
  signUrlLoading: false,
});

const sendCertificateStart = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  sendCertificateLoading: true,
});

const sendCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  sendCertificateLoading: false,
  sendCertificateError: null,
  sendCertificateSuccess: true,
});

const sendCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  sendCertificateLoading: false,
  sendCertificateError: action.sendCertificateError,
});

const checkSignStatusStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signStatusLoading: true,
});

const checkSignStatusSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signStatusLoading: false,
  signStatusError: null,
  signStatus: action.signStatus,
});

const checkSignStatusFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signStatusError: action.signStatusError,
  signStatusLoading: false,
});

const deleteExternalCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  deleteExternalCeritficateLoading: true,
});

const deleteExternalCertificateSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  deleteExternalCeritficateLoading: false,
  deleteExternalCeritficateError: null,
  deleteExternalCeritficateSuccess: true,
  participantCertificateListUpdateNeeded: true,
});

const deleteExternalCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  deleteExternalCeritficateLoading: false,
  deleteExternalCeritficateError: action.deleteExternalCeritficateError,
});

const fetchUnsectionedParticipantCertificatesStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  unsectionedCertificatesLoading: true,
});

const fetchUnsectionedParticipantCertificatesSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  unsectionedCertificates: action.unsectionedCertificates,
  unsectionedCertificatesLoading: false,
  unsectionedCertificatesError: null,
});

const fetchUnsectionedParticipantCertificatesFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  unsectionedCertificatesError: action.unsectionedCertificatesError,
  unsectionedCertificatesLoading: false,
});

const fetchParticipantSectionCertificatesStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  participantSectionCertificatesLoading: true,
});

const fetchParticipantSectionCertificatesSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => {
  const prev = state.mappedParticipantSectionCertificates?.length
    ? state.mappedParticipantSectionCertificates
    : [];

  const arr = action.participantSectionCertificates?.result?.length
    ? [
        ...prev,
        {
          participantCertificateSectionId: Number(
            action.participantSectionCertificates.result[0]
              .participantCertificateSectionId,
          ),
          certificates: action.participantSectionCertificates.result,
        },
      ]
    : prev;

  const mappedParticipantSectionCertificates = _(arr)
    .keyBy('participantCertificateSectionId')
    .values()
    .value();

  return {
    ...state,
    participantSectionCertificates: action.participantSectionCertificates,
    mappedParticipantSectionCertificates: mappedParticipantSectionCertificates,
    participantSectionCertificatesLoading: false,
    participantSectionCertificatesError: null,
  };
};

const fetchParticipantSectionCertificatesFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  participantSectionCertificatesError:
    action.participantSectionCertificatesError,
  participantSectionCertificatesLoading: false,
});

const toggleCertificateArchiveStatusStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  toggleCertificateArchiveStatusLoading: true,
});

const toggleCertificateArchiveStatusSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  toggleCertificateArchiveStatusLoading: false,
  toggleCertificateArchiveStatusSuccess: true,
  toggleCertificateArchiveStatusError: null,
  participantCertificateListUpdateNeeded: true,
});

const toggleCertificateArchiveStatusFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  toggleCertificateArchiveStatusError:
    action.toggleCertificateArchiveStatusError,
  toggleCertificateArchiveStatusLoading: false,
  toggleCertificateArchiveStatusSuccess: false,
});

const signOnBehalfStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signOnBehalfLoading: true,
});

const signOnBehalfSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signOnBehalfLoading: false,
  signOnBehalfError: null,
  signOnBehalfSuccess: true,
});

const signOnBehalfFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signOnBehalfError: action.signOnBehalfError,
  signOnBehalfLoading: false,
});

const resetSignOnBehalf = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signOnBehalfSuccess: false,
  signOnBehalfError: null,
  signOnBehalfLoading: false,
});

const resetSendCertificateStore = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  isUpdateNeeded: false,
  sendCertificateSuccess: false,
  sendCertificateLoading: false,
  sendCertificateError: null,
});

const resetParticipantCertificateListStore = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  participantCertificateListUpdateNeeded: false,
  participantCertificateListSuccess: false,
  sendCertificateLoading: false,
  sendCertificateError: null,
});

const resetSignCertificateStatus = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signStatusError: null,
  signStatusLoading: false,
  signStatus: null,
});

const resetExternalCertificateCreate = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateError: null,
});

const resetStore = (): CertificateStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CertificateActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_LIST_START:
      return fetchParticipantCertificateListStart(state);
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_LIST_SUCCESS:
      return fetchParticipantCertificateListSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_CERTIFICATE_LIST_FAIL:
      return fetchParticipantCertificateListFail(state, action);
    case actionTypes.FETCH_PUBLIC_CERTIFICATE_START:
      return fetchPublicCertificateStart(state);
    case actionTypes.FETCH_PUBLIC_CERTIFICATE_SUCCESS:
      return fetchPublicCertificateSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_CERTIFICATE_FAIL:
      return fetchPublicCertificateFail(state, action);
    case actionTypes.CREATE_EXTERNAL_CERTIFICATE_START:
      return createExternalCertificateStart(state);
    case actionTypes.CREATE_EXTERNAL_CERTIFICATE_SUCCESS:
      return createExternalCertificateSuccess(state);
    case actionTypes.CREATE_EXTERNAL_CERTIFICATE_FAIL:
      return createExternalCertificateFail(state, action);
    case actionTypes.UPDATE_EXTERNAL_CERTIFICATE_START:
      return updateExternalCertificateStart(state);
    case actionTypes.UPDATE_EXTERNAL_CERTIFICATE_SUCCESS:
      return updateExternalCertificateSuccess(state);
    case actionTypes.UPDATE_EXTERNAL_CERTIFICATE_FAIL:
      return updateExternalCertificateFail(state, action);
    case actionTypes.TOGGLE_PARTICIPANT_CERTIFICATE_START:
      return toggleParticipantCertificateStart(state);
    case actionTypes.TOGGLE_PARTICIPANT_CERTIFICATE_SUCCESS:
      return toggleParticipantCertificateSuccess(state);
    case actionTypes.TOGGLE_PARTICIPANT_CERTIFICATE_FAIL:
      return toggleParticipantCertificateFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_START:
      return prepareToSignCertificateStart(state);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_SUCCESS:
      return prepareToSignCertificateSuccess(state, action);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_RESET:
      return prepareToSignCertificateReset(state);
    case actionTypes.GET_SIGN_URL_START:
      return getSignUrlStart(state);
    case actionTypes.GET_SIGN_URL_SUCCESS:
      return getSignUrlSuccess(state, action);
    case actionTypes.GET_SIGN_URL_FAIL:
      return getSignUrlFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_FAIL:
      return prepareToSignCertificateFail(state, action);
    case actionTypes.START_SIGN_PROCESS_START:
      return startSignProcessStart(state);
    case actionTypes.START_SIGN_PROCESS_SUCCESS:
      return startSignProcessSuccess(state, action);
    case actionTypes.START_SIGN_PROCESS_FAIL:
      return startSignProcessFail(state, action);
    case actionTypes.SEND_CERTIFICATE_START:
      return sendCertificateStart(state, action);
    case actionTypes.SEND_CERTIFICATE_SUCCESS:
      return sendCertificateSuccess(state, action);
    case actionTypes.SEND_CERTIFICATE_FAIL:
      return sendCertificateFail(state, action);
    case actionTypes.CHECK_SIGN_STATUS_START:
      return checkSignStatusStart(state);
    case actionTypes.CHECK_SIGN_STATUS_SUCCESS:
      return checkSignStatusSuccess(state, action);
    case actionTypes.CHECK_SIGN_STATUS_FAIL:
      return checkSignStatusFail(state, action);
    case actionTypes.DELETE_EXTERNAL_CERTIFICATE_START:
      return deleteExternalCertificateStart(state);
    case actionTypes.DELETE_EXTERNAL_CERTIFICATE_SUCCESS:
      return deleteExternalCertificateSuccess(state);
    case actionTypes.DELETE_EXTERNAL_CERTIFICATE_FAIL:
      return deleteExternalCertificateFail(state, action);
    case actionTypes.FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_START:
      return fetchUnsectionedParticipantCertificatesStart(state);
    case actionTypes.FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_SUCCESS:
      return fetchUnsectionedParticipantCertificatesSuccess(state, action);
    case actionTypes.FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_FAIL:
      return fetchUnsectionedParticipantCertificatesFail(state, action);
    case actionTypes.FETCH_PARTICIPANT_SECTION_CERTIFICATES_START:
      return fetchParticipantSectionCertificatesStart(state);
    case actionTypes.FETCH_PARTICIPANT_SECTION_CERTIFICATES_SUCCESS:
      return fetchParticipantSectionCertificatesSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_SECTION_CERTIFICATES_FAIL:
      return fetchParticipantSectionCertificatesFail(state, action);
    case actionTypes.TOGGLE_CERTIFICATE_ARCHIVE_STATUS_START:
      return toggleCertificateArchiveStatusStart(state);
    case actionTypes.TOGGLE_CERTIFICATE_ARCHIVE_STATUS_SUCCESS:
      return toggleCertificateArchiveStatusSuccess(state);
    case actionTypes.TOGGLE_CERTIFICATE_ARCHIVE_STATUS_FAIL:
      return toggleCertificateArchiveStatusFail(state, action);
    case actionTypes.SIGN_ON_BEHALF_START:
      return signOnBehalfStart(state);
    case actionTypes.SIGN_ON_BEHALF_SUCCESS:
      return signOnBehalfSuccess(state);
    case actionTypes.SIGN_ON_BEHALF_FAIL:
      return signOnBehalfFail(state, action);
    case actionTypes.RESET_SIGN_ON_BEHALF:
      return resetSignOnBehalf(state, action);
    case actionTypes.RESET_PARTICIPANT_CERTIFICATE_LIST_STORE:
      return resetParticipantCertificateListStore(state);
    case actionTypes.RESET_SEND_CERTIFICATE_STORE:
      return resetSendCertificateStore(state);
    case actionTypes.RESET_SIGNED_CERTIFICATE_STATUS:
      return resetSignCertificateStatus(state);
    case actionTypes.RESET_EXTERNAL_CERTIFICATE_CREATE:
      return resetExternalCertificateCreate(state);
    case actionTypes.RESET_CERTIFICATE_STORE:
      return resetStore();
    default:
      return state;
  }
};

export default reducer;
