import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  createAdminStart,
  createAdminSuccess,
  createAdminFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFail,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  fetchAllUsersFail,
  fetchAllUsersStart,
  fetchAllUsersSuccess,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserInfoFail,
  updateCurrentOrganiserInfoStart,
  updateCurrentOrganiserInfoSuccess,
  updateCurrentOrganiserInfoFail,
  updateOrganiserInfoStart,
  updateOrganiserInfoSuccess,
  updateOrganiserInfoFail,
  changeUserPasswordStart,
  changeUserPasswordFail,
  changeUserPasswordSuccess,
  uploadUserCertificateImageStart,
  uploadUserCertificateImageSuccess,
  uploadUserCertificateImageFail,
  fetchUserWithCertificateImagesStart,
  fetchUserWithCertificateImagesSuccess,
  fetchUserWithCertificateImagesFail,
  deleteUserCertificateImageStart,
  deleteUserCertificateImageSuccess,
  deleteUserCertificateImageFail,
  uploadUserBackgroundImageStart,
  uploadUserBackgroundImagesSuccess,
  uploadUserBackgroundImageFail,
  fetchUserWithBackgroundImagesFail,
  fetchUserWithBackgroundImagesStart,
  fetchUserWithBackgroundImagesSuccess,
  fetchAdminsListStart,
  fetchAdminsListSuccess,
  fetchAdminsListFail,
  deleteUserBackgroundImageStart,
  deleteUserBackgroundImageFail,
  deleteUserBackgroundImageSuccess,
  fetchOverviewInfoStart,
  fetchOverviewInfoSuccess,
  fetchOverviewInfoFail,
  confirmConnectUserFail,
  confirmConnectUserStart,
  confirmConnectUserSuccess,
  connectUserFail,
  connectUserStart,
  connectUserSuccess,
  deleteConnectedUserFail,
  deleteConnectedUserStart,
  deleteConnectedUserSuccess,
  fetchConnectedUsersFail,
  fetchConnectedUsersStart,
  fetchConnectedUsersSuccess,
  resendConnectedUserInvitationFail,
  resendConnectedUserInvitationStart,
  resendConnectedUserInvitationSuccess,
  updateAdminStart,
  updateAdminSuccess,
  updateAdminFail,
  addOrganiserRoleSuccess,
  addOrganiserRoleFail,
  addOrganiserRoleStart,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { User } from '../../domain/User';
import { getFormData } from '../../utility/form/formData';
import { Roles } from '../../domain/Role';

export type AdminCreateRequest = {
  email: string;
  roles: Roles[];
  firstName: string;
  lastName: string;
};

export type UserUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
  balance: number;
};

export type AdminUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
};

export type UserUpdateInfoRequest = {
  firstName: string;
  lastName: string;
};

export type CurrentOrganiserUpdateInfoRequest = {
  firstName: string;
  lastName: string;
};

export type OrganiserUpdateInfoRequest = CurrentOrganiserUpdateInfoRequest & {
  userId?: number | string;
  balance?: number | string;
};

export type UserChangePasswordRequest = {
  currentPassword?: string;
  newPassword: string;
  newPasswordRepeat: string;
};

export type UserUploadCertificateImageRequest = {
  image: File;
};

export type UserUploadBackgroundImageRequest = {
  background: File;
};

export type ConnectUserRequest = {
  email: string;
};

export type UserListParams = ListParams & {
  role?: Roles;
};

export type OrganiserCreateRequest = {
  agreement: boolean;
};

const API_URL = '/users';

export const fetchUser = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err?.response?.data?.error));
    });
};

export const fetchUsers = (params: UserListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err?.response?.data?.error));
    });
};

export const fetchAllUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUsersStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchAllUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUsersFail(err?.response?.data?.error));
    });
};

export const fetchAdminsList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchAdminsListStart());
    return axios
      .get(`${API_URL}/admins`, { params })
      .then((response) => {
        dispatch(fetchAdminsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchAdminsListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const createAdmin =
  (inputs: AdminCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createAdminStart());
    return axios
      .post(`${API_URL}/admin`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createAdminSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.ADMIN_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createAdminFail(err?.response?.data?.message));
      });
  };

export const updateAdmin =
  (inputs: AdminUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateAdminStart());
    return axios
      .patch(`${API_URL}/admin/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateAdminSuccess());
        showToast(translate(intl, 'SUCCESS.ADMIN_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateAdminFail(err?.response?.data?.message));
      });
  };

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserFail(err?.response?.data?.message));
      });
  };

export const updateUserInfo =
  (inputs: UserUpdateInfoRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserInfoStart());
    return axios
      .patch(`${API_URL}/update-info`, { ...inputs })
      .then((response) => {
        dispatch(updateUserInfoSuccess());

        showToast(translate(intl, 'SUCCESS.USER_INFO_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserInfoFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateCurrentOrganiserInfo =
  (inputs: CurrentOrganiserUpdateInfoRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateCurrentOrganiserInfoStart());
    return axios
      .patch(`${API_URL}/update-current-organiser-info`, { ...inputs })
      .then((response) => {
        dispatch(updateCurrentOrganiserInfoSuccess());
        showToast(translate(intl, 'SUCCESS.USER_INFO_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCurrentOrganiserInfoFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateOrganiserInfo =
  (inputs: OrganiserUpdateInfoRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateOrganiserInfoStart());
    return axios
      .patch(`${API_URL}/update-organiser-info`, { ...inputs })
      .then((response) => {
        dispatch(updateOrganiserInfoSuccess());

        showToast(translate(intl, 'SUCCESS.USER_INFO_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateOrganiserInfoFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const deleteUser =
  (id: number, intl: IntlShape, isAdminDelete?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(deleteUserStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess());
        showToast(
          translate(
            intl,
            isAdminDelete ? 'SUCCESS.ADMIN_DELETE' : 'SUCCESS.USER_DELETE',
          ),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteUserFail(err?.response?.data?.message));
      });
  };

export const fetchCurrentUser =
  (refreshCurrentUser?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchCurrentUserStart(refreshCurrentUser));

    return axios
      .get(`${API_URL}/me`)
      .then((response) => {
        const user = response.data as User;
        dispatch(fetchCurrentUserSuccess(user));
      })
      .catch((err) => {
        dispatch(fetchCurrentUserFail());
      });
  };

export const changeUserPassword =
  (inputs: UserChangePasswordRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(changeUserPasswordStart());
    return axios
      .patch(`${API_URL}/change-password`, { ...inputs })
      .then(() => {
        dispatch(changeUserPasswordSuccess());
        showToast(translate(intl, 'SUCCESS.PASSWORD_CHANGE'), 'success');
      })
      .catch((err) => {
        dispatch(changeUserPasswordFail(err?.response?.data?.message));
      });
  };

export const fetchUserWithCertificateImages = () => (dispatch: Dispatch) => {
  dispatch(fetchUserWithCertificateImagesStart());
  return axios
    .get(`${API_URL}/certificate-images`)
    .then((response) => {
      dispatch(fetchUserWithCertificateImagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserWithCertificateImagesFail(err?.response?.data?.error));
    });
};

export const uploadUserCertificateImage =
  (inputs: UserUploadCertificateImageRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(uploadUserCertificateImageStart());
    return axios
      .post(`${API_URL}/upload-certificate-image`, getFormData(inputs))
      .then((response) => {
        dispatch(uploadUserCertificateImageSuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.USER_UPLOAD_CERTIFICATE_IMAGE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(uploadUserCertificateImageFail(err?.response?.data?.message));
      });
  };

export const deleteUserCertificateImage =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteUserCertificateImageStart());
    return axios
      .delete(`${API_URL}/certificate-image/${id}`)
      .then(() => {
        dispatch(deleteUserCertificateImageSuccess());
        showToast(
          translate(intl, 'SUCCESS.USER_DELETE_CERTIFICATE_IMAGE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteUserCertificateImageFail(err?.response?.data?.message));
      });
  };

export const getAsset = (assetId: number) => {
  return axios
    .get(`${API_URL}/download-file/${assetId}`, {
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/octet-binary',
      });

      return URL.createObjectURL(blob);
    })
    .catch(() => {
      return null;
    });
};

export const uploadUserBackgroundImage =
  (inputs: UserUploadBackgroundImageRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(uploadUserBackgroundImageStart());
    return axios
      .post(`${API_URL}/upload-background-image`, getFormData(inputs))
      .then((response) => {
        dispatch(uploadUserBackgroundImagesSuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.USER_UPLOAD_CERTIFICATE_BACKGROUND'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(uploadUserBackgroundImageFail(err?.response?.data?.message));
      });
  };

export const fetchUserWithBackgroundImages = () => (dispatch: Dispatch) => {
  dispatch(fetchUserWithBackgroundImagesStart());
  return axios
    .get(`${API_URL}/background-images`)
    .then((response) => {
      dispatch(fetchUserWithBackgroundImagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserWithBackgroundImagesFail(err?.response?.data?.error));
    });
};

export const deleteUserCertificateBackgroundImage =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteUserBackgroundImageStart());
    return axios
      .delete(`${API_URL}/certificate-background/${id}`)
      .then(() => {
        dispatch(deleteUserBackgroundImageSuccess());
        showToast(translate(intl, 'SUCCESS.USER_DELETE_BACKGROUND'), 'success');
      })
      .catch((err) => {
        dispatch(deleteUserBackgroundImageFail(err?.response?.data?.message));
      });
  };

export const fetchOverviewInfo = (intl: IntlShape) => (dispatch: Dispatch) => {
  dispatch(fetchOverviewInfoStart());
  return axios
    .get(`${API_URL}/overview-info`)
    .then((response) => {
      dispatch(fetchOverviewInfoSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchOverviewInfoFail(err?.response?.data?.error));
      showToast(translate(intl, err?.response?.data?.message), 'error');
    });
};

export const connectUser =
  (inputs: ConnectUserRequest) => (dispatch: Dispatch) => {
    dispatch(connectUserStart());
    return axios
      .post(`${API_URL}/connect-user`, {
        ...inputs,
      })
      .then(() => {
        dispatch(connectUserSuccess());
      })
      .catch((err) => {
        dispatch(connectUserFail(err?.response?.data?.message));
      });
  };

export const confirmConnectUser =
  (token: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(confirmConnectUserStart());
    return axios
      .patch(`${API_URL}/confirm-connection/${token}`)
      .then(() => {
        dispatch(confirmConnectUserSuccess());
        showToast(translate(intl, 'SUCCESS.CONNECT_USER'), 'success');
      })
      .catch((err) => {
        dispatch(confirmConnectUserFail());
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const deleteConnectedUser =
  (childUserId: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteConnectedUserStart());
    return axios
      .patch(`${API_URL}/connected-users/${childUserId}`)
      .then(() => {
        dispatch(deleteConnectedUserSuccess());
        showToast(translate(intl, 'SUCCESS.CONNECT_USER_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteConnectedUserFail(err?.response?.data?.message));
      });
  };

export const fetchConnectUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchConnectedUsersStart());
  return axios
    .get(`${API_URL}/connected-users`)
    .then((response) => {
      dispatch(fetchConnectedUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchConnectedUsersFail(err?.response?.data?.message));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const resendConnectedUserInvitation =
  (childUserId: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(resendConnectedUserInvitationStart());
    return axios
      .post(`${API_URL}/connected-users/${childUserId}/resend-email`)
      .then(() => {
        dispatch(resendConnectedUserInvitationSuccess());
        showToast(translate(intl, 'SUCCESS.RESEND_EMAIL'), 'success');
      })
      .catch((err) => {
        dispatch(
          resendConnectedUserInvitationFail(err?.response?.data?.message),
        );
      });
  };

export const addOrganiserRole =
  (inputs: OrganiserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(addOrganiserRoleStart());
    return axios
      .post(`${API_URL}/become-organiser`, {
        ...inputs,
      })
      .then(() => {
        dispatch(addOrganiserRoleSuccess());
        showToast(translate(intl, 'SUCCESS.ADD_ORGANISER_ROLE'), 'success');
      })
      .catch((err) => {
        dispatch(addOrganiserRoleFail(err?.response?.data?.message));
        showToast(err?.response?.data?.message, 'error');
      });
  };
