import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './SuccessModal.module.scss';
import Button from '../Button/Button';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ReactSVG } from 'react-svg';
import successIcon from '../../assets/icons/success.svg';
import failIcon from '../../assets/icons/fail.svg';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  title: string | ReactNode;
  description?: string | ReactNode;
  buttonText: string;
  isSuccess?: boolean;
  hideIcon?: boolean;
  modalContainerClassName?: string;
  bottomContent?: string | ReactNode;
  isTopCloseButtonVisible?: boolean;
  closeButtonClassName?: string;
};

const SuccessModal = ({
  isOpen,
  onClose,
  title,
  description,
  buttonText,
  modalContainerClassName,
  hideIcon,
  isSuccess = true,
  bottomContent,
  isTopCloseButtonVisible,
  closeButtonClassName,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={cx(styles.modalContainer, modalContainerClassName)}
    >
      {isTopCloseButtonVisible && (
        <div className={styles.topCloseButtonContainer}>
          <IconButton onClick={onClose} className={styles.topCloseButton}>
            <CloseIcon fontSize={'large'} />
          </IconButton>
        </div>
      )}

      {!hideIcon && (
        <div className={styles.successIconContainer}>
          <ReactSVG src={isSuccess ? successIcon : failIcon} />
        </div>
      )}
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <p className={styles.description}>{description}</p>
        <Button
          onClick={onClose}
          buttonVariant="contained"
          color="primary"
          isSecondaryButton
          type="submit"
          className={cx(styles.closeButton, closeButtonClassName)}
        >
          {buttonText}
        </Button>

        <div className={styles.bottomContent}>{bottomContent}</div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
