import * as actionTypes from './actionTypes';
import { BalanceHistoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { BalanceHistory } from '../../domain/BalanceHistory';

export type BalanceHistoryStateType = {
  balanceHistoryListError: HttpError;
  balanceHistoryList: ListResults<BalanceHistory> | null;
  balanceHistoryListLoading: boolean;
  balanceHistoryListUpdateNeeded: boolean;
};

export type BalanceHistoryActionType = BalanceHistoryStateType & {
  type: BalanceHistoryActionTypes;
};

export const initialState: BalanceHistoryStateType = {
  balanceHistoryListError: null,
  balanceHistoryList: null,
  balanceHistoryListLoading: true,
  balanceHistoryListUpdateNeeded: false,
};

const fetchBalanceHistoryStart = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoryListLoading: true,
});

const fetchBalanceHistorySuccess = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoryList: action.balanceHistoryList,
  balanceHistoryListLoading: false,
  balanceHistoryListError: null,
  balanceHistoryListUpdateNeeded: false,
});

const fetchBalanceHistoryFail = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoryListError: action.balanceHistoryListError,
  balanceHistoryListLoading: false,
});

const resetBalanceHistoryStore = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...initialState,
});

const completelyResetBalanceHistoryStore = (): BalanceHistoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BalanceHistoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BALANCE_HISTORY_START:
      return fetchBalanceHistoryStart(state);
    case actionTypes.FETCH_BALANCE_HISTORY_SUCCESS:
      return fetchBalanceHistorySuccess(state, action);
    case actionTypes.FETCH_BALANCE_HISTORY_FAIL:
      return fetchBalanceHistoryFail(state, action);
    case actionTypes.RESET_BALANCE_HISTORY_STORE:
      return resetBalanceHistoryStore(state);
    case actionTypes.LOGOUT:
      return completelyResetBalanceHistoryStore();
    default:
      return state;
  }
};

export default reducer;
