import * as actionTypes from './actionTypes';
import { UserInvoiceInfoActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserInvoiceInfo } from '../../domain/UserInvoiceInfo';
import { OrganiserDetails } from '../../domain/OrganiserDetails';

export type UserInvoiceInfoStateType = {
  userInvoiceInfo: UserInvoiceInfo | null;
  userInvoiceInfoError: HttpError;
  userInvoiceInfoLoading: boolean;
  currentUserInvoiceInfo: UserInvoiceInfo | null;
  currentUserInvoiceInfoError: HttpError;
  currentUserInvoiceInfoLoading: boolean;
  organiserDetailsLoading: boolean;
  organiserDetails: OrganiserDetails | null;
  organiserDetailsError: HttpError;
  userInvoiceInfoUpdateLoading: boolean;
  userInvoiceInfoUpdateError: HttpError;
  userInvoiceInfoUpdateSuccess: boolean;
  currentUserInvoiceInfoUpdateLoading: boolean;
  currentUserInvoiceInfoUpdateError: HttpError;
  currentUserInvoiceInfoUpdateSuccess: boolean;
};

export type UserInvoiceInfoActionType = UserInvoiceInfoStateType & {
  type: UserInvoiceInfoActionTypes;
  refreshCurrentUserInvoiceInfo: boolean;
};

export const initialState: UserInvoiceInfoStateType = {
  userInvoiceInfo: null,
  userInvoiceInfoError: null,
  userInvoiceInfoLoading: false,
  currentUserInvoiceInfo: null,
  currentUserInvoiceInfoError: null,
  currentUserInvoiceInfoLoading: false,
  organiserDetailsLoading: false,
  organiserDetails: null,
  organiserDetailsError: null,
  userInvoiceInfoUpdateError: null,
  userInvoiceInfoUpdateLoading: false,
  userInvoiceInfoUpdateSuccess: false,
  currentUserInvoiceInfoUpdateError: null,
  currentUserInvoiceInfoUpdateLoading: false,
  currentUserInvoiceInfoUpdateSuccess: false,
};

const fetchUserInvoiceInfoStart = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  userInvoiceInfoLoading: true,
});

const fetchUserInvoiceInfoSuccess = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  userInvoiceInfo: action.userInvoiceInfo,
  userInvoiceInfoLoading: false,
});

const fetchUserInvoiceInfoFail = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  userInvoiceInfoError: action.userInvoiceInfoError,
  userInvoiceInfoLoading: false,
});

const fetchCurrentUserInvoiceInfoStart = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  currentUserInvoiceInfoLoading: true,
});

const fetchCurrentUserInvoiceInfoSuccess = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  currentUserInvoiceInfo: action.currentUserInvoiceInfo,
  currentUserInvoiceInfoLoading: false,
});

const fetchCurrentUserInvoiceInfoFail = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  currentUserInvoiceInfoError: action.currentUserInvoiceInfoError,
  currentUserInvoiceInfoLoading: false,
});

const fetchOrganiserDetailsStart = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  organiserDetailsLoading: true,
});

const fetchOrganiserDetailsSuccess = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  organiserDetails: action.organiserDetails,
  organiserDetailsLoading: false,
});

const fetchOrganiserDetailsFail = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  organiserDetailsError: action.organiserDetailsError,
  organiserDetailsLoading: false,
});

const updateUserInvoiceInfoStart = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  userInvoiceInfoUpdateLoading: true,
  userInvoiceInfoUpdateSuccess: false,
});

const updateUserInvoiceInfoSuccess = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  userInvoiceInfoUpdateLoading: false,
  userInvoiceInfoUpdateError: null,
  userInvoiceInfoUpdateSuccess: true,
});

const updateUserInvoiceInfoFail = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  userInvoiceInfoUpdateLoading: false,
  userInvoiceInfoUpdateError: action.userInvoiceInfoUpdateError,
});

const updateCurrentUserInvoiceInfoStart = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  currentUserInvoiceInfoUpdateLoading: true,
  currentUserInvoiceInfoUpdateSuccess: false,
});

const updateCurrentUserInvoiceInfoSuccess = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...state,
  currentUserInvoiceInfoUpdateLoading: false,
  currentUserInvoiceInfoUpdateError: null,
  currentUserInvoiceInfoUpdateSuccess: true,
});

const updateCurrentUserInvoiceInfoFail = (
  state: UserInvoiceInfoStateType,
  action: UserInvoiceInfoActionType,
): UserInvoiceInfoStateType => ({
  ...state,
  currentUserInvoiceInfoUpdateLoading: false,
  currentUserInvoiceInfoUpdateError: action.currentUserInvoiceInfoUpdateError,
});

const resetUserInvoiceInfoStore = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...initialState,
});

const resetUpdateUserInvoiceInfoStore = (
  state: UserInvoiceInfoStateType,
): UserInvoiceInfoStateType => ({
  ...initialState,
  currentUserInvoiceInfoUpdateLoading: false,
  currentUserInvoiceInfoUpdateError: null,
  currentUserInvoiceInfoUpdateSuccess: false,
});

const completelyResetUserInvoiceInfoStore = (): UserInvoiceInfoStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserInvoiceInfoActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INVOICE_INFO_START:
      return fetchUserInvoiceInfoStart(state);
    case actionTypes.FETCH_USER_INVOICE_INFO_SUCCESS:
      return fetchUserInvoiceInfoSuccess(state, action);
    case actionTypes.FETCH_USER_INVOICE_INFO_FAIL:
      return fetchUserInvoiceInfoFail(state, action);
    case actionTypes.FETCH_CURRENT_USER_INVOICE_INFO_START:
      return fetchCurrentUserInvoiceInfoStart(state);
    case actionTypes.FETCH_CURRENT_USER_INVOICE_INFO_SUCCESS:
      return fetchCurrentUserInvoiceInfoSuccess(state, action);
    case actionTypes.FETCH_CURRENT_USER_INVOICE_INFO_FAIL:
      return fetchCurrentUserInvoiceInfoFail(state, action);
    case actionTypes.FETCH_ORGANISER_DETAILS_START:
      return fetchOrganiserDetailsStart(state);
    case actionTypes.FETCH_ORGANISER_DETAILS_SUCCESS:
      return fetchOrganiserDetailsSuccess(state, action);
    case actionTypes.FETCH_ORGANISER_DETAILS_FAIL:
      return fetchOrganiserDetailsFail(state, action);
    case actionTypes.UPDATE_USER_INVOICE_INFO_START:
      return updateUserInvoiceInfoStart(state);
    case actionTypes.UPDATE_USER_INVOICE_INFO_SUCCESS:
      return updateUserInvoiceInfoSuccess(state);
    case actionTypes.UPDATE_USER_INVOICE_INFO_FAIL:
      return updateUserInvoiceInfoFail(state, action);
    case actionTypes.UPDATE_CURRENT_USER_INVOICE_INFO_START:
      return updateCurrentUserInvoiceInfoStart(state);
    case actionTypes.UPDATE_CURRENT_USER_INVOICE_INFO_SUCCESS:
      return updateCurrentUserInvoiceInfoSuccess(state);
    case actionTypes.UPDATE_CURRENT_USER_INVOICE_INFO_FAIL:
      return updateCurrentUserInvoiceInfoFail(state, action);
    case actionTypes.RESET_USER_INVOICE_INFO_STORE:
      return resetUserInvoiceInfoStore(state);
    case actionTypes.RESET_UPDATE_USER_INVOICE_INFO_STORE:
      return resetUpdateUserInvoiceInfoStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserInvoiceInfoStore();
    default:
      return state;
  }
};

export default reducer;
