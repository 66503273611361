export const SET_ATTRIBUTE_VALUE_START = 'SET_USER_START';
export const SET_ATTRIBUTE_VALUE_SUCCESS = 'SET_ATTRIBUTE_VALUE_START';
export const SET_ATTRIBUTE_VALUE_FAIL = 'SET_ATTRIBUTE_VALUE_FAIL';

export const RESET_ATTRIBUTE_VALUE_STORE = 'RESET_ATTRIBUTE_VALUE_STORE';

export const LOGOUT = 'LOGOUT';

export type AttributeValueActionTypes =
  | typeof SET_ATTRIBUTE_VALUE_START
  | typeof SET_ATTRIBUTE_VALUE_SUCCESS
  | typeof SET_ATTRIBUTE_VALUE_FAIL
  | typeof RESET_ATTRIBUTE_VALUE_STORE
  | typeof LOGOUT;
