import React, { useState } from 'react';
import styles from './SignaturePurchaseModal.module.scss';
import Modal from '../../../../common/Modal/Modal';
import PlansSection from '../PlansSection/PlansSection';
import SignaturePurchaseSecondStep from './SignaturePurchaseSecondStep/SignaturePurchaseSecondStep';
import { CreditPackage } from '../../../../domain/CreditPackage';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isFullScreen?: boolean;
  isBackButtonVisible?: boolean;
};

const SignaturePurchaseModal = ({
  isOpen,
  onClose,
  isFullScreen,
  isBackButtonVisible,
}: Props) => {
  const [selectedPlan, setSelectedPlan] = useState<null | CreditPackage>(null);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      className={styles.signaturePurchaseModal}
      isFullScreen={isFullScreen}
      isBackButtonVisible={!selectedPlan && isBackButtonVisible}
      isCloseButtonHidden={!!selectedPlan && isBackButtonVisible}
    >
      {!selectedPlan && <PlansSection setSelectedPlan={setSelectedPlan} />}
      {selectedPlan && (
        <SignaturePurchaseSecondStep
          selectedPlan={selectedPlan}
          onBackButtonClick={() => setSelectedPlan(null)}
        />
      )}
    </Modal>
  );
};

export default SignaturePurchaseModal;
