import React from 'react';
import styles from './AuthAboutSection.module.scss';
import { ReactSVG } from 'react-svg';
import bookIcon from '../../../../assets/icons/book.svg';
import sms from '../../../../assets/icons/sms.svg';
import sign from '../../../../assets/icons/sign.svg';
import logo from '../../../../assets/procertyLogo_beta.png';
import macbook from '../../../../assets/macbook.png';
import { AboutProcerty } from '../../../../pages/Public/SignUpPage/SignUpPage';

type Props = {
  translations: AboutProcerty;
};

const AuthAboutSection = ({ translations }: Props) => {
  return (
    <div className={styles.leftContainerInner}>
      <div className={styles.contentContainer}>
        <img src={logo} alt="procerty" className={styles.logo} />
        <div className={styles.title}>{translations.title}</div>
        <div className={styles.infoContainer}>
          <div className={styles.infoItem}>
            <ReactSVG src={bookIcon} className={styles.icon} />
            <span>{translations.label1}</span>
          </div>
          <div className={styles.infoItem}>
            <ReactSVG src={sign} className={styles.icon} />
            <span>{translations.label2}</span>
          </div>
          <div className={styles.infoItem}>
            <ReactSVG src={sms} className={styles.icon} />
            <span>{translations.label3}</span>
          </div>
        </div>
      </div>
      <img src={macbook} alt="laptop" className={styles.macbook} />
    </div>
  );
};

export default AuthAboutSection;
