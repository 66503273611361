export const FETCH_TEMPLATE_LIST_START = 'FETCH_TEMPLATE_LIST_START';
export const FETCH_TEMPLATE_LIST_SUCCESS = 'FETCH_TEMPLATE_LIST_SUCCESS';
export const FETCH_TEMPLATE_LIST_FAIL = 'FETCH_TEMPLATE_LIST_FAIL';

export const FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAIL = 'FETCH_TEMPLATES_FAIL';

export const FETCH_TEMPLATE_START = 'FETCH_TEMPLATE_START';
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS';
export const FETCH_TEMPLATE_FAIL = 'FETCH_TEMPLATE_FAIL';

export const CREATE_TEMPLATE_START = 'CREATE_TEMPLATE_START';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAIL = 'CREATE_TEMPLATE_FAIL';

export const UPDATE_TEMPLATE_START = 'UPDATE_TEMPLATE_START';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAIL = 'UPDATE_TEMPLATE_FAIL';

export const DELETE_TEMPLATE_START = 'DELETE_TEMPLATE_START';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'DELETE_TEMPLATE_FAIL';

export const SAVE_TEMPLATE_START = 'SAVE_TEMPLATE_START';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAIL = 'SAVE_TEMPLATE_FAIL';

export const SAVE_TEMPLATE_THUMBNAIL_START = 'SAVE_TEMPLATE_THUMBNAIL_START';
export const SAVE_TEMPLATE_THUMBNAIL_SUCCESS =
  'SAVE_TEMPLATE_THUMBNAIL_SUCCESS';
export const SAVE_TEMPLATE_THUMBNAIL_FAIL = 'SAVE_TEMPLATE_THUMBNAIL_FAIL';

export const REPLACE_TEMPLATE_IMAGE_START = 'REPLACE_TEMPLATE_IMAGE_START';
export const REPLACE_TEMPLATE_IMAGE_SUCCESS = 'REPLACE_TEMPLATE_IMAGE_SUCCESS';
export const REPLACE_TEMPLATE_IMAGE_FAIL = 'REPLACE_TEMPLATE_IMAGE_FAIL';

export const RESET_CROP_IMAGE_STORE = 'RESET_CROP_IMAGE_STORE';
export const RESET_CREATED_TEMPLATE_STORE = 'RESET_CREATED_TEMPLATE_STORE';
export const RESET_TEMPLATE_SELECT_STORE = 'RESET_TEMPLATE_SELECT_STORE';
export const RESET_TEMPLATE_STORE = 'RESET_TEMPLATE_STORE';

export const LOGOUT = 'LOGOUT';

export type TemplateActionTypes =
  | typeof FETCH_TEMPLATE_LIST_START
  | typeof FETCH_TEMPLATE_LIST_SUCCESS
  | typeof FETCH_TEMPLATE_LIST_FAIL
  | typeof FETCH_TEMPLATES_START
  | typeof FETCH_TEMPLATES_SUCCESS
  | typeof FETCH_TEMPLATES_FAIL
  | typeof FETCH_TEMPLATE_START
  | typeof FETCH_TEMPLATE_SUCCESS
  | typeof FETCH_TEMPLATE_FAIL
  | typeof CREATE_TEMPLATE_START
  | typeof CREATE_TEMPLATE_SUCCESS
  | typeof CREATE_TEMPLATE_FAIL
  | typeof UPDATE_TEMPLATE_START
  | typeof UPDATE_TEMPLATE_SUCCESS
  | typeof UPDATE_TEMPLATE_FAIL
  | typeof DELETE_TEMPLATE_START
  | typeof DELETE_TEMPLATE_SUCCESS
  | typeof DELETE_TEMPLATE_FAIL
  | typeof SAVE_TEMPLATE_START
  | typeof SAVE_TEMPLATE_SUCCESS
  | typeof SAVE_TEMPLATE_FAIL
  | typeof SAVE_TEMPLATE_THUMBNAIL_START
  | typeof SAVE_TEMPLATE_THUMBNAIL_SUCCESS
  | typeof SAVE_TEMPLATE_THUMBNAIL_FAIL
  | typeof REPLACE_TEMPLATE_IMAGE_START
  | typeof REPLACE_TEMPLATE_IMAGE_SUCCESS
  | typeof REPLACE_TEMPLATE_IMAGE_FAIL
  | typeof RESET_CROP_IMAGE_STORE
  | typeof RESET_TEMPLATE_SELECT_STORE
  | typeof RESET_CREATED_TEMPLATE_STORE
  | typeof RESET_TEMPLATE_STORE
  | typeof LOGOUT;
