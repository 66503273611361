import * as actionTypes from './actionTypes';
import { ParticipantActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import {
  FailedCreateParticipantDto,
  ImportedParticipants,
  Participant,
  ParticipantOverviewInfo,
} from '../../domain/Participant';

export type ParticipantStateType = {
  participantCreateLoading: boolean;
  participantCreateError: HttpError;
  participantCreateSuccess: boolean;
  participantsCreateLoading: boolean;
  participantsCreateError: HttpError;
  participantsCreateSuccess: boolean;
  failedCreateParticipants: FailedCreateParticipantDto[] | null;
  importedParticipansCount: number | null;
  participantsListUpdateNeeded: boolean;
  participantDeleteLoading: boolean;
  participantDeleteError: HttpError;
  participantDeleteSuccess: boolean;
  participantsList: ListResults<Participant> | null;
  participantsListLoading: boolean;
  participantsListError: HttpError;
  participantUpdateLoading: boolean;
  participantUpdateError: HttpError;
  participantUpdateSuccess: boolean;
  updatedParticipant: Participant | null;
  participantWithAttributesUpdateLoading: boolean;
  participantWithAttributesUpdateError: HttpError;
  participantWithAttributesUpdateSuccess: boolean;
  updatedParticipantWithAttributes: Participant | null;
  multipleParticipantsDeleteLoading: boolean;
  multipleParticipantsDeleteError: HttpError;
  multipleParticipantsDeleteSuccess: boolean;
  searchedParticipant: string | null;
  participantOverviewInfo: ParticipantOverviewInfo | null;
  participantOverviewInfoError: HttpError;
  participantOverviewInfoLoading: boolean;
  participantOverviewInfoSuccess: boolean;
  participantsSuggestions: Participant[] | null;
  participantsSuggestionsError: HttpError;
  participantsSuggestionsLoading: boolean;
  participantsCertificatesStatusesDeleteLoading: boolean;
  participantsCertificatesStatusesDeleteError: HttpError;
  participantsCertificatesStatusesDeleteSuccess: boolean;
};

export type ParticipantActionType = ParticipantStateType & {
  type: ParticipantActionTypes;
  importedParticipants: ImportedParticipants;
};

export const initialState: ParticipantStateType = {
  participantCreateLoading: false,
  participantCreateError: null,
  participantCreateSuccess: false,
  participantsCreateLoading: false,
  participantsCreateError: null,
  participantsCreateSuccess: false,
  failedCreateParticipants: null,
  importedParticipansCount: null,
  participantsListUpdateNeeded: false,
  participantDeleteError: null,
  participantDeleteLoading: false,
  participantDeleteSuccess: false,
  participantsList: null,
  participantsListLoading: true,
  participantsListError: null,
  participantUpdateLoading: false,
  participantUpdateError: null,
  participantUpdateSuccess: false,
  updatedParticipant: null,
  participantWithAttributesUpdateLoading: false,
  participantWithAttributesUpdateError: null,
  participantWithAttributesUpdateSuccess: false,
  updatedParticipantWithAttributes: null,
  multipleParticipantsDeleteError: null,
  multipleParticipantsDeleteLoading: false,
  multipleParticipantsDeleteSuccess: false,
  searchedParticipant: null,
  participantOverviewInfo: null,
  participantOverviewInfoError: null,
  participantOverviewInfoLoading: false,
  participantOverviewInfoSuccess: false,
  participantsSuggestions: null,
  participantsSuggestionsError: null,
  participantsSuggestionsLoading: false,
  participantsCertificatesStatusesDeleteLoading: false,
  participantsCertificatesStatusesDeleteError: null,
  participantsCertificatesStatusesDeleteSuccess: true,
};

const fetchParticipantsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsListLoading: true,
  participantCreateSuccess: false,
  participantsCreateSuccess: false,
});

const fetchParticipantsSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsList: action.participantsList,
  participantsListLoading: false,
  participantsListError: null,
  participantsListUpdateNeeded: false,
});

const fetchParticipantsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsListError: action.participantsListError,
  participantsListLoading: false,
});

const fetchParticipantOverviewInfoStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantOverviewInfoLoading: true,
});

const fetchParticipantOverviewInfoSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantOverviewInfo: action.participantOverviewInfo,
  participantOverviewInfoLoading: false,
  participantOverviewInfoError: null,
  participantOverviewInfoSuccess: true,
});

const fetchParticipantOverviewInfoFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantOverviewInfoError: action.participantOverviewInfoError,
  participantOverviewInfoLoading: false,
});

const fetchParticipantsSuggestionsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsSuggestionsLoading: true,
});

const fetchParticipantsSuggestionsSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsSuggestions: action.participantsSuggestions,
  participantsSuggestionsLoading: false,
  participantsSuggestionsError: null,
});

const fetchParticipantsSuggestionsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsSuggestionsError: action.participantsSuggestionsError,
  participantsSuggestionsLoading: false,
});

const createParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantCreateLoading: true,
});

const createParticipantSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantCreateLoading: false,
  participantCreateError: null,
  participantCreateSuccess: true,
  participantsListUpdateNeeded: true,
});

const createParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantCreateLoading: false,
  participantCreateError: action.participantCreateError,
});

const createParticipantsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsCreateLoading: true,
});

const createParticipantsSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsCreateLoading: false,
  participantsCreateError: null,
  participantsCreateSuccess: true,
  participantsListUpdateNeeded: true,
  failedCreateParticipants:
    action.importedParticipants.failedCreateParticipants,
  importedParticipansCount:
    action.importedParticipants.importedParticipantsCount,
});

const createParticipantsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsCreateLoading: false,
  participantsCreateError: action.participantsCreateError,
});

const deleteParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantDeleteLoading: true,
});

const deleteParticipantSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantDeleteLoading: false,
  participantDeleteError: null,
  participantDeleteSuccess: true,
  participantsListUpdateNeeded: true,
});

const deleteParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantDeleteLoading: false,
  participantDeleteError: action.participantDeleteError,
});

const deleteParticipantsCertificatesStatusesStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsCertificatesStatusesDeleteLoading: true,
});

const deleteParticipantsCertificatesStatusesSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsCertificatesStatusesDeleteLoading: false,
  participantsCertificatesStatusesDeleteError: null,
  participantsCertificatesStatusesDeleteSuccess: true,
});

const deleteParticipantsCertificatesStatusesFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsCertificatesStatusesDeleteLoading: false,
  participantsCertificatesStatusesDeleteError:
    action.participantsCertificatesStatusesDeleteError,
});

const updateParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: true,
});

const updateParticipantSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: false,
  participantUpdateError: null,
  participantUpdateSuccess: true,
  participantsListUpdateNeeded: true,
  updatedParticipant: action.updatedParticipant,
});

const updateParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: false,
  participantUpdateError: action.participantUpdateError,
});

const updateParticipantWithAttributesStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantWithAttributesUpdateLoading: true,
});

const updateParticipantWithAttributesSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantWithAttributesUpdateLoading: false,
  participantWithAttributesUpdateError: null,
  participantWithAttributesUpdateSuccess: true,
  updatedParticipantWithAttributes: action.updatedParticipantWithAttributes,
  participantsListUpdateNeeded: true,
});

const updateParticipantWithAttributesFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantWithAttributesUpdateLoading: false,
  participantWithAttributesUpdateError:
    action.participantWithAttributesUpdateError,
});

const deleteMultipleParticipantsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  multipleParticipantsDeleteLoading: true,
});

const deleteMultipleParticipantsSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  multipleParticipantsDeleteLoading: false,
  multipleParticipantsDeleteError: null,
  multipleParticipantsDeleteSuccess: true,
  participantsListUpdateNeeded: true,
});

const deleteMultipleParticipantsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  multipleParticipantsDeleteLoading: false,
  multipleParticipantsDeleteError: action.multipleParticipantsDeleteError,
});

const resetDeletedParticipantsStatusesStore = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsCertificatesStatusesDeleteLoading: false,
  participantsCertificatesStatusesDeleteError: null,
  participantsCertificatesStatusesDeleteSuccess: false,
});

const resetParticipantsSuggestions = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsSuggestions: null,
  participantsSuggestionsLoading: false,
  participantsSuggestionsError: null,
});

const resetDeletedMultipleParticipantsStore = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  multipleParticipantsDeleteLoading: false,
  multipleParticipantsDeleteError: null,
  multipleParticipantsDeleteSuccess: false,
});

const setSearchedParticipant = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  searchedParticipant: action.searchedParticipant,
});

const resetSearchedParticipant = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  searchedParticipant: null,
});

const resetUpdatedParticipantWithAttributesStore = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantWithAttributesUpdateError: null,
  participantWithAttributesUpdateSuccess: false,
  participantWithAttributesUpdateLoading: false,
});

const resetParticipantStore = (): ParticipantStateType => ({
  ...initialState,
});

const resetUpdateParticipantStore = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: false,
  participantUpdateError: null,
  participantUpdateSuccess: false,
  updatedParticipant: null,
});

const completelyResetParticipantStore = (): ParticipantStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ParticipantActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PARTICIPANTS_START:
      return fetchParticipantsStart(state);
    case actionTypes.FETCH_PARTICIPANTS_SUCCESS:
      return fetchParticipantsSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANTS_FAIL:
      return fetchParticipantsFail(state, action);
    case actionTypes.FETCH_PARTICIPANT_OVERVIEW_INFO_START:
      return fetchParticipantOverviewInfoStart(state);
    case actionTypes.FETCH_PARTICIPANT_OVERVIEW_INFO_SUCCESS:
      return fetchParticipantOverviewInfoSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_OVERVIEW_INFO_FAIL:
      return fetchParticipantOverviewInfoFail(state, action);
    case actionTypes.FETCH_PARTICIPANTS_SUGGESTIONS_START:
      return fetchParticipantsSuggestionsStart(state);
    case actionTypes.FETCH_PARTICIPANTS_SUGGESTIONS_SUCCESS:
      return fetchParticipantsSuggestionsSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANTS_SUGGESTIONS_FAIL:
      return fetchParticipantsSuggestionsFail(state, action);
    case actionTypes.CREATE_PARTICIPANT_START:
      return createParticipantStart(state);
    case actionTypes.CREATE_PARTICIPANT_SUCCESS:
      return createParticipantSuccess(state);
    case actionTypes.CREATE_PARTICIPANT_FAIL:
      return createParticipantFail(state, action);
    case actionTypes.CREATE_PARTICIPANTS_START:
      return createParticipantsStart(state);
    case actionTypes.CREATE_PARTICIPANTS_SUCCESS:
      return createParticipantsSuccess(state, action);
    case actionTypes.CREATE_PARTICIPANTS_FAIL:
      return createParticipantsFail(state, action);
    case actionTypes.DELETE_PARTICIPANT_START:
      return deleteParticipantStart(state);
    case actionTypes.DELETE_PARTICIPANT_SUCCESS:
      return deleteParticipantSuccess(state);
    case actionTypes.DELETE_PARTICIPANT_FAIL:
      return deleteParticipantFail(state, action);
    case actionTypes.UPDATE_PARTICIPANT_START:
      return updateParticipantStart(state);
    case actionTypes.UPDATE_PARTICIPANT_SUCCESS:
      return updateParticipantSuccess(state, action);
    case actionTypes.UPDATE_PARTICIPANT_FAIL:
      return updateParticipantFail(state, action);
    case actionTypes.UPDATE_PARTICIPANT_WITH_ATTRIBUTES_START:
      return updateParticipantWithAttributesStart(state);
    case actionTypes.UPDATE_PARTICIPANT_WITH_ATTRIBUTES_SUCCESS:
      return updateParticipantWithAttributesSuccess(state, action);
    case actionTypes.UPDATE_PARTICIPANT_WITH_ATTRIBUTES_FAIL:
      return updateParticipantWithAttributesFail(state, action);
    case actionTypes.DELETE_MULTIPLE_PARTICIPANTS_START:
      return deleteMultipleParticipantsStart(state);
    case actionTypes.DELETE_MULTIPLE_PARTICIPANTS_SUCCESS:
      return deleteMultipleParticipantsSuccess(state);
    case actionTypes.DELETE_MULTIPLE_PARTICIPANTS_FAIL:
      return deleteMultipleParticipantsFail(state, action);
    case actionTypes.DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_START:
      return deleteParticipantsCertificatesStatusesStart(state);
    case actionTypes.DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_SUCCESS:
      return deleteParticipantsCertificatesStatusesSuccess(state);
    case actionTypes.DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_FAIL:
      return deleteParticipantsCertificatesStatusesFail(state, action);
    case actionTypes.RESET_DELETED_PARTICIPANTS_CERTIFICATES_STATUSES_STORE:
      return resetDeletedParticipantsStatusesStore(state);
    case actionTypes.RESET_PARTICIPANTS_SUGGESTIONS:
      return resetParticipantsSuggestions(state);
    case actionTypes.RESET_DELETED_MULTIPLE_PARTICIPANTS_STORE:
      return resetDeletedMultipleParticipantsStore(state);
    case actionTypes.SET_SEARCHED_PARTICIPANT:
      return setSearchedParticipant(state, action);
    case actionTypes.RESET_UPDATED_PARTICIPANT_WITH_ATTRIBUTES_PARTICIPANT:
      return resetUpdatedParticipantWithAttributesStore(state);
    case actionTypes.RESET_SEARCHED_PARTICIPANT:
      return resetSearchedParticipant(state);
    case actionTypes.RESET_PARTICIPANT_STORE:
      return resetParticipantStore();
    case actionTypes.RESET_UPDATE_PARTICIPANT_STORE:
      return resetUpdateParticipantStore(state);
    case actionTypes.LOGOUT:
      return completelyResetParticipantStore();
    default:
      return state;
  }
};

export default reducer;
