import useDelay from '../useDelay/useDelay';
import { useState } from 'react';

const SEARCH_DELAY = 200;

export interface UseDelayedSearch {
  searchValue: string | null;
  setSearchValue: (value: string | null) => void;
}

const useDelayedSearch = (
  handleSearch: (value: string) => void,
  initSearchValue?: string | null,
): UseDelayedSearch => {
  const [searchValue, setSearchValue] = useState<string | null>(
    initSearchValue ?? null,
  );

  useDelay(searchValue, SEARCH_DELAY, (newValue, oldValue) => {
    if (newValue !== null && newValue !== oldValue) {
      handleSearch(newValue as string);
    }
  });

  return {
    searchValue,
    setSearchValue,
  };
};

export default useDelayedSearch;
