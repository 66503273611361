export const FETCH_USER_INVOICE_INFO_START = 'FETCH_USER_INVOICE_INFO_START';
export const FETCH_USER_INVOICE_INFO_SUCCESS =
  'FETCH_USER_INVOICE_INFO_SUCCESS';
export const FETCH_USER_INVOICE_INFO_FAIL = 'FETCH_USER_INVOICE_INFO_FAIL';

export const FETCH_CURRENT_USER_INVOICE_INFO_START =
  'FETCH_CURRENT_USER_INVOICE_INFO_START';
export const FETCH_CURRENT_USER_INVOICE_INFO_SUCCESS =
  'FETCH_CURRENT_USER_INVOICE_INFO_SUCCESS';
export const FETCH_CURRENT_USER_INVOICE_INFO_FAIL =
  'FETCH_CURRENT_USER_INVOICE_INFO_FAIL';

export const FETCH_ORGANISER_DETAILS_START = 'FETCH_ORGANISER_DETAILS_START';
export const FETCH_ORGANISER_DETAILS_SUCCESS =
  'FETCH_ORGANISER_DETAILS_SUCCESS';
export const FETCH_ORGANISER_DETAILS_FAIL = 'FETCH_ORGANISER_DETAILS_FAIL';

export const UPDATE_USER_INVOICE_INFO_START = 'UPDATE_USER_INVOICE_INFO_START';
export const UPDATE_USER_INVOICE_INFO_SUCCESS =
  'UPDATE_USER_INVOICE_INFO_SUCCESS';
export const UPDATE_USER_INVOICE_INFO_FAIL = 'UPDATE_USER_INVOICE_INFO_FAIL';

export const UPDATE_CURRENT_USER_INVOICE_INFO_START =
  'UPDATE_CURRENT_USER_INVOICE_INFO_START';
export const UPDATE_CURRENT_USER_INVOICE_INFO_SUCCESS =
  'UPDATE_CURRENT_USER_INVOICE_INFO_SUCCESS';
export const UPDATE_CURRENT_USER_INVOICE_INFO_FAIL =
  'UPDATE_CURRENT_USER_INVOICE_INFO_FAIL';

export const RESET_USER_INVOICE_INFO_STORE = 'RESET_USER_INVOICE_INFO_STORE';
export const RESET_UPDATE_USER_INVOICE_INFO_STORE =
  'RESET_UPDATE_USER_INVOICE_INFO_STORE';

export const LOGOUT = 'LOGOUT';

export type UserInvoiceInfoActionTypes =
  | typeof FETCH_USER_INVOICE_INFO_START
  | typeof FETCH_USER_INVOICE_INFO_SUCCESS
  | typeof FETCH_USER_INVOICE_INFO_FAIL
  | typeof FETCH_CURRENT_USER_INVOICE_INFO_START
  | typeof FETCH_CURRENT_USER_INVOICE_INFO_SUCCESS
  | typeof FETCH_CURRENT_USER_INVOICE_INFO_FAIL
  | typeof FETCH_ORGANISER_DETAILS_START
  | typeof FETCH_ORGANISER_DETAILS_SUCCESS
  | typeof FETCH_ORGANISER_DETAILS_FAIL
  | typeof UPDATE_USER_INVOICE_INFO_START
  | typeof UPDATE_USER_INVOICE_INFO_SUCCESS
  | typeof UPDATE_USER_INVOICE_INFO_FAIL
  | typeof UPDATE_CURRENT_USER_INVOICE_INFO_START
  | typeof UPDATE_CURRENT_USER_INVOICE_INFO_SUCCESS
  | typeof UPDATE_CURRENT_USER_INVOICE_INFO_FAIL
  | typeof RESET_USER_INVOICE_INFO_STORE
  | typeof RESET_UPDATE_USER_INVOICE_INFO_STORE
  | typeof LOGOUT;
