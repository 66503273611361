import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import cx from 'classnames';
import styles from './PublicSidebarItemsGroup.module.scss';
import { PublicNavItem } from '../../PublicNavigation';

type Props = {
  sidebarItems: PublicNavItem[];
};

const PublicSidebarItemsGroup = ({ sidebarItems }: Props) => {
  if (!sidebarItems) {
    return <></>;
  }

  return (
    <div className={styles.sidebarItemsGroup}>
      {sidebarItems.map((sidebarItem) =>
        sidebarItem.to ? (
          <NavLink
            key={sidebarItem.label}
            to={sidebarItem.to}
            className={({ isActive }) =>
              cx(styles.sidebarItemContainer, {
                [styles.activeSidebarItem]: isActive,
              })
            }
            target={sidebarItem.targetBlank ? '_blank' : undefined}
          >
            <ReactSVG src={sidebarItem.icon} />
            <span>{sidebarItem.label}</span>
          </NavLink>
        ) : (
          <div
            className={styles.sidebarItemContainer}
            key={sidebarItem.label}
            onClick={sidebarItem.onClick}
          >
            <ReactSVG src={sidebarItem.icon} />
            {sidebarItem.label}
          </div>
        ),
      )}
    </div>
  );
};

export default PublicSidebarItemsGroup;
