import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  CreditPackage,
  CreditPackageListType,
} from '../../domain/CreditPackage';

export const fetchCreditPackagesStart = () => ({
  type: actionTypes.FETCH_CREDIT_PACKAGES_START,
});

export const fetchCreditPackagesSuccess = (
  creditPackagesList: CreditPackageListType,
) => ({
  type: actionTypes.FETCH_CREDIT_PACKAGES_SUCCESS,
  creditPackagesList,
});

export const fetchCreditPackagesFail = (creditPackagesError: HttpError) => ({
  type: actionTypes.FETCH_CREDIT_PACKAGES_FAIL,
  creditPackagesError,
});

export const fetchPublicCreditPackagesStart = () => ({
  type: actionTypes.FETCH_PUBLIC_CREDIT_PACKAGES_START,
});

export const fetchPublicCreditPackagesSuccess = (
  publicCreditPackages: CreditPackage[],
) => ({
  type: actionTypes.FETCH_PUBLIC_CREDIT_PACKAGES_SUCCESS,
  publicCreditPackages,
});

export const fetchPublicCreditPackagesFail = (
  publicCreditPackagesError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_CREDIT_PACKAGES_FAIL,
  publicCreditPackagesError,
});

export const fetchCreditPackageStart = () => ({
  type: actionTypes.FETCH_CREDIT_PACKAGE_START,
});

export const fetchCreditPackageSuccess = (creditPackage: CreditPackage) => ({
  type: actionTypes.FETCH_CREDIT_PACKAGE_SUCCESS,
  creditPackage,
});

export const fetchCreditPackageFail = (creditPackageError: HttpError) => ({
  type: actionTypes.FETCH_CREDIT_PACKAGE_FAIL,
  creditPackageError,
});

export const fetchCreditPackageOptionsFail = (
  creditPackageOptionsError: HttpError,
) => ({
  type: actionTypes.FETCH_CREDIT_PACKAGE_FAIL,
  creditPackageOptionsError,
});

export const updateCreditPackageStart = () => ({
  type: actionTypes.UPDATE_CREDIT_PACKAGE_START,
});

export const updateCreditPackageSuccess = () => ({
  type: actionTypes.UPDATE_CREDIT_PACKAGE_SUCCESS,
});

export const updateCreditPackageFail = (
  creditPackageUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_CREDIT_PACKAGE_FAIL,
  creditPackageUpdateError,
});

export const onEditedCreditPackageReset = () => ({
  type: actionTypes.RESET_EDITED_CREDIT_PACKAGE,
});

export const resetCreditPackagesStore = () => ({
  type: actionTypes.RESET_CREDIT_PACKAGES_STORE,
});
