import React from 'react';
import styles from './PlanCard.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../../../../utility/messageTranslator/translate';
import Button from '../../../../../common/Button/Button';
import cx from 'classnames';
import { ReactSVG } from 'react-svg';
import bagIcon from '../../../../../assets/icons/bag.svg';
import checkmarkIcon from '../../../../../assets/icons/check_mark_circle.svg';

type Props = {
  icon: string;
  name: string;
  description: string;
  price: number;
  isTheMostPopular?: boolean;
  onPurchaseButtonClick?: () => void;
  isPurchaseButtonHidden?: boolean;
  credits: number;
  additionalCredits: number;
};

const PlanCard = ({
  icon,
  name,
  description,
  price,
  isTheMostPopular,
  onPurchaseButtonClick,
  isPurchaseButtonHidden,
  credits,
  additionalCredits,
}: Props) => {
  const intl = useIntl();

  return (
    <div
      className={cx(styles.cardContainer, {
        [styles.isTheMostPopular]: isTheMostPopular,
      })}
    >
      <div>
        <ReactSVG className={styles.icon} src={icon} />
        {isTheMostPopular && (
          <div className={styles.theMostPopularMessageContainer}>
            {translate(intl, 'PLANS_CARD.POPULAR')}
          </div>
        )}
        <div className={styles.title}>{name}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.price}>{`${price} €`}</div>
        <hr className={styles.line} />
        <div className={styles.prosContainer}>
          <div className={styles.pros}>
            <ReactSVG className={styles.checkmark} src={checkmarkIcon} />
            <p>{`${credits} ${translate(intl, 'PLAN_CARD.CREDITS')}`}</p>
          </div>
          {additionalCredits > 0 && (
            <div className={styles.pros}>
              <ReactSVG className={styles.checkmark} src={checkmarkIcon} />
              <p>{`${additionalCredits} ${translate(
                intl,
                'PLAN_CARD.BONUS_CREDITS',
              )}`}</p>
            </div>
          )}
        </div>
      </div>
      {!isPurchaseButtonHidden && (
        <div>
          <Button
            className={styles.purchaseButton}
            type="button"
            isLoadingButton={!isTheMostPopular}
            isSecondaryButton={isTheMostPopular}
            onClick={onPurchaseButtonClick}
          >
            <ReactSVG className={styles.bagIcon} src={bagIcon} />
            {translate(intl, 'PLANS_CARD.PURCHASE_BUTTON')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PlanCard;
