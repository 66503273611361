import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Button from '../../../../common/Button/Button';
import { LoginGoogleRequest } from '../../../../store/auth/service';
import { connect } from 'react-redux';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../../../store/auth/service';
import Alert from '../../../../common/Alert/Alert';
import { HttpError } from '../../../../config/Axios/axios-instance';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import styles from './SocialLogin.module.scss';
import { ReactSVG } from 'react-svg';
import googleIcon from '../../../../assets/icons/google.svg';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import { resetGoogleAuthStore } from '../../../../store/auth/actions';

type GoogleError = {
  details?: string;
  error?: string;
};

type Props = {
  onResetGoogleAuthStore: () => void;
  onLoginGoogleSubmit: (inputs: LoginGoogleRequest) => void;
  isLoading: boolean;
  error: HttpError;
  isRegistration?: boolean;
};

const SocialLogin = ({
  onResetGoogleAuthStore,
  onLoginGoogleSubmit,
  error,
  isLoading,
  isRegistration,
}: Props) => {
  const [googleError, setGoogleError] = useState<string | GoogleError | null>(
    null,
  );
  const intl = useIntl();

  useEffect(() => {
    return () => onResetGoogleAuthStore();
  }, [isRegistration]);

  const handleSuccessGoogle = (response: any) => {
    handleSubmitGoogle(response);
  };

  const handleSubmitGoogle = (response: any) => {
    const data = {
      token: response.access_token,
      email: '',
      firstName: '',
      lastName: '',
      imageUrl: '',
    };

    onLoginGoogleSubmit(data);
  };

  const handleFailureGoogle = () => {
    setGoogleError('');
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleSuccessGoogle,
    onError: handleFailureGoogle,
  });

  const buttonLabel = isRegistration
    ? translate(intl, 'SIGN_UP.REGISTER_GOOGLE')
    : translate(intl, 'SOCIAL_LOGIN.LOGIN_GOOGLE');

  const globalError = getGlobalError(error, intl);

  return (
    <div className={styles.socialLogin}>
      {globalError && (
        <Alert className={styles.loginError} variant="danger">
          {translate(intl, globalError)}
        </Alert>
      )}
      {!!googleError && (
        <Alert className={styles.loginError} variant="danger">
          {typeof googleError !== 'string'
            ? googleError?.details
              ? googleError?.details
              : googleError.error ?? ''
            : null}
        </Alert>
      )}
      <Button
        onClick={() => handleGoogleLogin()}
        buttonVariant="contained"
        color="secondary"
        className={styles.googleButton}
      >
        <>
          <ReactSVG src={googleIcon} /> <span>{buttonLabel}</span>
        </>
      </Button>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.loginGoogleError,
  isLoading: state.auth.loginGoogleLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onResetGoogleAuthStore: () => dispatch(resetGoogleAuthStore()),
  onLoginGoogleSubmit: (inputs: LoginGoogleRequest) =>
    dispatch(authService.loginWithGoogle(inputs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
