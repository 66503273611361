import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './RegisterForm.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { RegistrationRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import { resetRegistration } from '../../../../store/auth/actions';
import { Locale } from '../../../../domain/Translation';
import { useParams } from 'react-router-dom';
import { User } from '../../../../domain/User';
import Loader from '../../../../common/Loader/Loader';
import SuccessModal from '../../../../common/SuccessModal/SuccessModal';

type Props = {
  onRegister: (inputs: RegistrationRequest) => void;
  isLoading: boolean;
  error: HttpError;
  registrationSuccess: boolean;
  onResetRegistration: () => void;
  selectedLocale: Locale;
  validatedUser: null | User;
};

type FormInputs = {
  email: string;
  password: string;
  passwordRepeat: string;
};

export const RegisterForm = ({
  onRegister,
  isLoading,
  error,
  registrationSuccess,
  onResetRegistration,
  selectedLocale,
  validatedUser,
}: Props) => {
  const intl = useIntl();
  const { code } = useParams<{ code: string | undefined }>();
  const [isLoaded, setIsLoaded] = useState<boolean>();

  const [isSuccessRegistrationModalOpen, setSuccessRegistrationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    return () => onResetRegistration();
  }, []);

  useEffect(() => {
    if (!isLoading && (!code || (validatedUser && code))) {
      setIsLoaded(true);
    }
  }, [isLoading, validatedUser, code]);

  const INPUTS = useMemo(() => {
    if (code) {
      return [
        {
          name: 'password',
          label: translate(intl, 'REGISTRATION_FORM.PASSWORD'),
          type: 'password',
          validation: [
            {
              type: 'required',
            },
            {
              type: 'minLength',
              parameter: 8,
            },
            {
              type: 'maxLength',
              parameter: 30,
            },
          ],
        },
      ];
    }

    return [
      {
        name: 'email',
        label: translate(intl, 'REGISTRATION_FORM.EMAIL'),
        type: 'text',
        placeholder: translate(intl, 'REGISTRATION_FORM.PLACHEHOLDER_EMAIL'),
        validation: [
          {
            type: 'required',
          },
          {
            type: 'email',
          },
          {
            type: 'minLength',
            parameter: 4,
          },
          {
            type: 'maxLength',
            parameter: 60,
          },
        ],
      },
      {
        name: 'password',
        label: translate(intl, 'REGISTRATION_FORM.PASSWORD'),
        type: 'password',
        placeholder: translate(intl, 'REGISTRATION_FORM.PLACHEHOLDER_PASSWORD'),
        validation: [
          {
            type: 'required',
          },
          {
            type: 'minLength',
            parameter: 8,
          },
          {
            type: 'maxLength',
            parameter: 30,
          },
        ],
      },
      {
        name: 'passwordRepeat',
        label: translate(intl, 'REGISTRATION_FORM.PASSWORD_REPEAT'),
        type: 'password',
        placeholder: translate(
          intl,
          'REGISTRATION_FORM.PLACHEHOLDER_PASSWORD_REPEAT',
        ),
        validation: [
          {
            type: 'required',
          },
          {
            type: 'minLength',
            parameter: 8,
          },
          {
            type: 'maxLength',
            parameter: 30,
          },
        ],
      },
    ];
  }, [code]);

  const handleSubmit = async (submitInputs: FormInputs) => {
    onRegister({
      email: submitInputs.email,
      password: submitInputs.password,
      passwordRepeat: submitInputs.passwordRepeat,
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onSetValidationErrors,
    onInputBlur,
    onLoseInputFocus,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  useEffect(() => {
    if (registrationSuccess) {
      setSuccessRegistrationModalOpen(true);
    }
  }, [registrationSuccess]);

  if (!isLoaded && (isLoading || !validatedUser || !code) && !error) {
    return <Loader isLoading={isLoading} height="300" />;
  }

  return (
    <div className={styles.registrationForm}>
      <Form
        className={styles.form}
        error={error}
        onSubmit={onSubmit}
        scrollIntoView={false}
      >
        {registrationSuccess && (
          <Alert variant="success" capitalize={false}>
            {translate(intl, 'REGISTRATION_FORM.SUCCESS_MESSAGE')}
          </Alert>
        )}
        {globalError && (
          <Alert variant="danger" capitalize={false}>
            {translate(intl, globalError)}
          </Alert>
        )}
        {!registrationSuccess && (
          <>
            {inputs.map((input) => (
              <TextField
                key={input.name}
                onChange={onInputChange}
                value={input.value?.toString() ?? ''}
                label={input.label ?? ''}
                errors={input.validationErrors ?? []}
                name={input.name}
                type={input.type}
                onInputBlur={onInputBlur}
                onBlur={onLoseInputFocus}
                placeholder={input.placeholder}
                inputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: '-1rem',
                  },
                }}
              />
            ))}
            <p className={styles.termsAndConditionsMessage}>
              {translate(
                intl,
                'REGISTRATION_FORM.TERMS_AND_CONDITIONS_MESSAGE_PART_1',
              )}{' '}
              <span
                onClick={() =>
                  window.open('https://procerty.com/taisykles', '_blank')
                }
              >
                {translate(
                  intl,
                  'REGISTRATION_FORM.TERMS_AND_CONDITIONS_MESSAGE_PART_2',
                )}
              </span>
            </p>
            <Button
              isLoadingButton
              onClick={onSubmit}
              buttonVariant="contained"
              color="primary"
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {translate(intl, 'REGISTRATION_FORM.SUBMIT')}
            </Button>
          </>
        )}
      </Form>
      <SuccessModal
        isOpen={isSuccessRegistrationModalOpen}
        onClose={() => setSuccessRegistrationModalOpen(false)}
        title={translate(intl, 'REGISTRATION.SUCCESS_REGISTRATION_MODAL_TITLE')}
        description={translate(
          intl,
          'REGISTRATION.SUCCESS_REGISTRATION_MODAL_DESCRIPTION',
        )}
        buttonText={translate(
          intl,
          'REGISTRATION.SUCCESS_REGISTRATION_MODAL_BUTTON',
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.registrationError,
  isLoading: state.auth.registrationLoading,
  registrationSuccess: state.auth.registrationSuccess,
  selectedLocale: state.auth.selectedLocale,
  validatedUser: state.auth.validatedUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onRegister: (inputs: RegistrationRequest) =>
    dispatch(authService.register(inputs)),
  onResetRegistration: () => dispatch(resetRegistration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
