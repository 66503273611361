import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'cropperjs/dist/cropper.css';
import countries from 'i18n-iso-countries';

if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://3d1b3006202c42d29db2efa1774493e2@o1101437.ingest.sentry.io/6134108',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: true,
    tracesSampleRate: 1.0,
  });
}

countries.registerLocale(require('i18n-iso-countries/langs/lt.json'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <DndProvider backend={HTML5Backend}>
    <App />
  </DndProvider>,
);

reportWebVitals();
