import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export const setSelectedRoleStart = () => ({
  type: actionTypes.SET_SELECTED_ROLE_START,
});

export const setSelectedRoleSuccess = () => ({
  type: actionTypes.SET_SELECTED_ROLE_SUCCESS,
});

export const setSelectedRoleFail = (setSelecterRoleError: HttpError) => ({
  type: actionTypes.SET_SELECTED_ROLE_FAIL,
  setSelecterRoleError,
});

export const sendPhoneVerificationCodeStart = () => ({
  type: actionTypes.SEND_PHONE_VERIFICATION_CODE_START,
});

export const sendPhoneVerificationCodeSuccess = () => ({
  type: actionTypes.SEND_PHONE_VERIFICATION_CODE_SUCCESS,
});

export const sendPhoneVerificationCodeFail = (
  sendPhoneVerificationError: HttpError,
) => ({
  type: actionTypes.SEND_PHONE_VERIFICATION_CODE_FAIL,
  sendPhoneVerificationError,
});

export const confirmPhoneNumberStart = () => ({
  type: actionTypes.CONFIRM_PHONE_NUMBER_START,
});

export const confirmPhoneNumberSuccess = () => ({
  type: actionTypes.CONFIRM_PHONE_NUMBER_SUCCESS,
});

export const confirmPhoneNumberFail = (confirmPhoneNumberError: HttpError) => ({
  type: actionTypes.CONFIRM_PHONE_NUMBER_FAIL,
  confirmPhoneNumberError,
});

export const resetPhoneVerificationStore = () => ({
  type: actionTypes.RESET_PHONE_VERIFICATION_STORE,
});

export const resetProfileStore = () => ({
  type: actionTypes.RESET_PROFILE_STORE,
});
