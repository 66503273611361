import * as actionTypes from './actionTypes';
import { AttributeValueActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { AttributeValue } from '../../domain/AttributeValue';

export type AttributeValueStateType = {
  setAttributeValueLoading: boolean;
  setAttributeValueError: HttpError;
  setAttributeValueSuccess: boolean;
  updatedAttributeValue: AttributeValue | null;
};

export type AttributeValueActionType = AttributeValueStateType & {
  type: AttributeValueActionTypes;
};

export const initialState: AttributeValueStateType = {
  setAttributeValueLoading: false,
  updatedAttributeValue: null,
  setAttributeValueError: null,
  setAttributeValueSuccess: false,
};

const setAttributeValueStart = (
  state: AttributeValueStateType,
): AttributeValueStateType => ({
  ...state,
  setAttributeValueLoading: true,
});

const setAttributeValueSuccess = (
  state: AttributeValueStateType,
  action: AttributeValueActionType,
): AttributeValueStateType => ({
  ...state,
  setAttributeValueLoading: false,
  setAttributeValueError: null,
  setAttributeValueSuccess: true,
  updatedAttributeValue: action.updatedAttributeValue,
});

const setAttributeValueFail = (
  state: AttributeValueStateType,
  action: AttributeValueActionType,
): AttributeValueStateType => ({
  ...state,
  setAttributeValueLoading: false,
  setAttributeValueError: action.setAttributeValueError,
});

const resetStore = () => ({
  ...initialState,
});

const reducer = (state = initialState, action: AttributeValueActionType) => {
  switch (action.type) {
    case actionTypes.SET_ATTRIBUTE_VALUE_START:
      return setAttributeValueStart(state);
    case actionTypes.SET_ATTRIBUTE_VALUE_SUCCESS:
      return setAttributeValueSuccess(state, action);
    case actionTypes.SET_ATTRIBUTE_VALUE_FAIL:
      return setAttributeValueFail(state, action);
    case actionTypes.RESET_ATTRIBUTE_VALUE_STORE:
      return resetStore();
    default:
      return state;
  }
};

export default reducer;
