import React, { useEffect } from 'react';
import styles from './PlansSection.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import PlanCard from './PlanCard/PlanCard';

import cx from 'classnames';
import { connect } from 'react-redux';
import * as creditPackageService from '../../../../store/creditPackage/service';
import { StoreState } from '@react-pdf-viewer/core';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CreditPackage } from '../../../../domain/CreditPackage';
import Loader from '../../../../common/Loader/Loader';
import { getIcon } from '../../../../utility/creditPackageHelper/creditPackageHelper';

type Props = {
  setSelectedPlan: (plan: CreditPackage) => void;
  sectionClass?: string;
  cardsListClass?: string;
  isTitleHidden?: boolean;
  publicCreditPackages: CreditPackage[] | null;
  onPublicCreditPackagesFetch: () => void;
};

const PlansSection = ({
  setSelectedPlan,
  sectionClass,
  cardsListClass,
  isTitleHidden,
  publicCreditPackages,
  onPublicCreditPackagesFetch,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    onPublicCreditPackagesFetch();
  }, []);

  if (!publicCreditPackages) {
    return <Loader isLoading height="300" />;
  }

  return (
    <section className={cx(styles.sectionContainer, sectionClass)}>
      {!isTitleHidden && (
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: translate(intl, 'PLANS_SECTION.TITLE'),
          }}
        />
      )}
      <div className={cx(styles.cards, cardsListClass)}>
        {publicCreditPackages.map((creditPackage, index) => (
          <PlanCard
            icon={getIcon(creditPackage.subscriptionType)}
            name={creditPackage.name}
            key={`plan-creditPackage-${index}`}
            description={creditPackage.description}
            price={creditPackage.price}
            isTheMostPopular={creditPackage?.isPopular}
            onPurchaseButtonClick={() => setSelectedPlan(creditPackage)}
            credits={creditPackage.credits}
            additionalCredits={creditPackage.additionalCredits}
          />
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = (state: StoreState) => ({
  publicCreditPackages: state.creditPackage.publicCreditPackages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onPublicCreditPackagesFetch: () =>
    dispatch(creditPackageService.publicCreditPackagesFetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansSection);
