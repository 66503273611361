export const FETCH_LANGUAGE_START = 'FETCH_LANGUAGE_START';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const FETCH_LANGUAGE_FAIL = 'FETCH_LANGUAGE_FAIL';

export const FETCH_LANGUAGES_START = 'FETCH_LANGUAGES_START';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAIL = 'FETCH_LANGUAGES_FAIL';

export const CHANGE_LANGUAGE_STATUS_START = 'CHANGE_LANGUAGE_STATUS_START';
export const CHANGE_LANGUAGE_STATUS_SUCCESS = 'CHANGE_LANGUAGE_STATUS_SUCCESS';
export const CHANGE_LANGUAGE_STATUS_FAIL = 'CHANGE_LANGUAGE_STATUS_FAIL';

export const FETCH_LANGUAGES_LIST_START = 'FETCH_LANGUAGES_LIST_START';
export const FETCH_LANGUAGES_LIST_SUCCESS = 'FETCH_LANGUAGES_LIST_SUCCESS';
export const FETCH_LANGUAGES_LIST_FAIL = 'FETCH_LANGUAGES_LIST_FAIL';

export type LanguageActionTypes =
  | typeof FETCH_LANGUAGE_START
  | typeof FETCH_LANGUAGE_SUCCESS
  | typeof FETCH_LANGUAGE_FAIL
  | typeof FETCH_LANGUAGES_START
  | typeof FETCH_LANGUAGES_SUCCESS
  | typeof FETCH_LANGUAGES_FAIL
  | typeof CHANGE_LANGUAGE_STATUS_START
  | typeof CHANGE_LANGUAGE_STATUS_SUCCESS
  | typeof CHANGE_LANGUAGE_STATUS_FAIL
  | typeof FETCH_LANGUAGES_LIST_START
  | typeof FETCH_LANGUAGES_LIST_SUCCESS
  | typeof FETCH_LANGUAGES_LIST_FAIL;
