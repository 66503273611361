import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { OverviewInfo } from '../../domain/OverviewInfo';

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (usersList: ListResults<User>) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  usersList,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const fetchAllUsersStart = () => ({
  type: actionTypes.FETCH_ALL_USERS_START,
});

export const fetchAllUsersSuccess = (allUsersList: ListResults<User>) => ({
  type: actionTypes.FETCH_ALL_USERS_SUCCESS,
  allUsersList,
});

export const fetchAllUsersFail = (allUsersError: HttpError) => ({
  type: actionTypes.FETCH_ALL_USERS_FAIL,
  allUsersError,
});

export const fetchAdminsListStart = () => ({
  type: actionTypes.FETCH_ADMINS_LIST_START,
});

export const fetchAdminsListSuccess = (adminsList: ListResults<User>) => ({
  type: actionTypes.FETCH_ADMINS_LIST_SUCCESS,
  adminsList,
});

export const fetchAdminsListFail = (adminsListError: HttpError) => ({
  type: actionTypes.FETCH_ADMINS_LIST_FAIL,
  adminsListError,
});

export const fetchUserWithCertificateImagesStart = () => ({
  type: actionTypes.FETCH_USER_WITH_CERTIFICATE_IMAGES_START,
});

export const fetchUserWithCertificateImagesSuccess = (
  userWithCertificateImages: User,
) => ({
  type: actionTypes.FETCH_USER_WITH_CERTIFICATE_IMAGES_SUCCESS,
  userWithCertificateImages,
});

export const fetchUserWithCertificateImagesFail = (
  userWithCertificateImagesError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_WITH_CERTIFICATE_IMAGES_FAIL,
  userWithCertificateImagesError,
});

export const createAdminStart = () => ({
  type: actionTypes.CREATE_ADMIN_START,
});

export const createAdminSuccess = (createdAdmin: User) => ({
  type: actionTypes.CREATE_ADMIN_SUCCESS,
  createdAdmin,
});

export const createAdminFail = (adminCreateError: HttpError) => ({
  type: actionTypes.CREATE_ADMIN_FAIL,
  adminCreateError,
});

export const uploadUserCertificateImageStart = () => ({
  type: actionTypes.UPLOAD_USER_CERTIFICATE_IMAGE_START,
});

export const uploadUserCertificateImageSuccess = (
  uploadedUserCertificateImage: User,
) => ({
  type: actionTypes.UPLOAD_USER_CERTIFICATE_IMAGE_SUCCESS,
  uploadedUserCertificateImage,
});

export const uploadUserCertificateImageFail = (
  userCreateCertificateImageError: HttpError,
) => ({
  type: actionTypes.UPLOAD_USER_CERTIFICATE_IMAGE_FAIL,
  userCreateCertificateImageError,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const updateAdminStart = () => ({
  type: actionTypes.UPDATE_ADMIN_START,
});

export const updateAdminSuccess = () => ({
  type: actionTypes.UPDATE_ADMIN_SUCCESS,
});

export const updateAdminFail = (adminUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_ADMIN_FAIL,
  adminUpdateError,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (userError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  userError,
});

export const fetchCurrentUserStart = (refreshCurrentUser?: boolean) => ({
  type: actionTypes.FETCH_ME_START,
  refreshCurrentUser,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const updateUserInfoStart = () => ({
  type: actionTypes.UPDATE_USER_INFO_START,
});

export const updateUserInfoSuccess = () => ({
  type: actionTypes.UPDATE_USER_INFO_SUCCESS,
});

export const updateUserInfoFail = (userUpdateInfoError: HttpError) => ({
  type: actionTypes.UPDATE_USER_INFO_FAIL,
  userUpdateInfoError,
});

export const updateCurrentOrganiserInfoStart = () => ({
  type: actionTypes.UPDATE_CURRENT_ORGANISER_INFO_START,
});

export const updateCurrentOrganiserInfoSuccess = () => ({
  type: actionTypes.UPDATE_CURRENT_ORGANISER_INFO_SUCCESS,
});

export const updateCurrentOrganiserInfoFail = (
  currentOrganiserUpdateInfoError: HttpError,
) => ({
  type: actionTypes.UPDATE_CURRENT_ORGANISER_INFO_FAIL,
  currentOrganiserUpdateInfoError,
});

export const updateOrganiserInfoStart = () => ({
  type: actionTypes.UPDATE_ORGANISER_INFO_START,
});

export const updateOrganiserInfoSuccess = () => ({
  type: actionTypes.UPDATE_ORGANISER_INFO_SUCCESS,
});

export const updateOrganiserInfoFail = (
  organiserUpdateInfoError: HttpError,
) => ({
  type: actionTypes.UPDATE_ORGANISER_INFO_FAIL,
  organiserUpdateInfoError,
});

export const changeUserPasswordStart = () => ({
  type: actionTypes.CHANGE_USER_PASSWORD_START,
});

export const changeUserPasswordSuccess = () => ({
  type: actionTypes.CHANGE_USER_PASSWORD_SUCCESS,
});

export const changeUserPasswordFail = (userChangePasswordError: HttpError) => ({
  type: actionTypes.CHANGE_USER_PASSWORD_FAIL,
  userChangePasswordError,
});

export const deleteUserCertificateImageStart = () => ({
  type: actionTypes.DELETE_USER_CERTIFICATE_IMAGE_START,
});

export const deleteUserCertificateImageSuccess = () => ({
  type: actionTypes.DELETE_USER_CERTIFICATE_IMAGE_SUCCESS,
});

export const deleteUserCertificateImageFail = (
  userCertificateImageDeleteError: HttpError,
) => ({
  type: actionTypes.DELETE_USER_CERTIFICATE_IMAGE_FAIL,
  userCertificateImageDeleteError,
});

export const uploadUserBackgroundImageStart = () => ({
  type: actionTypes.UPLOAD_BACKGROUND_IMAGE_START,
});

export const uploadUserBackgroundImagesSuccess = (
  uploadedUserBackgroundImage: User,
) => ({
  type: actionTypes.UPLOAD_BACKGROUND_IMAGE_SUCCESS,
  uploadedUserBackgroundImage,
});

export const uploadUserBackgroundImageFail = (
  backgroundImageUploadError: HttpError,
) => ({
  type: actionTypes.UPLOAD_BACKGROUND_IMAGE_FAIL,
  backgroundImageUploadError,
});

export const deleteUserBackgroundImageStart = () => ({
  type: actionTypes.DELETE_BACKGROUND_IMAGE_START,
});

export const deleteUserBackgroundImageSuccess = () => ({
  type: actionTypes.DELETE_BACKGROUND_IMAGE_SUCCESS,
});

export const deleteUserBackgroundImageFail = (
  deleteUserCertificateImageError: HttpError,
) => ({
  type: actionTypes.DELETE_BACKGROUND_IMAGE_FAIL,
  deleteUserCertificateImageError,
});

export const resetUploadedUserBackgroundImageStore = () => ({
  type: actionTypes.RESET_USER_UPLOADED_BACKGROUND_IMAGE,
});

export const fetchUserWithBackgroundImagesStart = () => ({
  type: actionTypes.FETCH_USER_WITH_BACKGROUND_IMAGES_START,
});

export const fetchUserWithBackgroundImagesSuccess = (
  userWithBackgroundImages: User,
) => ({
  type: actionTypes.FETCH_USER_WITH_BACKGROUND_IMAGES_SUCCESS,
  userWithBackgroundImages,
});

export const fetchUserWithBackgroundImagesFail = (
  userWithBackgroundImagesError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_WITH_BACKGROUND_IMAGES_FAIL,
  userWithBackgroundImagesError,
});

export const fetchOverviewInfoStart = () => ({
  type: actionTypes.FETCH_OVERVIEW_INFO_START,
});

export const fetchOverviewInfoSuccess = (overviewInfo: OverviewInfo) => ({
  type: actionTypes.FETCH_OVERVIEW_INFO_SUCCESS,
  overviewInfo,
});

export const fetchOverviewInfoFail = (overviewInfoError: HttpError) => ({
  type: actionTypes.FETCH_OVERVIEW_INFO_FAIL,
  overviewInfoError,
});

export const connectUserStart = () => ({
  type: actionTypes.CONNECT_USER_START,
});

export const connectUserSuccess = () => ({
  type: actionTypes.CONNECT_USER_SUCCESS,
});

export const connectUserFail = (connectUserError: string) => ({
  type: actionTypes.CONNECT_USER_FAIL,
  connectUserError,
});

export const confirmConnectUserStart = () => ({
  type: actionTypes.CONFIRM_CONNECT_USER_START,
});

export const confirmConnectUserSuccess = () => ({
  type: actionTypes.CONFIRM_CONNECT_USER_SUCCESS,
});

export const confirmConnectUserFail = () => ({
  type: actionTypes.CONFIRM_CONNECT_USER_FAIL,
});

export const fetchConnectedUsersStart = () => ({
  type: actionTypes.FETCH_CONNECTED_USERS_START,
});

export const fetchConnectedUsersSuccess = (connectedUsers: User) => ({
  type: actionTypes.FETCH_CONNECTED_USERS_SUCCESS,
  connectedUsers,
});

export const fetchConnectedUsersFail = (connectedUsersError: HttpError) => ({
  type: actionTypes.FETCH_CONNECTED_USERS_FAIL,
  connectedUsersError,
});

export const deleteConnectedUserStart = () => ({
  type: actionTypes.DELETE_CONNECTED_USER_START,
});

export const deleteConnectedUserSuccess = () => ({
  type: actionTypes.DELETE_CONNECTED_USER_SUCCESS,
});

export const deleteConnectedUserFail = (connectedUserDeleteError: string) => ({
  type: actionTypes.DELETE_CONNECTED_USER_FAIL,
  connectedUserDeleteError,
});

export const resendConnectedUserInvitationStart = () => ({
  type: actionTypes.RESEND_CONNECTED_USER_INVITATION_START,
});

export const resendConnectedUserInvitationSuccess = () => ({
  type: actionTypes.RESEND_CONNECTED_USER_INVITATION_SUCCESS,
});

export const resendConnectedUserInvitationFail = (
  resendConnectedUserInvitationError: string,
) => ({
  type: actionTypes.RESEND_CONNECTED_USER_INVITATION_FAIL,
  resendConnectedUserInvitationError,
});

export const addOrganiserRoleStart = () => ({
  type: actionTypes.ADD_ORGANISER_ROLE_START,
});

export const addOrganiserRoleSuccess = () => ({
  type: actionTypes.ADD_ORGANISER_ROLE_SUCCESS,
});

export const addOrganiserRoleFail = (addOrganiserRoleError: HttpError) => ({
  type: actionTypes.ADD_ORGANISER_ROLE_FAIL,
  addOrganiserRoleError,
});

export const resetChangeUserPasswordStore = () => ({
  type: actionTypes.RESET_CHANGE_USER_PASSWORD_STORE,
});

export const resetUserInfoStore = () => ({
  type: actionTypes.RESET_USER_INFO_STORE,
});

export const resetCurrentOrganiserInfoStore = () => ({
  type: actionTypes.RESET_CURRENT_ORGANISER_INFO_STORE,
});

export const resetOrganiserInfoStore = () => ({
  type: actionTypes.RESET_ORGANISER_INFO_STORE,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});

export const resetCreatedUserStore = () => ({
  type: actionTypes.RESET_CREATED_USER,
});

export const resetUploadedUserCertificateImageStore = () => ({
  type: actionTypes.RESET_USER_UPLOADED_CERTIFICATE_IMAGE,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});
