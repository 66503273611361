import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { OverviewInfo } from '../../domain/OverviewInfo';

export type UserStateType = {
  user: User | null;
  userLoading: boolean;
  userError: HttpError;
  usersList: ListResults<User> | null;
  usersLoading: boolean;
  usersError: HttpError;
  adminCreateLoading: boolean;
  adminCreateError: HttpError;
  adminCreateSuccess: boolean;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  adminUpdateLoading: boolean;
  adminUpdateError: HttpError;
  adminUpdateSuccess: boolean;
  userDeleteLoading: boolean;
  userDeleteError: HttpError;
  userDeleteSuccess: boolean;
  usersListUpdateNeeded: boolean;
  currentUser: User | null;
  currentUserLoading: boolean;
  allUsersError: HttpError;
  allUsersList: ListResults<User> | null;
  allUsersLoading: boolean;
  userUpdateInfoLoading: boolean;
  userUpdateInfoError: HttpError;
  userUpdateInfoSuccess: boolean;
  currentOrganiserUpdateInfoLoading: boolean;
  currentOrganiserUpdateInfoError: HttpError;
  currentOrganiserUpdateInfoSuccess: boolean;
  organiserUpdateInfoLoading: boolean;
  organiserUpdateInfoError: HttpError;
  organiserUpdateInfoSuccess: boolean;
  userChangePasswordLoading: boolean;
  userChangePasswordError: HttpError;
  userChangePasswordSuccess: boolean;
  createdAdmin: User | null;
  userUploadCertificateImageLoading: boolean;
  userUploadCertificateImageError: HttpError;
  userUploadCertificateImageSuccess: boolean;
  uploadedUserCertificateImage: User | null;
  userWithCertificateImages: User | null;
  userWithCertificateImagesError: HttpError;
  userWithCertificateImagesLoading: boolean;
  userWithCertificateImagesSuccess: boolean;
  userWithCertificateImagesUpdateNeeded: boolean;
  userDeleteCertificateImageLoading: boolean;
  userDeleteCertificateImageError: HttpError;
  userDeleteCertificateImageSuccess: boolean;
  userUploadBackgroundImageLoading: boolean;
  userUploadBackgroundImageSuccess: boolean;
  userUploadBackgroundImageError: HttpError;
  uploadedUserBackgroundImage: User | null;
  userWithBackgroundImages: User | null;
  userWithBackgroundImagesError: HttpError;
  userWithBackgroundImagesLoading: boolean;
  userWithBackgroundImagesSuccess: boolean;
  userWithBackgroundImagesUpdateNeeded: boolean;
  adminsList: ListResults<User> | null;
  adminsListLoading: boolean;
  adminsListError: HttpError;
  adminsListUpdateNeeded: boolean;
  deleteUserCertificateBackgroundLoading: boolean;
  deleteUserCertificateBackgroundError: HttpError;
  deleteUserCertificateBackgroundSuccess: boolean;
  overviewInfo: OverviewInfo | null;
  overviewInfoError: HttpError;
  overviewInfoLoading: boolean;
  overviewInfoSuccess: boolean;
  connectUserLoading: boolean;
  connectUserError: string | null;
  connectUserSuccess: boolean;
  connectedUsers: User[];
  connectedUsersLoading: boolean;
  connectedUsersError: HttpError;
  connectedUserDeleteLoading: boolean;
  connectedUserDeleteError: HttpError;
  connectedUserDeleteSuccess: boolean;
  confirmConnectUserLoading: boolean;
  confirmConnectUserSuccess: boolean;
  resendConnectedUserInvitationLoading: boolean;
  resendConnectedUserInvitationSuccess: boolean;
  resendConnectedUserInvitationError: HttpError;
  addOrganiserRoleLoading: boolean;
  addOrganiserRoleSuccess: boolean;
  addOrganiserRoleError: HttpError;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
  refreshCurrentUser: boolean;
};

export const initialState: UserStateType = {
  user: null,
  userLoading: false,
  userError: null,
  adminCreateError: null,
  adminCreateLoading: false,
  adminCreateSuccess: false,
  userDeleteError: null,
  userDeleteLoading: false,
  userDeleteSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  adminUpdateError: null,
  adminUpdateLoading: false,
  adminUpdateSuccess: false,
  usersError: null,
  usersList: null,
  usersLoading: true,
  usersListUpdateNeeded: false,
  currentUser: null,
  currentUserLoading: false,
  allUsersError: null,
  allUsersList: null,
  allUsersLoading: true,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
  currentOrganiserUpdateInfoError: null,
  currentOrganiserUpdateInfoLoading: false,
  currentOrganiserUpdateInfoSuccess: false,
  organiserUpdateInfoError: null,
  organiserUpdateInfoLoading: false,
  organiserUpdateInfoSuccess: false,
  userChangePasswordError: null,
  userChangePasswordLoading: false,
  userChangePasswordSuccess: false,
  createdAdmin: null,
  userUploadCertificateImageError: null,
  userUploadCertificateImageLoading: false,
  userUploadCertificateImageSuccess: false,
  uploadedUserCertificateImage: null,
  userWithCertificateImages: null,
  userWithCertificateImagesError: null,
  userWithCertificateImagesLoading: false,
  userWithCertificateImagesSuccess: false,
  userWithCertificateImagesUpdateNeeded: false,
  userDeleteCertificateImageError: null,
  userDeleteCertificateImageLoading: false,
  userDeleteCertificateImageSuccess: false,
  userUploadBackgroundImageLoading: false,
  userUploadBackgroundImageSuccess: false,
  userUploadBackgroundImageError: null,
  uploadedUserBackgroundImage: null,
  userWithBackgroundImages: null,
  userWithBackgroundImagesError: null,
  userWithBackgroundImagesLoading: false,
  userWithBackgroundImagesSuccess: false,
  userWithBackgroundImagesUpdateNeeded: false,
  adminsList: null,
  adminsListLoading: true,
  adminsListError: null,
  adminsListUpdateNeeded: false,
  deleteUserCertificateBackgroundLoading: false,
  deleteUserCertificateBackgroundError: null,
  deleteUserCertificateBackgroundSuccess: false,
  overviewInfo: null,
  overviewInfoError: null,
  overviewInfoLoading: false,
  overviewInfoSuccess: false,
  connectUserLoading: false,
  connectUserError: null,
  connectUserSuccess: false,
  connectedUsers: [],
  connectedUsersLoading: false,
  connectedUsersError: null,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: null,
  connectedUserDeleteSuccess: false,
  confirmConnectUserLoading: false,
  confirmConnectUserSuccess: false,
  resendConnectedUserInvitationLoading: false,
  resendConnectedUserInvitationSuccess: false,
  resendConnectedUserInvitationError: null,
  addOrganiserRoleLoading: false,
  addOrganiserRoleSuccess: false,
  addOrganiserRoleError: null,
};

const fetchCurrentUserStart = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUserLoading: action.refreshCurrentUser ? false : true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
  currentOrganiserUpdateInfoSuccess: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoSuccess: false,
  userLoading: true,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
});

const fetchUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersList: action.usersList,
  usersLoading: false,
  usersError: null,
  usersListUpdateNeeded: false,
});

const fetchUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const fetchAllUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  allUsersLoading: true,
});

const fetchAllUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersList: action.allUsersList,
  allUsersLoading: false,
  allUsersError: null,
});

const fetchAllUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersError: action.allUsersError,
  allUsersLoading: false,
});

const fetchAdminsListStart = (state: UserStateType): UserStateType => ({
  ...state,
  adminsListLoading: true,
});

const fetchAdminsListSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  adminsListLoading: false,
  adminsList: action.adminsList,
  adminsListUpdateNeeded: false,
});

const fetchAdminsListFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  adminsListLoading: false,
  adminsListError: action.adminsListError,
});

const fetchUserWithCertificateImagesStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userWithCertificateImagesLoading: true,
});

const fetchUserWithCertificateImagesSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userWithCertificateImages: action.userWithCertificateImages,
  userWithCertificateImagesLoading: false,
  userWithCertificateImagesError: null,
  userWithCertificateImagesUpdateNeeded: false,
});

const fetchUserWithCertificateImagesFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userWithCertificateImagesError: action.userWithCertificateImagesError,
  userWithCertificateImagesLoading: false,
});

const createAdminStart = (state: UserStateType): UserStateType => ({
  ...state,
  adminCreateLoading: true,
});

const createAdminSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  adminCreateLoading: false,
  adminCreateError: null,
  adminCreateSuccess: true,
  usersListUpdateNeeded: true,
  adminsListUpdateNeeded: true,
  createdAdmin: action.createdAdmin,
});

const createAdminFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  adminCreateLoading: false,
  adminCreateError: action.adminCreateError,
});

const uploadUserCertificateImageStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userUploadCertificateImageLoading: true,
});

const uploadUserCertificateImageSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUploadCertificateImageLoading: false,
  userUploadCertificateImageError: null,
  userUploadCertificateImageSuccess: true,
  userWithCertificateImagesUpdateNeeded: true,
  uploadedUserCertificateImage: action.uploadedUserCertificateImage,
});

const uploadUserCertificateImageFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUploadCertificateImageLoading: false,
  userUploadCertificateImageError: action.userUploadCertificateImageError,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
  usersListUpdateNeeded: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const updateAdminStart = (state: UserStateType): UserStateType => ({
  ...state,
  adminUpdateLoading: true,
});

const updateAdminSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  adminUpdateLoading: false,
  adminUpdateError: null,
  adminUpdateSuccess: true,
  adminsListUpdateNeeded: true,
});

const updateAdminFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  adminUpdateLoading: false,
  adminUpdateError: action.adminUpdateError,
});

const deleteUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: true,
});

const deleteUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: null,
  userDeleteSuccess: true,
  usersListUpdateNeeded: true,
  adminsListUpdateNeeded: true,
});

const deleteUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: action.userDeleteError,
});

const deleteUserCertificateImageStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userDeleteCertificateImageLoading: true,
});

const deleteUserCertificateImageSuccess = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userDeleteCertificateImageLoading: false,
  userDeleteCertificateImageError: null,
  userDeleteCertificateImageSuccess: true,
  userWithCertificateImagesUpdateNeeded: true,
});

const deleteUserCertificateImageFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteCertificateImageLoading: false,
  userDeleteCertificateImageError: action.userDeleteCertificateImageError,
});

const updateUserInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: true,
});

const updateUserInfoSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: null,
  userUpdateInfoSuccess: true,
});

const updateUserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: action.userUpdateInfoError,
});

const updateCurrentOrganiserInfoStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  currentOrganiserUpdateInfoLoading: true,
});

const updateCurrentOrganiserInfoSuccess = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  currentOrganiserUpdateInfoLoading: false,
  currentOrganiserUpdateInfoError: null,
  currentOrganiserUpdateInfoSuccess: true,
});

const updateCurrentOrganiserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentOrganiserUpdateInfoLoading: false,
  currentOrganiserUpdateInfoError: action.currentOrganiserUpdateInfoError,
});

const updateOrganiserInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  organiserUpdateInfoLoading: true,
});

const updateOrganiserInfoSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  organiserUpdateInfoLoading: false,
  organiserUpdateInfoError: null,
  organiserUpdateInfoSuccess: true,
});

const updateOrganiserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  organiserUpdateInfoLoading: false,
  organiserUpdateInfoError: action.organiserUpdateInfoError,
});

const changeUserPasswordStart = (state: UserStateType): UserStateType => ({
  ...state,
  userChangePasswordLoading: true,
});

const changeUserPasswordSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userChangePasswordLoading: false,
  userChangePasswordError: null,
  userChangePasswordSuccess: true,
});

const changeUserPasswordFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userChangePasswordLoading: false,
  userChangePasswordError: action.userChangePasswordError,
});

const uploadBackgroundImagesStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUploadBackgroundImageLoading: true,
});

const uploadBackgroundImagesSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUploadBackgroundImageLoading: false,
  userUploadBackgroundImageError: null,
  userUploadBackgroundImageSuccess: true,
  userWithBackgroundImagesUpdateNeeded: true,
  uploadedUserBackgroundImage: action.uploadedUserBackgroundImage,
});

const uploadBackgroundImagesFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUploadBackgroundImageLoading: false,
  userUploadBackgroundImageError: action.userUploadBackgroundImageError,
});

const deleteBackgroundImageStart = (state: UserStateType): UserStateType => ({
  ...state,
  deleteUserCertificateBackgroundLoading: true,
});

const deleteBackgroundImageSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  deleteUserCertificateBackgroundLoading: false,
  deleteUserCertificateBackgroundError: null,
  deleteUserCertificateBackgroundSuccess: true,
  userWithBackgroundImagesUpdateNeeded: true,
});

const deleteBackgroundImageFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  deleteUserCertificateBackgroundLoading: false,
  deleteUserCertificateBackgroundError:
    action.deleteUserCertificateBackgroundError,
});

const fetchUserWithBackgroundImagesStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userWithBackgroundImagesLoading: true,
});

const fetchUserWithBackgroundImagesSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userWithBackgroundImages: action.userWithBackgroundImages,
  userWithBackgroundImagesLoading: false,
  userWithBackgroundImagesError: null,
  userWithBackgroundImagesUpdateNeeded: false,
});

const fetchUserWithBackgroundImagesFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userWithBackgroundImagesError: action.userWithBackgroundImagesError,
  userWithBackgroundImagesLoading: false,
});

const fetchOverviewInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  overviewInfoLoading: true,
});

const fetchOverviewInfoSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  overviewInfo: action.overviewInfo,
  overviewInfoLoading: false,
  overviewInfoError: null,
  overviewInfoSuccess: true,
});

const fetchOverviewInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  overviewInfoError: action.overviewInfoError,
  overviewInfoLoading: false,
});

const connectUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  connectUserLoading: true,
  connectUserSuccess: false,
});

const connectUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  connectUserError: null,
  connectUserLoading: false,
  connectUserSuccess: true,
});

const connectUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  connectUserError: action.connectUserError,
  connectUserLoading: false,
});

const fetchConnectedUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  connectedUsersLoading: true,
  connectedUserDeleteSuccess: false,
});

const fetchConnectedUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  connectedUsers: action.connectedUsers,
  connectedUsersLoading: false,
  connectedUsersError: null,
  connectedUserDeleteSuccess: false,
});

const fetchConnectedUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  connectedUsersError: action.connectedUsersError,
  connectedUsersLoading: false,
});

const deleteConnectedUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  connectedUserDeleteLoading: true,
  connectUserSuccess: false,
});

const deleteConnectedUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: null,
  connectedUserDeleteSuccess: true,
});

const deleteConnectedUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: action.connectedUserDeleteError,
});

const resendConnectedUserInvitationStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  resendConnectedUserInvitationLoading: true,
});

const resendConnectedUserInvitationSuccess = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  resendConnectedUserInvitationLoading: false,
  resendConnectedUserInvitationError: null,
  resendConnectedUserInvitationSuccess: true,
});

const resendConnectedUserInvitationFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  resendConnectedUserInvitationLoading: false,
  resendConnectedUserInvitationError: action.resendConnectedUserInvitationError,
});

const confirmConnectUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  confirmConnectUserLoading: true,
  confirmConnectUserSuccess: false,
});

const confirmConnectUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  confirmConnectUserLoading: false,
  confirmConnectUserSuccess: true,
});

const confirmConnectUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  confirmConnectUserLoading: false,
});

const addOrganiserRoleStart = (state: UserStateType): UserStateType => ({
  ...state,
  addOrganiserRoleLoading: true,
  addOrganiserRoleSuccess: false,
});

const addOrganiserRoleSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  addOrganiserRoleLoading: false,
  addOrganiserRoleSuccess: true,
});

const addOrganiserRoleFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  addOrganiserRoleError: action.addOrganiserRoleError,
});

const resetUserInfoStore = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
});

const resetCurrentOrganiserInfoStore = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  currentOrganiserUpdateInfoError: null,
  currentOrganiserUpdateInfoLoading: false,
  currentOrganiserUpdateInfoSuccess: false,
});

const resetOrganiserInfoStore = (state: UserStateType): UserStateType => ({
  ...state,
  organiserUpdateInfoError: null,
  organiserUpdateInfoLoading: false,
  organiserUpdateInfoSuccess: false,
});

const resetUserStore = (state: UserStateType): UserStateType => ({
  ...initialState,
  currentUser: state.currentUser,
});

const completelyResetUserStore = (): UserStateType => ({
  ...initialState,
});

const resetUploadedUserCertificateImageStore = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userUploadCertificateImageError: null,
  userUploadCertificateImageLoading: false,
  userUploadCertificateImageSuccess: false,
  uploadedUserCertificateImage: null,
});

const resetUploadedUserBackgroundImageStore = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userUploadBackgroundImageError: null,
  userUploadBackgroundImageLoading: false,
  userUploadBackgroundImageSuccess: false,
  uploadedUserBackgroundImage: null,
});

const resetCreatedAdminStore = (state: UserStateType): UserStateType => ({
  ...state,
  createdAdmin: null,
  adminCreateLoading: false,
  adminCreateError: null,
  adminCreateSuccess: false,
});

const resetChangeUserPasswordStore = (state: UserStateType): UserStateType => ({
  ...state,
  userChangePasswordError: null,
  userChangePasswordLoading: false,
  userChangePasswordSuccess: false,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.FETCH_ALL_USERS_START:
      return fetchAllUsersStart(state);
    case actionTypes.FETCH_ALL_USERS_SUCCESS:
      return fetchAllUsersSuccess(state, action);
    case actionTypes.FETCH_ALL_USERS_FAIL:
      return fetchAllUsersFail(state, action);
    case actionTypes.FETCH_ADMINS_LIST_START:
      return fetchAdminsListStart(state);
    case actionTypes.FETCH_ADMINS_LIST_SUCCESS:
      return fetchAdminsListSuccess(state, action);
    case actionTypes.FETCH_ADMINS_LIST_FAIL:
      return fetchAdminsListFail(state, action);
    case actionTypes.FETCH_USER_WITH_CERTIFICATE_IMAGES_START:
      return fetchUserWithCertificateImagesStart(state);
    case actionTypes.FETCH_USER_WITH_CERTIFICATE_IMAGES_SUCCESS:
      return fetchUserWithCertificateImagesSuccess(state, action);
    case actionTypes.FETCH_USER_WITH_CERTIFICATE_IMAGES_FAIL:
      return fetchUserWithCertificateImagesFail(state, action);
    case actionTypes.CREATE_ADMIN_START:
      return createAdminStart(state);
    case actionTypes.CREATE_ADMIN_SUCCESS:
      return createAdminSuccess(state, action);
    case actionTypes.CREATE_ADMIN_FAIL:
      return createAdminFail(state, action);
    case actionTypes.UPLOAD_USER_CERTIFICATE_IMAGE_START:
      return uploadUserCertificateImageStart(state);
    case actionTypes.UPLOAD_USER_CERTIFICATE_IMAGE_SUCCESS:
      return uploadUserCertificateImageSuccess(state, action);
    case actionTypes.UPLOAD_USER_CERTIFICATE_IMAGE_FAIL:
      return uploadUserCertificateImageFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.UPDATE_ADMIN_START:
      return updateAdminStart(state);
    case actionTypes.UPDATE_ADMIN_SUCCESS:
      return updateAdminSuccess(state);
    case actionTypes.UPDATE_ADMIN_FAIL:
      return updateAdminFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.DELETE_USER_CERTIFICATE_IMAGE_START:
      return deleteUserCertificateImageStart(state);
    case actionTypes.DELETE_USER_CERTIFICATE_IMAGE_SUCCESS:
      return deleteUserCertificateImageSuccess(state);
    case actionTypes.DELETE_USER_CERTIFICATE_IMAGE_FAIL:
      return deleteUserCertificateImageFail(state, action);
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state, action);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.UPDATE_USER_INFO_START:
      return updateUserInfoStart(state);
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return updateUserInfoSuccess(state);
    case actionTypes.UPDATE_USER_INFO_FAIL:
      return updateUserInfoFail(state, action);
    case actionTypes.UPDATE_CURRENT_ORGANISER_INFO_START:
      return updateCurrentOrganiserInfoStart(state);
    case actionTypes.UPDATE_CURRENT_ORGANISER_INFO_SUCCESS:
      return updateCurrentOrganiserInfoSuccess(state);
    case actionTypes.UPDATE_CURRENT_ORGANISER_INFO_FAIL:
      return updateCurrentOrganiserInfoFail(state, action);
    case actionTypes.UPDATE_ORGANISER_INFO_START:
      return updateOrganiserInfoStart(state);
    case actionTypes.UPDATE_ORGANISER_INFO_SUCCESS:
      return updateOrganiserInfoSuccess(state);
    case actionTypes.UPDATE_ORGANISER_INFO_FAIL:
      return updateOrganiserInfoFail(state, action);
    case actionTypes.CHANGE_USER_PASSWORD_START:
      return changeUserPasswordStart(state);
    case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return changeUserPasswordSuccess(state);
    case actionTypes.CHANGE_USER_PASSWORD_FAIL:
      return changeUserPasswordFail(state, action);
    case actionTypes.UPLOAD_BACKGROUND_IMAGE_START:
      return uploadBackgroundImagesStart(state);
    case actionTypes.UPLOAD_BACKGROUND_IMAGE_SUCCESS:
      return uploadBackgroundImagesSuccess(state, action);
    case actionTypes.UPLOAD_BACKGROUND_IMAGE_FAIL:
      return uploadBackgroundImagesFail(state, action);
    case actionTypes.DELETE_BACKGROUND_IMAGE_START:
      return deleteBackgroundImageStart(state);
    case actionTypes.DELETE_BACKGROUND_IMAGE_SUCCESS:
      return deleteBackgroundImageSuccess(state);
    case actionTypes.DELETE_BACKGROUND_IMAGE_FAIL:
      return deleteBackgroundImageFail(state, action);
    case actionTypes.FETCH_USER_WITH_BACKGROUND_IMAGES_START:
      return fetchUserWithBackgroundImagesStart(state);
    case actionTypes.FETCH_USER_WITH_BACKGROUND_IMAGES_SUCCESS:
      return fetchUserWithBackgroundImagesSuccess(state, action);
    case actionTypes.FETCH_USER_WITH_BACKGROUND_IMAGES_FAIL:
      return fetchUserWithBackgroundImagesFail(state, action);
    case actionTypes.FETCH_OVERVIEW_INFO_START:
      return fetchOverviewInfoStart(state);
    case actionTypes.FETCH_OVERVIEW_INFO_SUCCESS:
      return fetchOverviewInfoSuccess(state, action);
    case actionTypes.FETCH_OVERVIEW_INFO_FAIL:
      return fetchOverviewInfoFail(state, action);
    case actionTypes.CONNECT_USER_START:
      return connectUserStart(state);
    case actionTypes.CONNECT_USER_SUCCESS:
      return connectUserSuccess(state);
    case actionTypes.CONNECT_USER_FAIL:
      return connectUserFail(state, action);
    case actionTypes.FETCH_CONNECTED_USERS_START:
      return fetchConnectedUsersStart(state);
    case actionTypes.FETCH_CONNECTED_USERS_SUCCESS:
      return fetchConnectedUsersSuccess(state, action);
    case actionTypes.FETCH_CONNECTED_USERS_FAIL:
      return fetchConnectedUsersFail(state, action);
    case actionTypes.DELETE_CONNECTED_USER_START:
      return deleteConnectedUserStart(state);
    case actionTypes.DELETE_CONNECTED_USER_SUCCESS:
      return deleteConnectedUserSuccess(state);
    case actionTypes.DELETE_CONNECTED_USER_FAIL:
      return deleteConnectedUserFail(state, action);
    case actionTypes.RESEND_CONNECTED_USER_INVITATION_START:
      return resendConnectedUserInvitationStart(state);
    case actionTypes.RESEND_CONNECTED_USER_INVITATION_SUCCESS:
      return resendConnectedUserInvitationSuccess(state);
    case actionTypes.RESEND_CONNECTED_USER_INVITATION_FAIL:
      return resendConnectedUserInvitationFail(state, action);
    case actionTypes.ADD_ORGANISER_ROLE_START:
      return addOrganiserRoleStart(state);
    case actionTypes.ADD_ORGANISER_ROLE_SUCCESS:
      return addOrganiserRoleSuccess(state);
    case actionTypes.ADD_ORGANISER_ROLE_FAIL:
      return addOrganiserRoleFail(state, action);
    case actionTypes.CONFIRM_CONNECT_USER_START:
      return confirmConnectUserStart(state);
    case actionTypes.CONFIRM_CONNECT_USER_SUCCESS:
      return confirmConnectUserSuccess(state);
    case actionTypes.CONFIRM_CONNECT_USER_FAIL:
      return confirmConnectUserFail(state);
    case actionTypes.RESET_CHANGE_USER_PASSWORD_STORE:
      return resetChangeUserPasswordStore(state);
    case actionTypes.RESET_USER_INFO_STORE:
      return resetUserInfoStore(state);
    case actionTypes.RESET_CURRENT_ORGANISER_INFO_STORE:
      return resetCurrentOrganiserInfoStore(state);
    case actionTypes.RESET_ORGANISER_INFO_STORE:
      return resetOrganiserInfoStore(state);
    case actionTypes.RESET_USER_STORE:
      return resetUserStore(state);
    case actionTypes.RESET_CREATED_USER:
      return resetCreatedAdminStore(state);
    case actionTypes.RESET_USER_UPLOADED_CERTIFICATE_IMAGE:
      return resetUploadedUserCertificateImageStore(state);
    case actionTypes.RESET_USER_UPLOADED_BACKGROUND_IMAGE:
      return resetUploadedUserBackgroundImageStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserStore();
    default:
      return state;
  }
};

export default reducer;
