import React, { useEffect, useState } from 'react';
import styles from './AuthNavigation.module.scss';
import logo from '../../../../assets/procertyLogo_beta_light.png';
import LogoLink from '../../../../common/PublicNavigation/LogoLink/LogoLink';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import HamburgerButton from '../../../../common/PublicNavigation/HamburgerButton/HamburgerButton';
import { useLocation } from 'react-router-dom';
import Button from '../../../../common/Button/Button';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { routes } from '../../../../config/Router/routes';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { User } from '../../../../domain/User';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../../../store/auth/service';

type Props = {
  currentUser: User | null;
  onLogout: () => void;
};

export const MOBILE_BREAK_POINT = 900;

const AuthNavigation = ({ currentUser, onLogout }: Props) => {
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (width && width > MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  const getMenuButtons = () => {
    if (currentUser) {
      return (
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            color="secondary"
            onClick={onLogout}
          >
            {translate(intl, 'AUTH_NAVIGATION.BUTTON_LOGOUT')}
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          color="secondary"
          onClick={() => navigate(routes.login)}
        >
          {translate(intl, 'AUTH_NAVIGATION.BUTTON_LOGIN')}
        </Button>
      </div>
    );
  };

  return (
    <header className={styles.authNavigationContainer}>
      <div className={styles.innerNavigation}>
        <LogoLink logoSrc={logo} isNotSvg logoClassName={styles.logo} />
        {width && width > MOBILE_BREAK_POINT && getMenuButtons()}
        {width && width < MOBILE_BREAK_POINT && (
          <HamburgerButton
            isMobileMenuOpen={isMobileMenuOpen}
            clickHandler={() => setIsMobileMenuOpen((prevState) => !prevState)}
          />
        )}
      </div>

      {width && width < MOBILE_BREAK_POINT && isMobileMenuOpen && (
        <div className={styles.mobileMenuLinks}>{getMenuButtons()}</div>
      )}
    </header>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavigation);
