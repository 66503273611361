import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './PasswordRemindForm.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { RemindRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import { resetRemindPassword } from '../../../../store/auth/actions';

type Props = {
  onPasswordRemind: (inputs: RemindRequest) => void;
  isLoading: boolean;
  error: HttpError;
  isRemindSuccess: boolean;
  onResetRemindPasswordStore: () => void;
};

type FormInputs = {
  email: string;
};

export const PasswordRemindForm = ({
  onPasswordRemind,
  isLoading,
  error,
  isRemindSuccess,
  onResetRemindPasswordStore,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    return () => onResetRemindPasswordStore();
  }, []);

  const INPUTS = [
    {
      name: 'email',
      label: translate(intl, 'PASSWORD_REMIND_FORM.EMAIL'),
      placeholder: translate(intl, 'PASSWORD_REMIND_FORM.PLACEHOLDER'),
      type: 'text',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'email',
        },
        {
          type: 'minLength',
          parameter: 4,
        },
        {
          type: 'maxLength',
          parameter: 60,
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onPasswordRemind({
      email: submitInputs.email,
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (isRemindSuccess) {
      setNewInputObject('email', {
        value: '',
      });
    }
  }, [isRemindSuccess]);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  return (
    <div className={styles.remindPasswordContainer}>
      <div className={styles.remindTitle}>
        {translate(intl, 'PASSWORD_REMIND_FORM.TITLE')}
      </div>
      <div className={styles.remindSubTitle}>
        {translate(intl, 'PASSWORD_REMIND_FORM.SUB_TITLE')}
      </div>
      <div className={styles.remindForm}>
        <Form
          className={styles.form}
          error={error}
          onSubmit={onSubmit}
          scrollIntoView={false}
        >
          {isRemindSuccess && (
            <Alert variant="success">
              {translate(intl, 'PASSWORD_REMIND_FORM.SUCCESS')}
            </Alert>
          )}
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {translate(intl, globalError)}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              onInputBlur={onInputBlur}
              placeholder={input.placeholder}
              inputLabelProps={{
                shrink: true,
                sx: {
                  marginTop: '-1rem',
                },
              }}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {translate(intl, 'PASSWORD_REMIND_FORM.SEND_LINK')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.remindError,
  isLoading: state.auth.remindLoading,
  isRemindSuccess: state.auth.isRemindSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onPasswordRemind: (inputs: RemindRequest) =>
    dispatch(authService.remind(inputs)),
  onResetRemindPasswordStore: () => dispatch(resetRemindPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRemindForm);
