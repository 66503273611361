export const CREATE_CERTIFICATE_SECTION_START =
  'CREATE_CERTIFICATE_SECTION_START';
export const CREATE_CERTIFICATE_SECTION_SUCCESS =
  'CREATE_CERTIFICATE_SECTION_SUCCESS';
export const CREATE_CERTIFICATE_SECTION_FAIL =
  'CREATE_CERTIFICATE_SECTION_FAIL';

export const FETCH_CERTIFICATE_SECTIONS_START =
  'FETCH_CERTIFICATE_SECTIONS_START';
export const FETCH_CERTIFICATE_SECTIONS_SUCCESS =
  'FETCH_CERTIFICATE_SECTIONS_SUCCESS';
export const FETCH_CERTIFICATE_SECTIONS_FAIL =
  'FETCH_CERTIFICATE_SECTIONS_FAIL';

export const UPDATE_CERTIFICATE_SECTION_START =
  'UPDATE_CERTIFICATE_SECTION_START';
export const UPDATE_CERTIFICATE_SECTION_SUCCESS =
  'UPDATE_CERTIFICATE_SECTION_SUCCESS';
export const UPDATE_CERTIFICATE_SECTION_FAIL =
  'UPDATE_CERTIFICATE_SECTION_FAIL';

export const DELETE_CERTIFICATE_SECTION_START =
  'DELETE_CERTIFICATE_SECTION_START';
export const DELETE_CERTIFICATE_SECTION_SUCCESS =
  'DELETE_CERTIFICATE_SECTION_SUCCESS';
export const DELETE_CERTIFICATE_SECTION_FAIL =
  'DELETE_CERTIFICATE_SECTION_FAIL';

export const ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_START =
  'ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_START';
export const ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_SUCCESS =
  'ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_SUCCESS';
export const ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_FAIL =
  'ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_FAIL';

export const RESET_ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION =
  'RESET_ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION';

export const RESET_CREATE_CERTIFICATE_SECTION =
  'RESET_CREATE_CERTIFICATE_SECTION';

export const RESET_CERTIFICATE_SECTION_STORE =
  'RESET_CERTIFICATE_SECTION_STORE';

export type CertificateSectionActionTypes =
  | typeof CREATE_CERTIFICATE_SECTION_START
  | typeof CREATE_CERTIFICATE_SECTION_SUCCESS
  | typeof CREATE_CERTIFICATE_SECTION_FAIL
  | typeof FETCH_CERTIFICATE_SECTIONS_START
  | typeof FETCH_CERTIFICATE_SECTIONS_SUCCESS
  | typeof FETCH_CERTIFICATE_SECTIONS_FAIL
  | typeof UPDATE_CERTIFICATE_SECTION_START
  | typeof UPDATE_CERTIFICATE_SECTION_SUCCESS
  | typeof UPDATE_CERTIFICATE_SECTION_FAIL
  | typeof DELETE_CERTIFICATE_SECTION_START
  | typeof DELETE_CERTIFICATE_SECTION_SUCCESS
  | typeof DELETE_CERTIFICATE_SECTION_FAIL
  | typeof ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_START
  | typeof ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_SUCCESS
  | typeof ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION_FAIL
  | typeof RESET_ASSIGN_CERTIFICATE_TEMPLATE_TO_SECTION
  | typeof RESET_CREATE_CERTIFICATE_SECTION
  | typeof RESET_CERTIFICATE_SECTION_STORE;
