import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Payment } from '../../domain/Payment';

export const fetchPaymentListStart = () => ({
  type: actionTypes.FETCH_PAYMENT_LIST_START,
});

export const fetchPaymentListSuccess = (paymentList: ListResults<Payment>) => ({
  type: actionTypes.FETCH_PAYMENT_LIST_SUCCESS,
  paymentList,
});

export const fetchPaymentListFail = (paymentListError: HttpError) => ({
  type: actionTypes.FETCH_PAYMENT_LIST_FAIL,
  paymentListError,
});

export const createPaymentStart = () => ({
  type: actionTypes.CREATE_PAYMENT_START,
});

export const createPaymentSuccess = (redirectUrl: string) => ({
  type: actionTypes.CREATE_PAYMENT_SUCCESS,
  redirectUrl,
});

export const createPaymentFail = (paymentCreateError: HttpError) => ({
  type: actionTypes.CREATE_PAYMENT_FAIL,
  paymentCreateError,
});

export const resetPaymentStore = () => ({
  type: actionTypes.RESET_PAYMENT_STORE,
});
