import { HttpError } from '../../config/Axios/axios-instance';
import { TextTemplate } from '../../domain/TextTemplate';
import { TextTemplateActionTypes } from './actionTypes';
import * as actionTypes from './actionTypes';

export type TextTemplateStateType = {
  textTemplatesListLaoding: boolean;
  textTemplatesList: TextTemplate[];
  textTemplatesListError: HttpError;
  textTemplatesCreateUpdateLaoding: boolean;
  textTemplatesCreateUpdateSuccess: boolean;
  textTemplatesCreateUpdateError: HttpError;
  createdTextTemplate: TextTemplate | null;
};

export type TextTemplateActionType = TextTemplateStateType & {
  type: TextTemplateActionTypes;
};

export const initialState: TextTemplateStateType = {
  textTemplatesListLaoding: false,
  textTemplatesList: [],
  textTemplatesListError: null,
  textTemplatesCreateUpdateLaoding: false,
  textTemplatesCreateUpdateSuccess: false,
  textTemplatesCreateUpdateError: null,
  createdTextTemplate: null,
};

const fetchTextTemplatesStart = (
  state: TextTemplateStateType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesListLaoding: true,
  textTemplatesCreateUpdateSuccess: false,
});

const fetchTextTemplatesSuccess = (
  state: TextTemplateStateType,
  action: TextTemplateActionType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesListLaoding: false,
  textTemplatesList: [...state.textTemplatesList, ...action.textTemplatesList],
});

const fetchTextTemplatesFail = (
  state: TextTemplateStateType,
  action: TextTemplateActionType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesListError: action.textTemplatesListError,
});

const createOrUpdateTextTemplatesStart = (
  state: TextTemplateStateType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesCreateUpdateLaoding: true,
});

const createOrUpdateTextTemplatesSuccess = (
  state: TextTemplateStateType,
  action: TextTemplateActionType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesCreateUpdateLaoding: false,
  textTemplatesCreateUpdateSuccess: true,
  createdTextTemplate: action.createdTextTemplate,
});

const createOrUpdateTextTemplatesFail = (
  state: TextTemplateStateType,
  action: TextTemplateActionType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesCreateUpdateError: action.textTemplatesCreateUpdateError,
});

const resetTextTemplateStore = (): TextTemplateStateType => ({
  ...initialState,
});

const resetTextTemplateCreateUpdateStore = (
  state: TextTemplateStateType,
): TextTemplateStateType => ({
  ...state,
  textTemplatesCreateUpdateLaoding: false,
  textTemplatesCreateUpdateSuccess: false,
  textTemplatesCreateUpdateError: null,
  createdTextTemplate: null,
  // textTemplatesList: [],
});

const completelyResetTextTemplateStore = (): TextTemplateStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TextTemplateActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TEXT_TEMPLATES_START:
      return fetchTextTemplatesStart(state);
    case actionTypes.FETCH_TEXT_TEMPLATES_SUCCESS:
      return fetchTextTemplatesSuccess(state, action);
    case actionTypes.FETCH_TEXT_TEMPLATES_FAIL:
      return fetchTextTemplatesFail(state, action);
    case actionTypes.CREATE_OR_UPDATE_TEXT_TEMPLATES_START:
      return createOrUpdateTextTemplatesStart(state);
    case actionTypes.CREATE_OR_UPDATE_TEXT_TEMPLATES_SUCCESS:
      return createOrUpdateTextTemplatesSuccess(state, action);
    case actionTypes.CREATE_OR_UPDATE_TEXT_TEMPLATES_FAIL:
      return createOrUpdateTextTemplatesFail(state, action);
    case actionTypes.RESET_TEXT_TEMPLATE_STORE:
      return resetTextTemplateStore();
    case actionTypes.RESET_TEXT_TEMPLATE_CREATE_UPDATE_STORE:
      return resetTextTemplateCreateUpdateStore(state);
    case actionTypes.LOGOUT:
      return completelyResetTextTemplateStore();
    default:
      return state;
  }
};

export default reducer;
