import moonIcon from '../../assets/icons/moon.svg';
import sunIcon from '../../assets/icons/sun.svg';
import lightningIcon from '../../assets/icons/lightning.svg';
import starIcon from '../../assets/icons/star.svg';
import { SubscriptionType } from '../../domain/Payment';

export const getIcon = (subscriptionType: SubscriptionType) => {
  switch (subscriptionType) {
    case SubscriptionType.BASE:
      return moonIcon;
    case SubscriptionType.OPTIMAL:
      return sunIcon;
    case SubscriptionType.EXPERIENCED:
      return lightningIcon;
    case SubscriptionType.PROFESSIONAL:
      return starIcon;
  }
};
