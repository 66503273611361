import React from 'react';
import { ReactSVG } from 'react-svg';
import { useIntl } from 'react-intl';
import styles from './PublicSidebarCompanyAndSignatures.module.scss';
import briefcaseIcon from '../../../../assets/icons/briefcase.svg';
import { translate } from '../../../../utility/messageTranslator/translate';
import { User } from '../../../../domain/User';
import { Roles } from '../../../../domain/Role';
import cx from 'classnames';

type Props = {
  onAddCreditsModalOpen: () => void;
  currentUser: User | null;
  companyContainerClass?: string;
};

const PublicSidebarCompanyAndSignatures = ({
  onAddCreditsModalOpen,
  currentUser,
  companyContainerClass,
}: Props) => {
  const intl = useIntl();

  return (
    <>
      <div className={cx(styles.companyContainer, companyContainerClass)}>
        {currentUser?.userInvoiceInfo?.companyName && (
          <>
            <ReactSVG src={briefcaseIcon} />
            <span>{currentUser?.userInvoiceInfo?.companyName}</span>
          </>
        )}
      </div>
      {currentUser?.profile?.selectedRole !== Roles.USER && (
        <div className={styles.signaturesContainer}>
          <div className={styles.signatureCountContainer}>
            <span className={styles.count}>
              {intl.formatNumber(currentUser?.profile?.balance ?? 0)}
            </span>
            <span className={styles.label}>
              {translate(intl, 'PUBLIC_NAVIGATION.SIGNATURES')}
            </span>
          </div>
          <div className={styles.fillSignaturesCta}>
            <p onClick={onAddCreditsModalOpen}>
              {translate(intl, 'PUBLIC_NAVIGATION.ADD_SIGNATURES')}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default PublicSidebarCompanyAndSignatures;
