import * as actionTypes from './actionTypes';
import { TemplateActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Template } from '../../domain/Template';
import { ListResults } from '../../common/List/List';

export type TemplatesStateType = {
  templateList: ListResults<Template> | null;
  templateListLoading: boolean;
  templateListError: HttpError;
  templateListUpdateNeeded: boolean;
  templatesError: HttpError;
  templates: Template[] | null;
  templatesLoading: boolean;
  templateError: HttpError;
  template: Template | null;
  templateLoading: boolean;
  templateCreateLoading: boolean;
  templateCreateError: HttpError;
  templateCreateSuccess: boolean;
  createdTemplate: Template | null;
  templateUpdateLoading: boolean;
  templateUpdateError: HttpError;
  templateUpdateSuccess: boolean;
  templateDeleteLoading: boolean;
  templateDeleteError: HttpError;
  saveTemplateLoading: boolean;
  saveThumbnailSuccess: boolean;
  saveTemplateError: HttpError;
  saveThumbnailLoading: boolean;
  saveThumbnailError: HttpError;
  replaceTemplateImageLoading: boolean;
  replaceTemplateImageSuccess: boolean;
  replaceTemplateImageError: HttpError;
};

export type TemplatesActionType = TemplatesStateType & {
  type: TemplateActionTypes;
};

export const initialState: TemplatesStateType = {
  templateList: null,
  templateListLoading: false,
  templateListError: null,
  templateListUpdateNeeded: false,
  templatesError: null,
  templates: null,
  templateError: null,
  template: null,
  templateLoading: false,
  templatesLoading: true,
  templateCreateError: null,
  templateCreateLoading: false,
  templateCreateSuccess: false,
  createdTemplate: null,
  templateUpdateError: null,
  templateUpdateLoading: false,
  templateUpdateSuccess: false,
  templateDeleteLoading: false,
  templateDeleteError: null,
  saveTemplateError: null,
  saveThumbnailSuccess: false,
  saveTemplateLoading: false,
  saveThumbnailError: null,
  saveThumbnailLoading: false,
  replaceTemplateImageLoading: false,
  replaceTemplateImageSuccess: false,
  replaceTemplateImageError: null,
};

const fetchTemplateListStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templateListLoading: true,
});

const fetchTemplateListSuccess = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateList: action.templateList,
  templateListLoading: false,
  templateListUpdateNeeded: false,
});

const fetchTemplateListFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateListError: action.templateListError,
  templateListLoading: false,
});

const fetchTemplatesStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templatesLoading: true,
});

const fetchTemplatesSuccess = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templates: action.templates,
  templatesLoading: false,
  templatesError: null,
});

const fetchTemplatesFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templatesError: action.templatesError,
  templatesLoading: false,
});

const fetchTemplateStart = (state: TemplatesStateType): TemplatesStateType => ({
  ...state,
  templateLoading: true,
});

const fetchTemplateSuccess = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  template: action.template,
  templateLoading: false,
  templateError: null,
});

const fetchTemplateFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateError: action.templateError,
  templateLoading: false,
});

const createTemplateStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templateCreateLoading: true,
});

const createTemplateSuccess = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateCreateLoading: false,
  templateCreateError: null,
  templateCreateSuccess: true,
  templateListUpdateNeeded: true,
  createdTemplate: action.createdTemplate,
});

const createTemplateFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateCreateLoading: false,
  templateCreateError: action.templateCreateError,
});

const updateTemplateStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templateUpdateLoading: true,
});

const updateTemplateSuccess = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templateUpdateLoading: false,
  templateUpdateError: null,
  templateUpdateSuccess: true,
  templateListUpdateNeeded: true,
});

const updateTemplateFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateUpdateLoading: false,
  templateUpdateError: action.templateUpdateError,
});

const deleteTemplateStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templateDeleteLoading: true,
});

const deleteTemplateSuccess = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  templateDeleteLoading: false,
  templateDeleteError: null,
  templateListUpdateNeeded: true,
});

const deleteTemplateFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  templateDeleteError: action.templateDeleteError,
  templateDeleteLoading: false,
});

const saveTemplateStart = (state: TemplatesStateType): TemplatesStateType => ({
  ...state,
  saveTemplateLoading: true,
});

const saveTemplateSuccess = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  template: action.template,
  saveTemplateLoading: false,
  saveTemplateError: null,
});

const saveTemplateFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  saveTemplateError: action.saveTemplateError,
  saveTemplateLoading: false,
});

const saveTemplateThumbnaiStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  saveThumbnailLoading: true,
});

const saveTemplateThumbnaiSuccess = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  saveThumbnailLoading: false,
  saveThumbnailSuccess: true,
  saveThumbnailError: null,
  templateListUpdateNeeded: true,
});

const saveTemplateThumbnaiFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  saveThumbnailError: action.saveThumbnailError,
  saveThumbnailLoading: false,
  saveThumbnailSuccess: false,
});

const replaceTemplateImageStart = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  replaceTemplateImageLoading: true,
});

const replaceTemplateImageSuccess = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  replaceTemplateImageLoading: false,
  replaceTemplateImageSuccess: true,
  replaceTemplateImageError: null,
  template: action.template,
});

const replaceTemplateImageFail = (
  state: TemplatesStateType,
  action: TemplatesActionType,
): TemplatesStateType => ({
  ...state,
  replaceTemplateImageError: action.replaceTemplateImageError,
  replaceTemplateImageLoading: false,
});

const resetCropImageStore = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  replaceTemplateImageLoading: false,
  replaceTemplateImageError: null,
  replaceTemplateImageSuccess: false,
});

const resetCreatedTemplateStore = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...state,
  createdTemplate: null,
  templateCreateLoading: false,
  templateCreateError: null,
  templateCreateSuccess: false,
});

const resetTemplateSelectStore = (
  state: TemplatesStateType,
): TemplatesStateType => ({
  ...initialState,
  templates: null,
  templatesLoading: false,
  templatesError: null,
});

const resetTemplateStore = (state: TemplatesStateType): TemplatesStateType => ({
  ...initialState,
});

const completelyResetTemplateStore = (): TemplatesStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TemplatesActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TEMPLATE_LIST_START:
      return fetchTemplateListStart(state);
    case actionTypes.FETCH_TEMPLATE_LIST_SUCCESS:
      return fetchTemplateListSuccess(state, action);
    case actionTypes.FETCH_TEMPLATE_LIST_FAIL:
      return fetchTemplateListFail(state, action);
    case actionTypes.FETCH_TEMPLATES_START:
      return fetchTemplatesStart(state);
    case actionTypes.FETCH_TEMPLATES_SUCCESS:
      return fetchTemplatesSuccess(state, action);
    case actionTypes.FETCH_TEMPLATES_FAIL:
      return fetchTemplatesFail(state, action);
    case actionTypes.FETCH_TEMPLATE_START:
      return fetchTemplateStart(state);
    case actionTypes.FETCH_TEMPLATE_SUCCESS:
      return fetchTemplateSuccess(state, action);
    case actionTypes.FETCH_TEMPLATE_FAIL:
      return fetchTemplateFail(state, action);
    case actionTypes.CREATE_TEMPLATE_START:
      return createTemplateStart(state);
    case actionTypes.CREATE_TEMPLATE_SUCCESS:
      return createTemplateSuccess(state, action);
    case actionTypes.CREATE_TEMPLATE_FAIL:
      return createTemplateFail(state, action);
    case actionTypes.UPDATE_TEMPLATE_START:
      return updateTemplateStart(state);
    case actionTypes.UPDATE_TEMPLATE_SUCCESS:
      return updateTemplateSuccess(state);
    case actionTypes.UPDATE_TEMPLATE_FAIL:
      return updateTemplateFail(state, action);
    case actionTypes.DELETE_TEMPLATE_START:
      return deleteTemplateStart(state);
    case actionTypes.DELETE_TEMPLATE_SUCCESS:
      return deleteTemplateSuccess(state);
    case actionTypes.DELETE_TEMPLATE_FAIL:
      return deleteTemplateFail(state, action);
    case actionTypes.SAVE_TEMPLATE_START:
      return saveTemplateStart(state);
    case actionTypes.SAVE_TEMPLATE_SUCCESS:
      return saveTemplateSuccess(state, action);
    case actionTypes.SAVE_TEMPLATE_FAIL:
      return saveTemplateFail(state, action);
    case actionTypes.SAVE_TEMPLATE_THUMBNAIL_START:
      return saveTemplateThumbnaiStart(state);
    case actionTypes.SAVE_TEMPLATE_THUMBNAIL_SUCCESS:
      return saveTemplateThumbnaiSuccess(state);
    case actionTypes.SAVE_TEMPLATE_THUMBNAIL_FAIL:
      return saveTemplateThumbnaiFail(state, action);
    case actionTypes.REPLACE_TEMPLATE_IMAGE_START:
      return replaceTemplateImageStart(state);
    case actionTypes.REPLACE_TEMPLATE_IMAGE_SUCCESS:
      return replaceTemplateImageSuccess(state, action);
    case actionTypes.REPLACE_TEMPLATE_IMAGE_FAIL:
      return replaceTemplateImageFail(state, action);
    case actionTypes.RESET_CROP_IMAGE_STORE:
      return resetCropImageStore(state);
    case actionTypes.RESET_CREATED_TEMPLATE_STORE:
      return resetCreatedTemplateStore(state);
    case actionTypes.RESET_TEMPLATE_SELECT_STORE:
      return resetTemplateSelectStore(state);
    case actionTypes.RESET_TEMPLATE_STORE:
      return resetTemplateStore(state);
    case actionTypes.LOGOUT:
      return completelyResetTemplateStore();
    default:
      return state;
  }
};

export default reducer;
